import { Paper, Skeleton, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import RulesetBreadcrumbs from '../../components/breadcrumbs/ruleset';
import { sortTableDataStatus } from '../../components/content-table-head';
import EditRulesetForm from '../../components/edit-ruleset-header';
import { BusinessProcessDto } from '../../services/dto/business-process/business-process.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskLevelDto } from '../../services/dto/risk-levels/risk-level.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import {
  useBusinessProcessService,
  useRiskFunctionService,
  useRiskLevelService,
  useRiskService,
  useRulesetService,
} from '../../services/hooks';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import {
  DASHBOARD_SEARCHPARAM_KEY_BPID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE,
} from '../../shared/constatns';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';
import RulesetRisksComponent from './ruleset-risks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetDetailsPageProps {}

const RulesetDetailsPage: FunctionComponent<RulesetDetailsPageProps> = ({ ...props }) => {
  const routeParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [risks, setRisks] = useState<RiskDto[] | null>(null);
  const [risksTotalCount, setRisksTotalCount] = useState(0);
  const [ruleset, setRuleset] = useState<RulesetDto | undefined>(undefined);
  const [rulesetVersionHistory, setRulesetVersionHistory] = useState<RulesetDto[]>([]);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[]>([]);
  const [riskLevels, setRiskLevels] = useState<RiskLevelDto[]>([]);
  const [allFunctions, setAllFunctions] = useState<RiskFunctionDto[]>([]);
  const [allRisks, setAllRisks] = useState<RiskDto[] | null>(null);

  const rulesetService = useRulesetService();
  const businessProcessService = useBusinessProcessService();
  const riskLevelService = useRiskLevelService();
  const riskFunctionService = useRiskFunctionService();
  const riskService = useRiskService();
  const navigate = useNavigate();

  const fetchRulesetVersionHistory = useCallback(
    async (rulesetId: string) => {
      const rulesetVersionHistory = await rulesetService.getRulesetVersionHistory(rulesetId);
      setRulesetVersionHistory(rulesetVersionHistory.data);
    },
    [rulesetService],
  );

  const fetchRisksByRulesetId = useCallback(
    async (rulesetId: string) => {
      const risksResult = await rulesetService.getRisksFromRuleset(rulesetId);
      setRisks(sortTableDataStatus('asc', risksResult.data));
      setRisksTotalCount(risksResult.meta?.totalCount ?? risksResult.data.length);
      return risksResult.data;
    },
    [rulesetService],
  );

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcessesResult = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcessesResult.data);
  }, [businessProcessService]);

  const fetchRiskLevels = useCallback(async () => {
    const riskLevelsResult = await riskLevelService.getRisklevels();
    setRiskLevels(riskLevelsResult.data);
  }, [riskLevelService]);

  const fetchRiskFunctions = useCallback(async () => {
    const riskFunctions = await riskFunctionService.getFunctions();
    setAllFunctions(riskFunctions.data);
  }, [riskFunctionService]);

  const fetchAllRisks = useCallback(async () => {
    const risks = await riskService.getRisks();
    setAllRisks(risks.data);
    return risks.data;
  }, [riskService]);

  const onSave = useCallback(
    async (data: FieldValues) => {
      try {
        if (ruleset) {
          const updateRuleset = {
            name: ruleset.name != data.name ? data.name : ruleset.name,
            displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
            description: ruleset.description != data.description ? data.description : ruleset.description,
          };
          await rulesetService.updateRuleset(ruleset.id, updateRuleset);
          setRuleset({
            ...ruleset,
            name: ruleset.name != data.name ? data.name : ruleset.name,
            displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
            description: ruleset.description != data.description ? data.description : ruleset.description,
          });
          fetchRulesetVersionHistory(ruleset.id);
        }
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRulesetVersionHistory, ruleset, rulesetService],
  );

  const onConfirmFinalize = useCallback(
    async (ruleset: RulesetDto, data: FieldValues) => {
      try {
        const updateRuleset = {
          name: ruleset.name != data.name ? data.name : ruleset.name,
          displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
          description: ruleset.description != data.description ? data.description : ruleset.description,
          status: ContentTypeStatus.Final,
        };
        await rulesetService.updateRuleset(ruleset.id, updateRuleset);
        setRuleset({
          ...ruleset,
          name: ruleset.name != data.name ? data.name : ruleset.name,
          displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
          description: ruleset.description != data.description ? data.description : ruleset.description,
          status: ContentTypeStatus.Final,
        });
        fetchRisksByRulesetId(ruleset.id);
        fetchRulesetVersionHistory(ruleset.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRisksByRulesetId, fetchRulesetVersionHistory, rulesetService],
  );

  const onConfirmRelease = useCallback(
    async (ruleset: RulesetDto) => {
      try {
        const updateRuleset = {
          status: ContentTypeStatus.Released,
        };
        await rulesetService.updateRuleset(ruleset.id, updateRuleset);
        setRuleset({
          ...ruleset,
          status: ContentTypeStatus.Released,
        });
        fetchRisksByRulesetId(ruleset.id);
        fetchRulesetVersionHistory(ruleset.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRisksByRulesetId, fetchRulesetVersionHistory, rulesetService],
  );

  const fetchRuleset = useCallback(
    async (rulesetId: string) => {
      const rulesetResult = await rulesetService.getRuleset(rulesetId);
      setRuleset(rulesetResult.data);
      fetchRulesetVersionHistory(rulesetId);
    },
    [fetchRulesetVersionHistory, rulesetService],
  );

  const onRiskUpdated = useCallback((id: string, updatedRisk: RiskDto) => {
    setRisks((risks) => {
      if (risks) {
        return risks.map((risk) => {
          if (risk.id === id) {
            return { ...risk, ...updatedRisk };
          }
          return risk;
        });
      }
      return null;
    });
  }, []);

  const onRiskRemoved = useCallback((riskId: string) => {
    setRisks((risks) => {
      if (risks) {
        return risks.filter((risk) => risk.id !== riskId);
      }
      return null;
    });
    setRisksTotalCount((oldTotalCount) => oldTotalCount - 1);
  }, []);

  const onRisksRemoved = useCallback((riskIds: string[]) => {
    setRisks((risks) => {
      if (risks) {
        return risks.filter((risk) => !riskIds.some((rId) => rId === risk.id));
      }
      return null;
    });
    setRisksTotalCount((oldTotalCount) => oldTotalCount - riskIds.length);
  }, []);

  const onRiskAdded = useCallback((ruleset: string, riskId: string, risk: RiskDto | null) => {
    if (risk) {
      setRisks((risks) => [risk, ...(risks ?? [])]);
      setRisksTotalCount((oldTotalCount) => oldTotalCount + 1);
    }
  }, []);

  const onRiskCreated = useCallback((newRisk: RiskDto) => {
    setRisks((risks) => [newRisk, ...(risks ?? [])]);
  }, []);

  const onNewRiskVersion = useCallback(
    (newRisk: RiskDto, origin: RiskDto, updateParents: Partial<RulesetDto>[]) => {
      // forward to new created parent
      const newParent = updateParents.find((p) => ruleset?.name === p.name);
      if (newParent) {
        navigate(`/rulesets/${newParent.id}`);
      }
    },
    [navigate, ruleset?.name],
  );

  const initRisksFilters = useMemo(() => {
    return {
      businessProcessId: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_BPID),
      riskLevelId: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID),
      riskType: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE),
    };
  }, [searchParams]);

  useEffect(() => {
    if (routeParams.rulesetId) {
      fetchRuleset(routeParams.rulesetId);
      fetchRisksByRulesetId(routeParams.rulesetId);
    } else {
      setRuleset(undefined);
      setRisks([]);
    }
  }, [fetchRisksByRulesetId, fetchRuleset, routeParams]);
  useEffect(() => {
    fetchBusinessProcesses();
    fetchRiskLevels();
    fetchRiskFunctions();
    fetchAllRisks();
  }, [fetchAllRisks, fetchBusinessProcesses, fetchRiskFunctions, fetchRiskLevels]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        {ruleset && <RulesetBreadcrumbs ruleset={ruleset} />}
        {!ruleset && <Skeleton variant='rounded' height={56} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {ruleset && (
          <EditRulesetForm
            ruleset={ruleset}
            risks={risks ?? []}
            rulesetVersionHistory={rulesetVersionHistory}
            onSave={onSave}
            onConfirmFinalize={onConfirmFinalize}
            onConfirmRelease={onConfirmRelease}
          />
        )}
        {!ruleset && <Skeleton variant='rounded' height={272} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {ruleset && (
          <RulesetRisksComponent
            ruleset={ruleset}
            risks={risks}
            allRisks={allRisks ?? []}
            totalCount={risksTotalCount}
            onRiskAdded={onRiskAdded}
            onRisksRemoved={onRisksRemoved}
            onRiskUpdated={onRiskUpdated}
            onRiskRemoved={onRiskRemoved}
            onRiskCreated={onRiskCreated}
            onRiskNewVersionCreated={onNewRiskVersion}
            initRisksFilters={initRisksFilters}
            allBusinessProcesses={allBusinessProcesses}
            riskLevels={riskLevels}
            allFunctions={allFunctions}
          />
        )}
        {!ruleset && <Skeleton variant='rounded' height={300} />}
      </Paper>
    </Stack>
  );
};

export default RulesetDetailsPage;
