import { Autocomplete, Grid, TextField } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { confirmReleaseFunction } from 'src/pages/function-details/dialogs';
import { BusinessProcessDto } from 'src/services/dto/business-process/business-process.dto';
import { CreateNewVersionRiskFunctionDto } from 'src/services/dto/functions/create-new-version-function.dto';
import { UpdateRiskFunctionDto } from 'src/services/dto/functions/update-function.dto';
import { ContentTypeStatus } from 'src/services/models/content-type-status';

import { ContentTableModal } from '../../../components/content-table-modal';
import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';

interface CreateRiskFunctionNewVersionFormData {
  name: string;
  displayName: string;
  businessProcessId: string;
  description: string;
}

interface RiskFunctionModalProps {
  open: boolean;
  riskFunction: RiskFunctionDto;
  allBusinessProcesses: BusinessProcessDto[];
  onClose: () => void;
  onRiskFunctionCreateNewVersion: (
    id: string,
    updatedRiskFunction: CreateNewVersionRiskFunctionDto,
    riskFunction: RiskFunctionDto,
  ) => Promise<void>;
  loadFunctionWithValues: (riskFunctionId: string) => Promise<RiskFunctionDto>;
  onRiskFunctionRelease: (riskFunctionId: string, data: UpdateRiskFunctionDto) => void;
}

const CreateRiskFunctionNewVersionModal: FunctionComponent<RiskFunctionModalProps> = ({
  open,
  riskFunction,
  allBusinessProcesses,
  onClose,
  loadFunctionWithValues,
  onRiskFunctionRelease,
  onRiskFunctionCreateNewVersion,
  ...props
}) => {
  const [selectedBusinessProcess, setSelectedBusinessProcess] = useState<BusinessProcessDto | null>(null);
  const [newBusinessProcessName, setNewBusinessProcessName] = useState('');
  const [riskFunctionWithValues, setRiskFuinctionWithValues] = useState<RiskFunctionDto | undefined>(undefined);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateRiskFunctionNewVersionFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      businessProcessId: '',
      description: '',
    },
  });

  //@NOTE: Function Values are required in confirm dialogue to check for release condition of min. 1 rule
  const fetchRiskFunctionWithValues = useCallback(async () => {
    setRiskFuinctionWithValues(await loadFunctionWithValues(riskFunction.id));
  }, [loadFunctionWithValues, riskFunction]);

  const onCreateNewVersion = useCallback(
    async (data: FieldValues) => {
      onRiskFunctionCreateNewVersion(
        riskFunction.id,
        {
          displayName: data.displayName,
          description: data.description,
          businessProcessId: data.businessProcessId || null,
        },
        riskFunction,
      );
    },
    [riskFunction, onRiskFunctionCreateNewVersion],
  );

  const confirm = useConfirm();

  const onRelease = useCallback(
    async (data: FieldValues) => {
      if (riskFunctionWithValues) {
        const { canRelease, dialog } = confirmReleaseFunction(confirm, riskFunctionWithValues);
        dialog
          .then(() => {
            if (canRelease) {
              onRiskFunctionRelease(riskFunctionWithValues.id, {
                status: ContentTypeStatus.Released,
              });
            }
          })
          .catch(() => {
            /* ... */
          });
      }
    },
    [confirm, onRiskFunctionRelease, riskFunctionWithValues],
  );

  const hasChanges = useCallback((data: FieldValues, content: RiskFunctionDto) => {
    return (
      data.name !== content.name ||
      data.displayName !== content.displayName ||
      data.description !== content.description ||
      data.businessProcessId !== (content.businessProcess?.id ?? '')
    );
  }, []);

  const onAutocompleteChange = useCallback(
    (_: any, newSelectedBusinessProcess: BusinessProcessDto | null) => {
      setSelectedBusinessProcess(newSelectedBusinessProcess);
      setValue('businessProcessId', newSelectedBusinessProcess?.id ?? '');
    },
    [setSelectedBusinessProcess, setValue],
  );

  useEffect(() => {
    setValue('name', riskFunction.name);
    setValue('displayName', riskFunction.displayName);
    setValue('description', riskFunction.description);
    setValue('businessProcessId', riskFunction.businessProcess ? riskFunction.businessProcess.id : '');
  }, [riskFunction.description, riskFunction.displayName, riskFunction.name, riskFunction.businessProcess, setValue]);

  useEffect(() => {
    fetchRiskFunctionWithValues();
  }, [fetchRiskFunctionWithValues]);

  const registerOptions = {
    businessProcessId: undefined,
  };

  return (
    <ContentTableModal<RiskFunctionDto, CreateRiskFunctionNewVersionFormData, RiskFunctionDto>
      title='Edit Function'
      type='CreateNewVersion'
      createNewVersion={{
        hasChanges,
        onCreateNewVersion,
        onRelease,
        content: riskFunction,
      }}
      open={open}
      onClose={onClose}
      form={{
        register,
        setValue,
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors },
      }}
    >
      <Grid item xs={12}>
        <Controller
          name='businessProcessId'
          control={control}
          rules={registerOptions.businessProcessId}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={selectedBusinessProcess}
              onChange={onAutocompleteChange}
              inputValue={newBusinessProcessName}
              onInputChange={(_, newBusinessProcessName) => {
                setNewBusinessProcessName(newBusinessProcessName);
              }}
              options={allBusinessProcesses}
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
              renderInput={(params) => (
                <TextField {...params} margin='dense' label='Business Process' InputLabelProps={{ shrink: true }} />
              )}
            />
          )}
        />
      </Grid>
    </ContentTableModal>
  );
};

export default CreateRiskFunctionNewVersionModal;
