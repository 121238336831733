import { TableHeadCell } from '@/components/content-table-head/content-table-header-filter';

import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { TableOrder, sortTableData } from '../../content-table-head';
import { TableBodyCellMapper, TableBodyMapper } from '../../content-table/content-table-body-cell';

export type RiskFunctionsOrder = TableOrder;
export type RiskFunctionsOrderBy = 'name' | 'displayName' | 'businessProcess' | 'finalizedAt' | 'author' | 'status';

export function sortRiskFunctions(
  order: RiskFunctionsOrder,
  orderBy: RiskFunctionsOrderBy,
  riskFunctions: RiskFunctionDto[],
  rowMapper: TableBodyMapper<RiskFunctionDto, RiskFunctionsOrderBy>,
) {
  return sortTableData(order, orderBy, riskFunctions, rowMapper);
}

export const RiskFunctionContentTableExtraCells = {
  businessProcess: {
    value: (riskFunction: RiskFunctionDto) => riskFunction.businessProcess?.name ?? '',
    sortValue: (riskFunction: RiskFunctionDto) => riskFunction.businessProcess?.name ?? null,
    renderValue: (riskFunction: RiskFunctionDto) => riskFunction.businessProcess?.name ?? '',
  } as TableBodyCellMapper<RiskFunctionDto>,
};

export const RiskFunctionContentTableExtraHeads: TableHeadCell<RiskFunctionsOrderBy>[] = [
  {
    id: 'businessProcess',
    numeric: false,
    disablePadding: false,
    label: 'Business Process',
  },
];
