import { RiskFunctionDto } from './dto/functions/function.dto';
import { CreateFromTemplateRiskDto } from './dto/risks/create-from-existing-risk.dto';
import { CreateNewVersionRiskDto } from './dto/risks/create-new-version-risk.dto';
import { CreateRiskDto } from './dto/risks/create-risk.dto';
import { RiskDto } from './dto/risks/risk.dto';
import { UpdateRiskDto } from './dto/risks/update-risk.dto';
import { RulesetDto } from './dto/rulesets/ruleset.dto';
import { HttpService } from './http.service';

export class RiskService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('risks')) {}

  async getRisks(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RiskDto[]>(undefined, params);
    return response.data;
  }

  async getRisksTrashed(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RiskDto[]>('trashed', params);
    return response.data;
  }

  async getRisk(id: string) {
    const response = await this.httpService.get<RiskDto>(id);
    return response.data;
  }

  async getRiskVersionHistory(id: string) {
    const response = await this.httpService.get<RiskDto[]>(`${id}/history`);
    return response.data;
  }

  async getFunctionsFromRisk(id: string) {
    const response = await this.httpService.get<RiskFunctionDto[]>(`${id}/functions`);
    return response.data;
  }

  async createRisk(body: CreateRiskDto) {
    const response = await this.httpService.post<RiskDto>('', body);
    return response.data;
  }

  async createFromExistingRisk(id: string, body: CreateFromTemplateRiskDto) {
    const response = await this.httpService.post<RiskDto>(id, body);
    return response.data;
  }

  async createNewRiskVersion(id: string, body: CreateNewVersionRiskDto) {
    const response = await this.httpService.postWithMeta<RiskDto, { updateParents: Partial<RulesetDto>[] }>(
      `${id}/new-version`,
      body,
    );
    return response.data;
  }

  async updateRisk(id: string, body: UpdateRiskDto) {
    const response = await this.httpService.patch<RiskDto>(id, body);
    return response.data;
  }

  async deleteRisk(id: string) {
    const response = await this.httpService.delete(`${id}/delete`);
    return response.data;
  }

  async deleteRisks(riskIds: string[]) {
    const response = await this.httpService.delete('delete', { riskIds });
    return response.data;
  }

  async trashRisk(id: string) {
    const response = await this.httpService.delete(`${id}/trash`);
    return response.data;
  }

  async trashRisks(riskIds: string[]) {
    const response = await this.httpService.delete('trash', { riskIds });
    return response.data;
  }

  async restoreRisk(id: string) {
    const response = await this.httpService.post(`${id}/restore`);
    return response.data;
  }

  async addFunctionToRisk(riskId: string, functionId: string) {
    await this.httpService.post(`${riskId}/functions/${functionId}`);
  }

  async removeFunctionFromRisk(riskId: string, functionId: string) {
    await this.httpService.delete(`${riskId}/remove/functions/${functionId}`);
  }

  async removeFunctionsFromRisk(riskId: string, riskFunctionIds: string[]) {
    await this.httpService.delete(`${riskId}/remove/functions`, { riskFunctionIds });
  }

  async getRulesetsFromRisk(riskId: string) {
    const response = await this.httpService.get<RulesetDto[]>(`${riskId}/rulesets`);
    return response.data;
  }
}
