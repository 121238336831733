import { createTheme } from '@mui/material/styles';

export const xcpColors = {
  grey: ['#414042', '#808285', '#A7A9AC', '#D1D3D4', '#ECEDED'],
  units: ['#CD1316', '#892144', '#344C76', '#17164F', '#AD0A65'],
  graphics: ['#E53917', '#F78B2B', '#FFD150', '#7FBD42', '#6FC7B6', '#2E7E89'],
  primary: '#CD1316',
  secondary: '#58595B',
  background: '#F2F2F2',
};

export let theme = createTheme({
  palette: {
    primary: {
      main: xcpColors.primary,
    },
    secondary: {
      main: xcpColors.secondary,
    },
    background: {
      default: xcpColors.background,
    },
    success: {
      main: '#558947',
    },
    warning: {
      main: '#E08A2D',
    },
    error: {
      main: '#BC4E35',
    },
    info: {
      main: '#4273CC',
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

theme = createTheme(theme, {
  palette: {
    draft: theme.palette.augmentColor({
      color: {
        main: '#E8F5E9',
        contrastText: '#F78B2B',
      },
      name: 'draft',
    }),
    final: theme.palette.augmentColor({
      color: {
        main: '#E5F2D9',
        contrastText: '#0D47A1',
      },
      name: 'final',
    }),
    released: theme.palette.augmentColor({
      color: {
        main: '#E2F4F0',
        contrastText: '#6FC7B6',
      },
      name: 'released',
    }),
    deleted: theme.palette.augmentColor({
      color: {
        main: '#EDEEEE',
        contrastText: '#A8A9AC',
      },
      name: 'deleted',
    }),
    deprecated: theme.palette.augmentColor({
      color: {
        main: '#EDEEEE',
        contrastText: '#A8A9AC',
      },
      name: 'deprecated',
    }),
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            backgroundColor: '#e8f5e9',
            color: '#1b5e20',
            boxShadow: theme.shadows[1],
            textTransform: 'none',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#e8f5e9',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            backgroundColor: '#e3f2fd',
            color: '#0d47a1',
            boxShadow: theme.shadows[1],
            textTransform: 'none',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#e3f2fd',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            backgroundColor: '#fff3e0',
            color: '#e65100',
            boxShadow: theme.shadows[1],
            textTransform: 'none',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#fff3e0',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            backgroundColor: '#ffebee',
            color: '#b71c1c',
            boxShadow: theme.shadows[1],
            textTransform: 'none',
            borderRadius: 0,
            '&:hover': {
              backgroundColor: '#ffebee',
            },
          },
        },
      ],
    },
  },
});

export default theme;
