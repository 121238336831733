import {
  faArrowUp,
  faFileCircleXmark,
  faMinus,
  faPen,
  faPlus,
  faRotateLeft,
  faTrash,
} from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableContainer,
  TableBody,
  TableCell,
  Table,
  Stack,
  Grid,
  TablePagination,
  Box,
  IconButton,
  AutocompleteRenderGroupParams,
  Checkbox,
  Tooltip,
  Autocomplete,
  styled,
  lighten,
  darken,
  TextField,
  CircularProgress,
  Typography,
  SxProps,
  Theme,
  Button,
} from '@mui/material';
import clsx from 'clsx';
import React, { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentTypeStatus } from '../../services/models/content-type-status';
import { extendStringFilterOperators } from '../../shared/data-grid';
import { searchStarFilter } from '../../shared/utils';
import {
  ContentRowsPerPageDefault,
  ContentRowsPerPageOptions,
  MainTableMinWidth,
  TableAutocompleteFieldWidth,
} from '../../styles/content-tables';
import {
  ChildrenBaseContentOrderBy,
  ChildrenBaseContentType,
  DefaultChildrenBaseContentOrderBy,
  DefaultChildrenContentType,
} from '../children-table/children-table-body-cell';
import { ContentTableHeader, TableOrder } from '../content-table-head';
import { TableHeadCell } from '../content-table-head/content-table-header-filter';
import { ContentTableToolbar } from '../content-table-toolbar';
import ChildrenCollapse from './children-collapse';
import {
  BaseContentOrderBy,
  BaseContentType,
  DefaultBaseContentType,
  TableBodyMapper,
} from './content-table-body-cell';
import ContentTableRow from './content-table-row';
import { useCanShowCell, useContentTableCells, useContentTableHeadCells, useContentTableRowMapper } from './hooks';

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled('ul')({
  padding: 0,
});

interface ContentTableProps<
  Row extends BaseContentType,
  OrderBy extends BaseContentOrderBy | keyof Row | number | symbol,
  Children extends ChildrenBaseContentType = DefaultChildrenContentType,
  ChildrenOrderBy extends
    | ChildrenBaseContentOrderBy
    | keyof Children
    | number
    | symbol = DefaultChildrenBaseContentOrderBy,
  Parent extends BaseContentType = DefaultBaseContentType,
> {
  parent?: Parent;
  rows: Row[] | null;
  totalCount: number;
  extraHeads?: TableHeadCell<OrderBy>[];
  extraCells?: TableBodyMapper<Row, keyof Row>;
  tableToolbarTitle: string;
  sortTable: (order: TableOrder, orderBy: OrderBy, rows: Row[], rowMapper: TableBodyMapper<Row, OrderBy>) => Row[];
  openEdit?: (row: Row) => void;
  openCreateNewVersion?: (row: Row) => void;
  onRemoveFromParent?: (row: Row) => void;
  onDelete?: (row: Row) => void;
  massDeletion?: {
    onMassDelete: (rowIds: string[]) => Promise<boolean>;
    tooltipTitle: string;
    iconType: 'delete' | 'remove';
  };
  onTrash?: (row: Row) => void;
  massTrash?: {
    onMassTrash: (rowIds: string[]) => Promise<boolean>;
    tooltipTitle: string;
    iconType: 'delete' | 'remove';
  };
  onRestore?: (row: Row) => void;
  trash?: boolean;
  createButtonTitle?: string;
  createFromTemplateButtonTitle?: string;
  autocompleteButtonTitle?: string;
  defaultOrderBy?: OrderBy;
  openCreate?: () => void;
  openCreateFromTemplate?: (row?: Row) => void;
  getDetailsUrl?: (row: Row) => string;
  loadChildren?: (row: Row) => Promise<Children[]>;
  template?: {
    allOptions: Row[];
    label: string;
    buttonLabel: string;
    addChildToParent: (parent: Parent, selectedChild: Row) => void;
    groupList?: {
      groupBy: (option: Row) => string;
      renderGroup: (params: AutocompleteRenderGroupParams) => ReactNode;
    };
  };
  showHeaders?: OrderBy[];
  disableMultiSelect?: boolean;
  initFilters?: Map<OrderBy, string | null | undefined>;
  childrenExtraHeads?: TableHeadCell<ChildrenOrderBy>[];
  childrenExtraCells?: TableBodyMapper<Children, keyof Children>;
  childrenShowHeaders?: ChildrenOrderBy[];
  getChildrenDetailsUrl?: (child: Children) => string;
}

export function ContentTable<
  Row extends BaseContentType,
  OrderBy extends Partial<BaseContentOrderBy | keyof Row | number | symbol>,
  Children extends ChildrenBaseContentType = DefaultChildrenContentType,
  ChildrenOrderBy extends
    | ChildrenBaseContentOrderBy
    | keyof Children
    | number
    | symbol = DefaultChildrenBaseContentOrderBy,
  Parent extends BaseContentType = DefaultBaseContentType,
>({
  parent,
  rows,
  totalCount,
  extraHeads,
  extraCells,
  tableToolbarTitle,
  massDeletion,
  massTrash,
  sortTable,
  openEdit,
  openCreateNewVersion,
  onRemoveFromParent,
  onDelete,
  onTrash,
  onRestore,
  trash,
  openCreate,
  openCreateFromTemplate,
  loadChildren,
  createButtonTitle,
  createFromTemplateButtonTitle,
  autocompleteButtonTitle,
  defaultOrderBy,
  getDetailsUrl,
  template,
  showHeaders,
  disableMultiSelect,
  initFilters,
  childrenExtraHeads,
  childrenExtraCells,
  childrenShowHeaders,
  getChildrenDetailsUrl,
}: ContentTableProps<Row, OrderBy, Children, ChildrenOrderBy, Parent>) {
  const [order, setOrder] = useState<TableOrder>('asc');
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(defaultOrderBy);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ContentRowsPerPageDefault);
  const [openRows, setOpenRows] = useState<string[]>([]);
  const [allChildren, setAllChildren] = useState<Record<string, Children[] | undefined>>({});
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedChild, setSelectedChild] = useState<Row | null>(null);
  const [newChildName, setNewChildName] = useState('');
  const [showAutocompleteField, setShowAutocompleteField] = useState(false);
  /// @NOTE: key = column
  const [filters, setFilters] = useState<Map<OrderBy, { operator: string; value: string }>>(new Map());
  const [searchFilter, setSearchFilter] = useState('contains');

  const filteredChildren = useMemo(() => {
    return template?.allOptions.filter((child) => !rows?.some((it) => child.name === it.name)) ?? [];
  }, [template, rows]);

  const handleAddChildToParentButton = useCallback(() => {
    if (parent && selectedChild && template) {
      template.addChildToParent(parent, selectedChild);
    }
    setSelectedChild(null);
  }, [parent, selectedChild, template]);

  const navigate = useNavigate();
  const navigateToRow = useCallback(
    (row: Row) => {
      if (getDetailsUrl) {
        const path = getDetailsUrl(row);

        navigate(path);
      }
    },
    [getDetailsUrl, navigate],
  );

  const onSort = useCallback(
    (newOrderBy: OrderBy) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(newOrderBy);
    },
    [order, orderBy],
  );

  const toggleCollapse = useCallback(
    async (row: Row, index: number) => {
      const getChildren = async () => {
        if (loadChildren && (!(row.id in allChildren) || !allChildren[row.id])) {
          return loadChildren(row);
        }
        return row.id in allChildren ? allChildren[row.id] : undefined;
      };
      const newChildren = await getChildren();
      setOpenRows((openRows) => {
        if (!openRows.some((rid) => rid === row.id)) {
          setAllChildren((allChildren) => {
            return {
              ...allChildren,
              [row.id]: newChildren,
            };
          });
          return [...openRows, row.id];
        }
        return openRows.filter((rid) => rid !== row.id);
      });
    },
    [allChildren, loadChildren],
  );

  const handleCheckboxClick = useCallback(
    (_: any, id: string) => {
      const selectedIndex = selectedRows.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedRows.slice(0, selectedIndex), selectedRows.slice(selectedIndex + 1));
      }
      setSelectedRows(newSelected);
    },
    [selectedRows],
  );

  const handleSelectedTrash = useCallback(async () => {
    const successfulTrashed = await massTrash?.onMassTrash(selectedRows);
    if (successfulTrashed) {
      setSelectedRows([]);
    }
  }, [selectedRows, massTrash]);
  const handleSelectedDelete = useCallback(async () => {
    const successfulDeleted = await massDeletion?.onMassDelete(selectedRows);
    if (successfulDeleted) {
      setSelectedRows([]);
    }
  }, [selectedRows, massDeletion]);

  const onPageChange = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsPerPageChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const rowsState = useMemo(() => {
    if (rows === null) {
      return 'loading';
    }
    if (rows.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [rows]);

  const canShowCell = useCanShowCell<Row, OrderBy>({ showHeaders });
  const headCells = useContentTableHeadCells<Row, OrderBy>({
    canShowCell,
    trash,
    extraHeads,
  });
  const rowMapper = useContentTableRowMapper<Row, OrderBy>({ trash, extraCells, getNameLinkPath: getDetailsUrl });
  const getTableCells = useContentTableCells<Row, OrderBy>({
    canShowCell,
    trash,
    extraCells,
    clickOnRow: navigateToRow,
    getNameLinkPath: getDetailsUrl,
  });

  const filteredRows = useMemo(
    () =>
      rows
        ?.filter((row) => {
          const filterResult: { column: string | number | symbol; result: boolean }[] = [];
          const rowValues = getTableCells(row);
          filters.forEach((filter, column) => {
            if (column && filter.value) {
              const filterOperators = extendStringFilterOperators();
              const filterOperator = filterOperators.find((fo) => fo.value === filter.operator);
              const filterFunction = filterOperator?.getApplyFilterFn(
                { field: column as string, value: filter.value, operator: filter.operator },
                {
                  field: column as string,
                },
              );
              const rowColumnValue = ((): string | number => {
                // get value from TableCell for filter
                const rv = rowValues.find((rv) => rv.key === column);
                const value = rv?.filterValue ? rv.filterValue() : rv?.value();
                if (value !== undefined) {
                  return value;
                }

                /// Fallback if value is not found (try to get raw data from row)
                const rowRawValue = column in row ? row[column as keyof BaseContentType] : '';
                if (rowRawValue === undefined) {
                  return '';
                }
                console.warn('Can not get value for filtering in ContentTable: ', {
                  column,
                  rowRawValue,
                  filter: filter.value,
                });

                // fallback for values
                if (column in row) {
                  const isString = (value: any) => typeof value === 'string';
                  const isDate = (value: any) => value instanceof Date;
                  const isValue = (value: any) => typeof value === 'string' || typeof value === 'number';
                  if (isString(rowRawValue)) {
                    return rowRawValue as string;
                  }
                  if (isValue(rowRawValue)) {
                    return rowRawValue as string | number;
                  } else if (isDate(rowRawValue)) {
                    return new Date(rowRawValue as Date).toLocaleDateString();
                  }
                }
                console.warn('Can not filter column in ContentTable: ', { column, rowRawValue, filter: filter.value });
                return '';
              })();
              /// @NOTE: use filterFunction as any, because filterFunction only need value, row and nothing else
              filterResult.push({
                column,
                result: filterFunction
                  ? (filterFunction({
                      value: rowColumnValue,
                      id: row.id,
                      row: row,
                    } as any) as boolean)
                  : false,
              });
              return;
            }

            filterResult.push({ column, result: true });
          });

          return filterResult.length > 0 ? filterResult.every((res) => res.result) : true;
        })
        .filter((row) => {
          return searchStarFilter(searchInput, [row.id, row.name, row.displayName]);
        })
        .map((row) => {
          return {
            ...row,
            selectable: row.status !== ContentTypeStatus.Deleted && row.status !== ContentTypeStatus.Deprecated,
          };
        }),
    [rows, getTableCells, filters, searchInput],
  );

  const visibleRows = useMemo(() => {
    if (filteredRows) {
      if (orderBy) {
        return sortTable(order, orderBy, filteredRows, rowMapper)
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            return {
              ...row,
              selectable: row.status !== ContentTypeStatus.Deleted && row.status !== ContentTypeStatus.Deprecated,
            };
          });
      }
      return filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
        return {
          ...row,
          selectable: row.status !== ContentTypeStatus.Deleted && row.status !== ContentTypeStatus.Deprecated,
        };
      });
    }
  }, [filteredRows, orderBy, page, rowsPerPage, sortTable, order, rowMapper]);

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && filteredRows) {
        const newSelected = filteredRows.filter((r) => r.selectable).map((r) => r.id);
        setSelectedRows(newSelected);
        return;
      }
      setSelectedRows([]);
    },
    [filteredRows],
  );

  const isRowOpen = useCallback(
    (row: Row) => {
      return openRows.some((rid) => rid === row.id);
    },
    [openRows],
  );

  const openAllChildren = useCallback(() => {
    if (visibleRows) {
      setOpenRows(visibleRows.map((row) => row.id));
    }
  }, [visibleRows]);

  const closeAllChildren = useCallback(() => {
    setOpenRows([]);
  }, []);

  interface ActionButton {
    key: string;
    title: string;
    sx?: SxProps<Theme>;
    size?: 'small' | 'medium' | 'large';
    onClick: () => void;
    icon: IconProp;
  }
  const getActionButtons = useCallback(
    (row: Row) => {
      const buttons: ActionButton[] = [];

      const editVersionButton = openEdit
        ? ({
            key: 'edit',
            title: 'Edit Version',
            size: 'small',
            icon: faPen,
            onClick: () => {
              openEdit(row);
            },
          } as ActionButton)
        : undefined;

      const restoreButton = onRestore
        ? ({
            key: 'restore',
            title: 'Restore',
            size: 'small',
            icon: faRotateLeft,
            onClick: () => {
              onRestore(row);
            },
          } as ActionButton)
        : undefined;

      const createNewVersionButton = openCreateNewVersion
        ? ({
            key: 'createNewVersion',
            title: 'Create new Version',
            size: 'small',
            icon: faPen,
            onClick: () => {
              openCreateNewVersion(row);
            },
          } as ActionButton)
        : undefined;

      const removeButton = onRemoveFromParent
        ? ({
            key: 'remove',
            title: 'Remove',
            size: 'small',
            icon: faMinus,
            onClick: () => {
              onRemoveFromParent(row);
            },
          } as ActionButton)
        : undefined;

      const trashButton = onTrash
        ? ({
            key: 'trash',
            title: 'Trash',
            size: 'small',
            icon: faTrash,
            onClick: () => {
              onTrash(row);
            },
          } as ActionButton)
        : undefined;

      const deleteButton = onDelete
        ? ({
            key: 'delete',
            title: 'Delete',
            size: 'small',
            icon: faTrash,
            onClick: () => {
              onDelete(row);
            },
          } as ActionButton)
        : undefined;

      // is not trash table (page)
      if (!trash) {
        switch (row.status) {
          case ContentTypeStatus.Draft:
            if (editVersionButton) {
              buttons.push(editVersionButton);
            }
            break;
          case ContentTypeStatus.Final:
          case ContentTypeStatus.Released:
            if (createNewVersionButton) {
              buttons.push(createNewVersionButton);
            }
            break;
          case ContentTypeStatus.Deleted:
          case ContentTypeStatus.Deprecated:
            // restore button is handled below
            break;
        }
        if (parent?.status === ContentTypeStatus.Draft) {
          if (removeButton) {
            buttons.push(removeButton);
          }
        } else if (!parent) {
          switch (row.status) {
            case ContentTypeStatus.Draft:
            case ContentTypeStatus.Final:
            case ContentTypeStatus.Released:
              if (trashButton) {
                buttons.push(trashButton);
              }
              break;
            case ContentTypeStatus.Deleted:
            case ContentTypeStatus.Deprecated:
              if (restoreButton) {
                buttons.push(restoreButton);
              }
              break;
          }
        }
      } else {
        // trash
        switch (row.status) {
          case ContentTypeStatus.Draft:
            break;
          case ContentTypeStatus.Final:
            break;
          case ContentTypeStatus.Released:
            break;
          case ContentTypeStatus.Deleted:
          case ContentTypeStatus.Deprecated:
            if (restoreButton) {
              buttons.push(restoreButton);
            }
            break;
        }
        if (!parent) {
          if (deleteButton) {
            buttons.push(deleteButton);
          }
        }
      }

      return buttons;
    },
    [onDelete, onRemoveFromParent, onRestore, onTrash, openCreateNewVersion, openEdit, parent, trash],
  );

  const tableContainerMaxHeight = useMemo(() => {
    if (parent) {
      return '35vh';
    }
    return '70vh';
  }, [parent]);
  const DefaultButtonVariant = 'contained';

  useEffect(() => {
    if (initFilters) {
      setFilters((filters) => {
        filters.clear();
        initFilters.forEach((value, key) => {
          if (value) {
            filters.set(key, { operator: 'contains', value: value });
          }
        });
        return filters;
      });
    }
  }, [initFilters]);

  return (
    <Stack spacing={2}>
      <ContentTableToolbar<Row>
        massTrash={
          massTrash
            ? {
                handleSelectedTrash,
                tooltipTitle: massTrash.tooltipTitle || 'Delete',
                iconType: massTrash.iconType,
              }
            : undefined
        }
        massDeletion={
          massDeletion
            ? {
                handleSelectedDelete,
                tooltipTitle: massDeletion.tooltipTitle || 'Delete',
                iconType: massDeletion.iconType,
              }
            : undefined
        }
        title={tableToolbarTitle}
        totalCount={totalCount}
        numSelected={selectedRows.length}
        tableData={rows}
        setSearchInput={setSearchInput}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
      />
      {rowsState === 'loading' && (
        <Box
          sx={{
            display: 'flex',
            minHeight: tableContainerMaxHeight,
            maxHeight: tableContainerMaxHeight,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {rowsState === 'empty' && (
        <Box
          sx={{
            display: 'flex',
            minHeight: tableContainerMaxHeight,
            maxHeight: tableContainerMaxHeight,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack spacing={1}>
            <Typography align='center'>No Data</Typography>
            <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
          </Stack>
        </Box>
      )}
      {rowsState === 'loaded' && filteredRows && visibleRows && (
        <TableContainer sx={{ minHeight: tableContainerMaxHeight, maxHeight: tableContainerMaxHeight }}>
          <Table sx={{ minWidth: MainTableMinWidth }} aria-labelledby='Content Table' size='small'>
            <ContentTableHeader<OrderBy>
              setFilters={setFilters}
              filters={filters}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onSort={onSort}
              numSelected={selectedRows.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={filteredRows.length}
              childrenOptions={
                loadChildren
                  ? {
                      openRows: openRows,
                      /// @TODO: open all children and fetch content
                      //openAllChildren: openAllChildren,
                      closeAllChildren: closeAllChildren,
                    }
                  : undefined
              }
              disableMultiSelect={disableMultiSelect}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selectedRows.includes(row.id);
                const labelId = `content-table-checkbox-${index}`;

                return (
                  <React.Fragment key={row.id}>
                    <ContentTableRow
                      key={row.id}
                      hover
                      tabIndex={-1}
                      aria-checked={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      className={clsx(
                        { draft: row.status === ContentTypeStatus.Draft },
                        !trash ? row.status : undefined,
                      )}
                      selected={isItemSelected}
                    >
                      {!disableMultiSelect && (
                        <TableCell key='selectAll' padding='checkbox'>
                          {row.selectable && (
                            <Checkbox
                              onClick={(event) => {
                                handleCheckboxClick(event, row.id);
                              }}
                              color='primary'
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          )}
                        </TableCell>
                      )}
                      {loadChildren && (
                        <TableCell key='toggleCollapse' align='left'>
                          <IconButton aria-label='expand row' size='small' onClick={() => toggleCollapse(row, index)}>
                            {isRowOpen(row) ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                          </IconButton>
                        </TableCell>
                      )}
                      {getTableCells(row).map((tableCell) => (
                        <TableCell
                          key={tableCell.key}
                          colSpan={tableCell.colSpan}
                          component={tableCell.component}
                          align={tableCell.align}
                          sx={{ maxWidth: tableCell.maxWidth }}
                          onClick={tableCell.onClick}
                        >
                          {tableCell.renderValue()}
                        </TableCell>
                      ))}
                      <TableCell key='actions' align='center'>
                        <Stack marginRight={3} justifyContent='end' direction='row' spacing={2}>
                          {getActionButtons(row).map((button) => (
                            <Tooltip key={button.key} title={button.title}>
                              <IconButton
                                sx={button.sx}
                                size={button.size as any} ///< @NOTE: can't convert to StringUnion, just me bro it's an `'small' | 'medium' | 'large'`
                                onClick={button.onClick}
                              >
                                <FontAwesomeIcon icon={button.icon} />
                              </IconButton>
                            </Tooltip>
                          ))}
                        </Stack>
                      </TableCell>
                    </ContentTableRow>
                    <ContentTableRow key={`children-${row.id}`} className={row.status}>
                      {/* headCells.length + 2 is needed because of the new checkbox cell and collapsible cell, which is not included in the headcells  */}
                      <TableCell colSpan={headCells.length + 2} sx={{ p: 0, border: '0px' }}>
                        {loadChildren && (
                          <ChildrenCollapse<Row, Children, ChildrenOrderBy>
                            trash={trash}
                            row={row}
                            allChildren={allChildren}
                            openRows={openRows}
                            childrenExtraHeads={childrenExtraHeads}
                            childrenExtraCells={childrenExtraCells}
                            childrenShowHeaders={childrenShowHeaders}
                            getChildrenDetailsUrl={getChildrenDetailsUrl}
                            parentCols={headCells.length}
                          />
                        )}
                      </TableCell>
                    </ContentTableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Grid container alignItems={'center'}>
        <Grid item xs={6} md={6}>
          {rows && (
            <Stack direction={'row'} spacing={2}>
              {(!parent || parent.status === ContentTypeStatus.Draft) && openCreate && (
                <Button
                  variant={DefaultButtonVariant}
                  color='success'
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={openCreate}
                >
                  {createButtonTitle ?? 'Create'}
                </Button>
              )}
              {(!parent || parent.status === ContentTypeStatus.Draft) && openCreateFromTemplate && (
                <Button
                  variant={DefaultButtonVariant}
                  color='success'
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => {
                    openCreateFromTemplate();
                  }}
                >
                  {createFromTemplateButtonTitle ?? 'Create From Template'}
                </Button>
              )}
              {parent && template && !showAutocompleteField && parent.status === ContentTypeStatus.Draft && (
                <Button
                  variant={DefaultButtonVariant}
                  color='success'
                  startIcon={<FontAwesomeIcon icon={faArrowUp} />}
                  onClick={() => {
                    setShowAutocompleteField(!showAutocompleteField);
                  }}
                >
                  {autocompleteButtonTitle ?? 'Add existing'}
                </Button>
              )}
              {parent && template && showAutocompleteField && parent.status === ContentTypeStatus.Draft && (
                <Stack direction={'row'}>
                  <Autocomplete
                    size='small'
                    sx={{ width: TableAutocompleteFieldWidth }}
                    value={selectedChild}
                    onChange={(_, newSelectedChild: Row | null) => {
                      setSelectedChild(newSelectedChild);
                    }}
                    inputValue={newChildName}
                    onInputChange={(_, newChildName) => {
                      setNewChildName(newChildName);
                    }}
                    options={filteredChildren}
                    autoHighlight
                    getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
                    groupBy={template.groupList?.groupBy}
                    renderGroup={(params) => {
                      if (template.groupList?.renderGroup) {
                        return template.groupList.renderGroup(params) ?? <GroupItems>{params.children}</GroupItems>;
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box component='li' {...props}>
                        <div
                          style={{
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >{`${option.name} - ${option.displayName}`}</div>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={template.label}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  <Tooltip title={template.buttonLabel}>
                    <IconButton disabled={!selectedChild} onClick={handleAddChildToParentButton}>
                      <FontAwesomeIcon icon={faArrowUp} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          )}{' '}
        </Grid>
        <Grid item xs={6} md={6}>
          <Box paddingX={2} width={'100%'} display={'flex'} justifyContent={'end'}>
            {rows && (
              <TablePagination
                rowsPerPageOptions={ContentRowsPerPageOptions}
                // @FIXME: p slots style
                // slotProps={{ selectLabel: { style: {paddingTop: '1rem'} }, displayedRows: { style: {paddingTop: '1rem' } } }}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
