import { Autocomplete, Grid, GridSize, Skeleton, TextField } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  useForm,
} from 'react-hook-form';

import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';

import { ContentTypeStatus } from '../../services/models/content-type-status';
import { BaseContentType } from '../content-table/content-table-body-cell';
import EditFormActionButtons from '../edit-form-action-buttons';

export interface EditFormData {
  name: string;
  displayName: string;
  description: string;
}

interface ContentHeaderFormProps<ContentDto extends BaseContentType, FormData extends EditFormData> {
  content: ContentDto;
  allBusinessProcesses?: BusinessProcessDto[];
  onSave: (data: FieldValues) => void;
  onFinalize: (data: FieldValues) => void;
  onRelease: (data: FieldValues) => void;
  extend?: {
    extendForm: (
      register: UseFormRegister<FormData | Record<string, any>>,
      control: Control<FormData | Record<string, any>>,
      errors: FieldErrors<FormData | Record<string, any>>,
      setValue: UseFormSetValue<FormData | Record<string, any>>,
    ) => { xs?: boolean | GridSize; field: ReactNode }[];
    defaultValues: FormData;
    onInit: (
      content: ContentDto,
      register: UseFormRegister<FormData | Record<string, any>>,
      setValue: UseFormSetValue<FormData | Record<string, any>>,
    ) => void;
  };
}

/// @FIXME: move out BP, make it more generic (use extend for extra values)
export function ContentHeaderForm<
  ContentDto extends BaseContentType & { businessProcess?: BusinessProcessDto },
  FormData extends EditFormData,
>({
  content,
  allBusinessProcesses,
  onSave,
  onFinalize,
  onRelease,
  extend,
  /*...props*/
}: ContentHeaderFormProps<ContentDto, FormData>) {
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData | Record<string, any>>({
    defaultValues: {
      name: content.name,
      displayName: content.displayName,
      description: content.description,
      businessProcess: content.businessProcess?.id,
      ...extend?.defaultValues,
    },
  });

  const [selectedBusinessProcess, setSelectedBusinessProcess] = useState<BusinessProcessDto | null>(null);
  const [newBusinessProcessName, setNewBusinessProcessName] = useState('');

  const ExtendForm = useMemo(() => {
    return extend?.extendForm(register, control, errors, setValue).map((field, index) => {
      return (
        <Grid item xs={4} key={index}>
          {field.field}
        </Grid>
      );
    });
  }, [extend, register, control, errors, setValue]);

  useEffect(() => {
    setValue('name', content.name ?? '');
    setValue('displayName', content.displayName ?? '');
    setValue('description', content.description ?? '');
    setValue('businessProcess', content.businessProcess?.id);
    setSelectedBusinessProcess(content.businessProcess ?? null);
    if (extend?.onInit) {
      extend.onInit(content, register, setValue);
    }
  }, [content, extend, register, setValue]);

  useEffect(() => {
    setValue('businessProcess', selectedBusinessProcess?.id);
  }, [selectedBusinessProcess, setValue]);

  const registerOptions = {
    name: { required: true },
    displayName: { required: true },
    description: undefined,
    businessProcess: undefined,
  };

  const nameGridItemPlace = extend ? 3 : 12;
  const displayNameGridItemPlace = allBusinessProcesses ? 6 : 12;

  return (
    <form>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={nameGridItemPlace}>
          {content ? (
            <Controller
              name='name'
              control={control}
              rules={registerOptions.name}
              defaultValue={content.name}
              render={({ field }) => (
                <TextField
                  margin='dense'
                  label='ID'
                  fullWidth
                  InputProps={{
                    readOnly: content.status !== ContentTypeStatus.Draft,
                  }}
                  error={!!errors.name}
                  helperText={errors.name?.message?.toString()}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.value.toUpperCase().trim());
                  }}
                />
              )}
            />
          ) : (
            <Skeleton variant='text' height={50} />
          )}
        </Grid>
        {extend && (
          <Grid item xs={9}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {ExtendForm}
            </Grid>
          </Grid>
        )}

        <Grid item xs={displayNameGridItemPlace}>
          {content ? (
            <Controller
              name='displayName'
              control={control}
              rules={registerOptions.displayName}
              defaultValue={content.displayName}
              render={({ field }) => (
                <TextField
                  margin='dense'
                  label='Name'
                  fullWidth
                  InputProps={{
                    readOnly: content.status !== ContentTypeStatus.Draft,
                  }}
                  error={!!errors.displayName}
                  helperText={errors.displayName?.message?.toString()}
                  {...field}
                />
              )}
            />
          ) : (
            <Skeleton variant='text' height={50} />
          )}
        </Grid>

        {content && allBusinessProcesses !== undefined && (
          <Grid item xs={displayNameGridItemPlace}>
            <Autocomplete
              disabled={content.status !== ContentTypeStatus.Draft}
              value={selectedBusinessProcess}
              onChange={(_, newSelectedBusinessProcess: BusinessProcessDto | null) => {
                setSelectedBusinessProcess(newSelectedBusinessProcess);
              }}
              inputValue={newBusinessProcessName}
              onInputChange={(_, newBusinessProcessName) => {
                setNewBusinessProcessName(newBusinessProcessName);
              }}
              options={allBusinessProcesses}
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
              renderInput={(params) => (
                <TextField
                  margin='dense'
                  {...params}
                  label='Business Process'
                  inputProps={{
                    ...params.inputProps,
                    readOnly: content.status !== ContentTypeStatus.Draft,
                  }}
                />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Controller
            name='description'
            control={control}
            rules={registerOptions.description}
            defaultValue={content.description}
            render={({ field }) => (
              <TextField
                multiline={true}
                minRows={3}
                margin='dense'
                label='Description'
                fullWidth
                InputProps={{
                  readOnly: content.status !== ContentTypeStatus.Draft,
                }}
                error={!!errors.description}
                helperText={errors.description?.message?.toString()}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <EditFormActionButtons
            data={content}
            onSave={handleSubmit(onSave)}
            onFinalize={handleSubmit(onFinalize)}
            onRelease={handleSubmit(onRelease)}
          />
        </Grid>
      </Grid>
    </form>
  );
}
