import { FunctionComponent, useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import { ContentTableModal } from '../../../components/content-table-modal';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { CreateRulesetDto } from '../../../services/dto/rulesets/create-ruleset.dto';
import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';

interface CreateRulesetFormData {
  name: string;
  displayName: string;
  description: string;
}

interface RulesetModalProps {
  open: boolean;
  onClose: () => void;
  allRisks?: RiskDto[] | null;
  onRulesetCreate: (data: CreateRulesetDto) => void;
}

const CreateRulesetModal: FunctionComponent<RulesetModalProps> = ({
  open,
  onClose,
  onRulesetCreate,
  allRisks,
  ...props
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateRulesetFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
    },
  });

  const onCreate = useCallback(
    async (data: FieldValues, riskIds?: string[]) => {
      onRulesetCreate({
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        riskIds: riskIds,
      });
    },
    [onRulesetCreate],
  );

  return (
    <ContentTableModal<RulesetDto, CreateRulesetFormData, RiskDto>
      title='Create Ruleset'
      type='Create'
      create={{
        onCreate,
      }}
      open={open}
      onClose={onClose}
      form={{
        register,
        setValue,
        watch,
        handleSubmit,
        control,
        reset,
        formState: { errors },
      }}
      contentChildren={{
        title: 'Risks',
        allChildren: allRisks,
        addFieldLabel: 'Add Risks to Ruleset',
      }}
    ></ContentTableModal>
  );
};

export default CreateRulesetModal;
