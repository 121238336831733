import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { confirmReleaseRuleset } from 'src/components/edit-ruleset-header/dialogs';
import { UpdateRulesetDto } from 'src/services/dto/rulesets/update-ruleset.dto';
import { ContentTypeStatus } from 'src/services/models/content-type-status';
import { compareContentArray } from 'src/shared/utils';

import { ContentTableModal } from '../../../components/content-table-modal';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { CreateNewVersionRulesetDto } from '../../../services/dto/rulesets/create-new-version-ruleset.dto';
import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';

interface CreateRulesetNewVersionFormData {
  name: string;
  displayName: string;
  description: string;
  riskIds?: string[];
}

interface RulesetModalProps {
  open: boolean;
  ruleset: RulesetDto;
  onClose: () => void;
  allRisks: RiskDto[] | null;
  loadRisksFromRuleset: (ruleset: RulesetDto) => Promise<RiskDto[]>;
  onRulesetCreateNewVersion: (id: string, newRuleset: CreateNewVersionRulesetDto, content: RulesetDto) => void;
  onRulesetRelease: (riskFunctionId: string, data: UpdateRulesetDto) => void;
}

const CreateRulesetNewVersionModal: FunctionComponent<RulesetModalProps> = ({
  open,
  ruleset,
  onClose,
  allRisks,
  loadRisksFromRuleset,
  onRulesetRelease,
  onRulesetCreateNewVersion,
  ...props
}) => {
  const [oldRisksFromRuleset, setOldRisksFromRuleset] = useState<RiskDto[] | undefined>(undefined);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateRulesetNewVersionFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
    },
  });

  const fetchOldRisksFromRuleset = useCallback(async () => {
    setOldRisksFromRuleset(await loadRisksFromRuleset(ruleset));
  }, [loadRisksFromRuleset, ruleset]);

  const confirm = useConfirm();

  const onRelease = useCallback(
    async (data: FieldValues) => {
      if (oldRisksFromRuleset) {
        const { canRelease, dialog } = confirmReleaseRuleset(confirm, oldRisksFromRuleset);
        dialog
          .then(() => {
            if (canRelease) {
              onRulesetRelease(ruleset.id, {
                status: ContentTypeStatus.Released,
              });
            }
          })
          .catch(() => {
            /* ... */
          });
      }
    },
    [confirm, onRulesetRelease, oldRisksFromRuleset, ruleset.id],
  );

  const onCreateNewVersion = useCallback(
    (data: FieldValues, riskIds?: string[]) => {
      onRulesetCreateNewVersion(
        ruleset.id,
        {
          displayName: data.displayName,
          description: data.description,
          riskIds: riskIds,
        },
        ruleset,
      );
    },
    [onRulesetCreateNewVersion, ruleset],
  );

  const hasChanges = useCallback(
    (data: FieldValues, content: RulesetDto, newChildren: RiskDto[]) => {
      return (
        data.name !== content.name ||
        data.displayName !== content.displayName ||
        data.description !== content.description ||
        !compareContentArray(newChildren, oldRisksFromRuleset ?? [])
      );
    },
    [oldRisksFromRuleset],
  );

  useEffect(() => {
    setValue('name', ruleset.name);
    setValue('displayName', ruleset.displayName);
    setValue('description', ruleset.description);
  }, [ruleset.description, ruleset.displayName, ruleset.name, setValue]);

  useEffect(() => {
    fetchOldRisksFromRuleset();
  }, [fetchOldRisksFromRuleset]);

  return (
    <ContentTableModal<RulesetDto, CreateRulesetNewVersionFormData, RiskDto>
      title='Edit Ruleset'
      type='CreateNewVersion'
      createNewVersion={{
        onCreateNewVersion,
        hasChanges,
        content: ruleset,
        onRelease,
      }}
      open={open}
      onClose={onClose}
      form={{
        register,
        setValue,
        watch,
        handleSubmit,
        control,
        reset,
        formState: { errors },
      }}
      contentChildren={{
        title: 'Risks',
        loadChildren: loadRisksFromRuleset,
        allChildren: allRisks,
        addFieldLabel: 'Add Risks to Ruleset',
      }}
    ></ContentTableModal>
  );
};

export default CreateRulesetNewVersionModal;
