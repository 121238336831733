import { Paper, Stack } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ContentTable } from 'src/components/content-table';
import {
  RulesetRisksContentTableExtraHeads,
  RulesetRisksOrderBy,
  useRiskContentTableExtraCells,
} from 'src/components/risks/risk-table-head';

import RestoreModal from '../../components/restore-modal';
import TrashModal from '../../components/trash-modal';
import { RiskLevelDto } from '../../services/dto/risk-levels/risk-level.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { CreateFromExistingRulesetDto } from '../../services/dto/rulesets/create-from-existing-ruleset.dto';
import { CreateNewVersionRulesetDto } from '../../services/dto/rulesets/create-new-version-ruleset.dto';
import { CreateRulesetDto } from '../../services/dto/rulesets/create-ruleset.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { UpdateRulesetDto } from '../../services/dto/rulesets/update-ruleset.dto';
import { useRiskLevelService, useRiskService, useRulesetService } from '../../services/hooks';
import { restoreContent, trashContent } from '../../shared/common';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';
import { confirmTrashRulesets } from './delete-dialogs';
import CreateRulesetModal from './ruleset-modal/create';
import CreateRulesetFromTemplateModal from './ruleset-modal/create-from-template';
import CreateRulesetNewVersionModal from './ruleset-modal/create-new-version';
import UpdateRulesetModal from './ruleset-modal/update';
import { RulesetsOrderBy, sortRulesets } from './rulesets-table-head';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetsPageProps {}

const RulesetsPage: FunctionComponent<RulesetsPageProps> = ({ ...props }) => {
  const [rulesets, setRulesets] = useState<RulesetDto[] | null>(null);
  const [riskLevels, setRiskLevels] = useState<RiskLevelDto[] | null>(null);
  const [allRisks, setAllRisks] = useState<RiskDto[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);

  const [modalRuleset, setModalRuleset] = useState<RulesetDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'createFromTemplate' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined
  >(undefined);

  const rulesetService = useRulesetService();
  const riskService = useRiskService();
  const riskLevelService = useRiskLevelService();

  const fetchRulesets = useCallback(async () => {
    const rulesets = await rulesetService.getRulesets();
    setRulesets(rulesets.data);
    setTotalCount(rulesets.meta?.totalCount ?? rulesets.data.length);
    return rulesets.data;
  }, [rulesetService]);

  const fetchRiskLevels = useCallback(async () => {
    const riskLevelsResult = await riskLevelService.getRisklevels();
    setRiskLevels(riskLevelsResult.data);
  }, [riskLevelService]);

  const fetchAllRisks = useCallback(async () => {
    const risks = await riskService.getRisks();
    setAllRisks(risks.data);
    return risks.data;
  }, [riskService]);

  const openCreateRuleset = useCallback(() => {
    setModalRuleset(undefined);
    setCurrentOpenModal('create');
  }, []);

  const openCreateFromTemplateRuleset = useCallback(() => {
    setModalRuleset(undefined);
    setCurrentOpenModal('createFromTemplate');
  }, []);

  const openEditRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const openTrashRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('trash');
  }, []);

  const openRestoreRuleset = useCallback((ruleset: RulesetDto) => {
    setModalRuleset(ruleset);
    setCurrentOpenModal('restore');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRuleset(undefined);
  }, []);

  const getRuleset = useCallback(
    async (id: string) => {
      const risksResult = await rulesetService.getRuleset(id);
      return risksResult.data;
    },
    [rulesetService],
  );

  const onRulesetCreate = useCallback(
    async (data: CreateRulesetDto) => {
      const res = await rulesetService.createRuleset(data);
      setRulesets((rulesets) => [res.data, ...(rulesets ?? [])]);
      closeModal();
    },
    [closeModal, rulesetService],
  );
  const onRulesetCreateFromTemplate = useCallback(
    async (template: RulesetDto, data: CreateFromExistingRulesetDto) => {
      const res = await rulesetService.createFromExistingRuleset(template.id, data);
      setRulesets((rulesets) => [res.data, ...(rulesets ?? [])]);
      closeModal();
    },
    [closeModal, rulesetService],
  );

  const onCreateNewRulesetVersion = useCallback(
    async (id: string, data: CreateNewVersionRulesetDto, origin: RulesetDto) => {
      const res = await rulesetService.createNewRulesetVersion(id, data);
      setRulesets((rulesets) => [res.data, ...(rulesets ?? [])].filter((ruleset) => ruleset.id != origin.id));
      closeModal();
    },
    [closeModal, rulesetService],
  );

  const onRulesetUpdate = useCallback(
    async (id: string, data: UpdateRulesetDto) => {
      const res = await rulesetService.updateRuleset(id, data);

      setRulesets((rulesets) => {
        if (rulesets) {
          return rulesets.map((ruleset) => {
            if (ruleset.id === id) {
              return { ...ruleset, ...res.data };
            }
            return ruleset;
          });
        }
        return null;
      });
      closeModal();
    },
    [closeModal, rulesetService],
  );

  const onRulesetFinalize = useCallback(
    async (id: string, data: UpdateRulesetDto) => {
      try {
        const res = await rulesetService.updateRuleset(id, data);
        setRulesets((rulesets) => {
          if (rulesets) {
            return rulesets.map((ruleset) => {
              if (ruleset.id === id) {
                return { ...ruleset, ...res.data };
              }
              return ruleset;
            });
          }
          return null;
        });
        closeModal();
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [closeModal, rulesetService],
  );

  const onRulesetRelease = useCallback(
    async (id: string, data: UpdateRulesetDto) => {
      try {
        const res = await rulesetService.updateRuleset(id, data);
        setRulesets((rulesets) => {
          if (rulesets) {
            return rulesets.map((ruleset) => {
              if (ruleset.id === id) {
                return { ...ruleset, ...res.data };
              }
              return ruleset;
            });
          }
          return null;
        });
        closeModal();
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [closeModal, rulesetService],
  );

  const trashRuleset = useCallback(
    (rulesetId: string) => {
      trashContent(rulesetId, (id) => rulesetService.trashRuleset(id), getRuleset, setRulesets, closeModal);
    },
    [closeModal, getRuleset, rulesetService],
  );

  const restoreRuleset = useCallback(
    (rulesetId: string) => {
      restoreContent(rulesetId, (id) => rulesetService.restoreRuleset(id), getRuleset, setRulesets, closeModal);
    },
    [closeModal, getRuleset, rulesetService],
  );

  const onRulesetsDeleted = useCallback(
    async (rulesetIds: string[]) => {
      await rulesetService.trashRulesets(rulesetIds);
      fetchRulesets();
      // @NOTE(workaround): fetch all, don't know which got deleted (removed) or updated (status)
      /*
      setRulesets((rulesets) => {
        if (rulesets) {
          return rulesets.filter((ruleset) => !rulesetIds.some((rId) => rId == ruleset.id));
        }
        return null;
      });
      */
    },
    [fetchRulesets, rulesetService],
  );

  const onRulesetTrash = useCallback(
    (id: string) => {
      trashRuleset(id);
    },
    [trashRuleset],
  );

  const onRulesetRestore = useCallback(
    (id: string) => {
      restoreRuleset(id);
    },
    [restoreRuleset],
  );

  const getRisksFromRuleset = useCallback(
    async (ruleset: RulesetDto) => {
      const risksResult = await rulesetService.getRisksFromRuleset(ruleset.id);
      return risksResult.data;
    },
    [rulesetService],
  );

  const confirm = useConfirm();

  const onMassTrash = useCallback(
    async (rulesetIds: string[]) => {
      const { dialog } = await confirmTrashRulesets(confirm, rulesetIds);
      return dialog
        .then(() => {
          onRulesetsDeleted(rulesetIds);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [confirm, onRulesetsDeleted],
  );

  const getRulesetUrl = useCallback((ruleset: RulesetDto) => {
    return `/rulesets/${ruleset.id}`;
  }, []);

  const getRiskUrl = useCallback((risk: RiskDto) => {
    return `/risks/${risk.id}`;
  }, []);

  const RiskContentTableExtraCells = useRiskContentTableExtraCells(riskLevels ?? []);

  useEffect(() => {
    fetchRiskLevels();
    fetchRulesets();
    fetchAllRisks();
  }, [fetchAllRisks, fetchRiskLevels, fetchRulesets]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <ContentTable<RulesetDto, RulesetsOrderBy, RiskDto, RulesetRisksOrderBy>
          tableToolbarTitle='Rulesets'
          totalCount={totalCount}
          rows={rulesets}
          sortTable={sortRulesets}
          openCreate={openCreateRuleset}
          openCreateFromTemplate={openCreateFromTemplateRuleset}
          createButtonTitle='Create Ruleset'
          createFromTemplateButtonTitle='Create Ruleset From Template'
          openCreateNewVersion={openCreateNewVersionRuleset}
          openEdit={openEditRuleset}
          onTrash={openTrashRuleset}
          onRestore={openRestoreRuleset}
          getDetailsUrl={getRulesetUrl}
          loadChildren={getRisksFromRuleset}
          childrenExtraCells={RiskContentTableExtraCells}
          childrenExtraHeads={RulesetRisksContentTableExtraHeads}
          getChildrenDetailsUrl={getRiskUrl}
          defaultOrderBy='name'
          massTrash={{
            onMassTrash: onMassTrash,
            tooltipTitle: 'Trash selected',
            iconType: 'delete',
          }}
        />
      </Paper>
      <CreateRulesetModal
        open={currentOpenModal === 'create'}
        onClose={closeModal}
        onRulesetCreate={onRulesetCreate}
        allRisks={allRisks}
      />
      <CreateRulesetFromTemplateModal
        open={currentOpenModal === 'createFromTemplate'}
        onClose={closeModal}
        onRulesetCreateFromTemplate={onRulesetCreateFromTemplate}
        allRisks={allRisks ?? []}
        allRulesets={rulesets ?? []}
        loadRisksFromRuleset={getRisksFromRuleset}
      />
      {modalRuleset && (
        <UpdateRulesetModal
          open={currentOpenModal === 'update'}
          onClose={closeModal}
          onRulesetUpdate={onRulesetUpdate}
          onRulesetFinalize={onRulesetFinalize}
          ruleset={modalRuleset}
          loadRisksFromRuleset={getRisksFromRuleset}
          allRisks={allRisks}
        />
      )}
      {modalRuleset && (
        <CreateRulesetNewVersionModal
          open={currentOpenModal === 'createNewVersion'}
          onClose={closeModal}
          ruleset={modalRuleset}
          onRulesetCreateNewVersion={onCreateNewRulesetVersion}
          loadRisksFromRuleset={getRisksFromRuleset}
          allRisks={allRisks}
          onRulesetRelease={onRulesetRelease}
        />
      )}
      {modalRuleset && (
        <TrashModal<RulesetDto, undefined>
          title='Trash Ruleset'
          contentName='Ruleset'
          contentId={modalRuleset.id}
          open={currentOpenModal === 'trash'}
          onClose={closeModal}
          onTrash={onRulesetTrash}
          getContent={getRuleset}
        />
      )}
      {modalRuleset && (
        <RestoreModal<RulesetDto, undefined>
          title='Restore Ruleset'
          contentName='Ruleset'
          contentId={modalRuleset.id}
          open={currentOpenModal === 'restore'}
          onClose={closeModal}
          onRestore={onRulesetRestore}
          getContent={getRuleset}
        />
      )}
    </Stack>
  );
};

export default RulesetsPage;
