import { grey } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMemo } from 'react';

import { TableHeadCell } from '../content-table-head/content-table-header-filter';
import { TableBodyMapper } from '../content-table/content-table-body-cell';
import {
  useCanShowCell,
  useContentTableCells,
  useContentTableHeadCells,
  useContentTableRowMapper,
} from '../content-table/hooks';
import {
  ChildrenBaseContentOrderBy,
  ChildrenBaseContentType,
  DefaultChildrenBaseContentOrderBy,
} from './children-table-body-cell';

interface ChildrenTableProps<
  Children extends ChildrenBaseContentType,
  ChildrenOrderBy extends
    | ChildrenBaseContentOrderBy
    | keyof Children
    | number
    | symbol = DefaultChildrenBaseContentOrderBy,
> {
  trash?: boolean;
  rows: Children[];
  extraHeads?: TableHeadCell<ChildrenOrderBy>[];
  extraCells?: TableBodyMapper<Children, keyof Children>;
  showHeaders?: ChildrenOrderBy[];
  getChildrenDetailsUrl?: (child: Children) => string;
  parentCols?: number;
}

export function ChildrenTable<
  Children extends ChildrenBaseContentType,
  ChildrenOrderBy extends
    | ChildrenBaseContentOrderBy
    | keyof Children
    | number
    | symbol = DefaultChildrenBaseContentOrderBy,
>({
  trash,
  rows,
  extraHeads,
  extraCells,
  showHeaders,
  getChildrenDetailsUrl,
  parentCols,
  /*...props*/
}: ChildrenTableProps<Children, ChildrenOrderBy>) {
  const defaultShowHeaders = useMemo(() => {
    const ret: ChildrenOrderBy[] = [
      'name' as ChildrenOrderBy,
      'displayName' as ChildrenOrderBy,
      'status' as ChildrenOrderBy,
    ];
    if (extraHeads) {
      extraHeads.forEach((extraHead) => {
        if (extraHead.id) {
          ret.push(extraHead.id);
        }
      });
    }
    return ret;
  }, [extraHeads]);
  const canShowCell = useCanShowCell<Children, ChildrenOrderBy>({ showHeaders: showHeaders ?? defaultShowHeaders });

  const headCells = useContentTableHeadCells<Children, ChildrenOrderBy>({
    canShowCell,
    trash,
    extraHeads,
  });
  const rowMapper = useContentTableRowMapper<Children, ChildrenOrderBy>({
    extraCells,
    getNameLinkPath: getChildrenDetailsUrl,
  });
  const getTableCells = useContentTableCells<Children, ChildrenOrderBy>({
    canShowCell,
    trash,
    extraCells,
    getNameLinkPath: getChildrenDetailsUrl,
  });

  /// @TODO: colSpan with parentCols to align children columns with parent columns, add extra TableHead TableCells for spacing
  const colSpan = useMemo(() => headCells.length, [headCells]);

  return (
    <TableContainer component={Paper} sx={{ width: '100%', bgcolor: grey[100], px: 4 }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id as string} align={headCell.align} sx={{ maxWidth: headCell.maxWidth }}>
                {headCell.label && <b>{headCell.label}</b>}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow key={'empty-result'}>
              <TableCell colSpan={colSpan}>No records found</TableCell>
            </TableRow>
          )}
          {rows.length > 0 &&
            rows.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {getTableCells(row).map((tableCell) => (
                  <TableCell
                    key={tableCell.key}
                    colSpan={tableCell.colSpan}
                    component={tableCell.component}
                    align={tableCell.align}
                    sx={{ maxWidth: tableCell.maxWidth }}
                  >
                    {tableCell.renderValue()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
