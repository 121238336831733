import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';
import { FieldValues } from 'react-hook-form';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import ContentHeader from '../content-header';
import { BaseContentType } from '../content-table/content-table-body-cell';
import { confirmFinalizeRuleset, confirmReleaseRuleset } from './dialogs';

export interface EditRulesetFormData {
  name: string;
  displayName: string;
  description: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditRulesetFormProps {
  ruleset: RulesetDto;
  rulesetVersionHistory: RulesetDto[];
  risks: RiskDto[];
  onSave: (data: FieldValues) => void;
  onConfirmFinalize: (ruleset: RulesetDto, data: FieldValues) => void;
  onConfirmRelease: (ruleset: RulesetDto, data: FieldValues) => void;
}

const EditRulesetForm: FunctionComponent<EditRulesetFormProps> = ({
  ruleset,
  risks,
  rulesetVersionHistory,
  onSave,
  onConfirmFinalize,
  onConfirmRelease,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const onFinalize = useCallback(
    async (data: FieldValues) => {
      const { canFinalize, dialog } = confirmFinalizeRuleset(confirm, risks);
      dialog
        .then(() => {
          if (canFinalize) {
            onConfirmFinalize(ruleset, {
              ...data,
              status: ContentTypeStatus.Final,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          /* ... */
        });
    },
    [ruleset, confirm, risks, onConfirmFinalize],
  );

  const onRelease = useCallback(
    async (data: FieldValues) => {
      const { canRelease, dialog } = confirmReleaseRuleset(confirm, risks);
      dialog
        .then(() => {
          if (canRelease) {
            onConfirmRelease(ruleset, {
              ...data,
              status: ContentTypeStatus.Final,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          /* ... */
        });
    },
    [confirm, ruleset, risks, onConfirmRelease],
  );

  const getDetailsUrl = (ruleset: BaseContentType) => {
    return `/rulesets/${ruleset.id}`;
  };

  return (
    <ContentHeader<RulesetDto, undefined, EditRulesetFormData>
      title='Ruleset Header'
      content={ruleset}
      versionHistory={rulesetVersionHistory}
      onSave={onSave}
      onFinalize={onFinalize}
      onRelease={onRelease}
      getVersionHistoryDetailsUrl={getDetailsUrl}
    />
  );
};

export default EditRulesetForm;
