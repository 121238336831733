import {
  faCheck,
  faCheckDouble,
  faFileCircleXmark,
  faMinus,
  faPlus,
  faShareAll,
  faXmark,
} from '@awesome.me/kit-6741fca89c/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Autocomplete,
  Box,
  Button,
  Typography,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Stack,
  IconButton,
} from '@mui/material';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, Controller } from 'react-hook-form';

import { TableAutocompleteFieldWidth } from '../../styles/content-tables';
import { childrenListMaxHeight } from '../../styles/create-update-modal';

interface ContentBaseDto {
  name: string;
  displayName: string;
  description: string;
  id: string;
}
interface MinimalFormData {
  name?: string;
  displayName?: string;
  description?: string;
  keepReference?: boolean;
}
interface OriginChildData {
  name: string;
  displayName: string;
  id: string;
}

interface ContentTableModalProps<
  ContentDto extends ContentBaseDto,
  FormData extends MinimalFormData = any,
  ChildData extends OriginChildData | undefined = undefined,
> {
  title: string;
  open: boolean;
  disabled?: boolean;
  onClose: () => void;
  type: 'Create' | 'CreateFromTemplate' | 'CreateNewVersion' | 'Update';
  create?: {
    onCreate: (data: FieldValues, childrenIds?: string[]) => void;
  };
  createFromTemplate?: {
    onCreateFromTemplate: (data: FieldValues, template: ContentDto, childrenIds?: string[]) => void;
    templates?: ContentDto[];
    onSelectNewTemplate: (newTemplate: ContentDto) => void;
  };
  createNewVersion?: {
    onCreateNewVersion: (data: FieldValues, childrenIds?: string[], content?: ContentDto) => void;
    onRelease: (data: FieldValues, childrenIds?: string[], content?: ContentDto) => void;
    hasChanges: (data: FieldValues, content: ContentDto, newChildren: ChildData[]) => boolean;
    content: ContentDto;
  };
  update?: {
    onUpdate: (data: FieldValues, childrenIds?: string[], content?: ContentDto) => void;
    onFinalize: (data: FieldValues, childrenIds?: string[]) => void;
    content: ContentDto;
  };
  contentChildren?: {
    loadChildren?: (content: ContentDto) => Promise<ChildData[]>;
    allChildren?: ChildData[] | null;
    title: string;
    addFieldLabel?: string;
  };
  form: {
    /// @TODO: type safe form (get rid of any)
    register: any /*UseFormRegister<Record<string, any> | FormData>;*/;
    setValue: any /*UseFormSetValue<Record<string, any> | FormData>;*/;
    watch: any /*UseFormWatch<Record<string, any> | FormData>*/;
    handleSubmit: any /*UseFormHandleSubmit<Record<string, any> | FormData>;*/;
    control: any /*Control<Record<string, any> | FormData>;*/;
    reset: any /*UseFormReset<Record<string, any> | FormData>;*/;
    formState: { errors: any /*FieldErrors<Record<string, any> | FormData>*/ };
  };
}

export function ContentTableModal<
  ContentDto extends ContentBaseDto,
  FormData extends MinimalFormData = any,
  ChildData extends OriginChildData | undefined = undefined,
>({
  title,
  open,
  disabled,
  type,
  create,
  createFromTemplate,
  createNewVersion,
  update,
  contentChildren,
  form,
  children,
  ...props
}: PropsWithChildren<ContentTableModalProps<ContentDto, FormData, ChildData>>) {
  const [selectedTemplate, setSelectedTemplate] = useState<ContentDto | null>(null);
  const [templateSearch, setTemplateSearch] = useState('');
  const [childrenData, setChildrenData] = useState<ChildData[] | undefined>(undefined);
  const [selectedChild, setSelectedChild] = useState<ChildData | null>(null);
  const [newChildName, setNewChildName] = useState('');

  const onTemplateChange = useCallback(
    (newTemplate: ContentDto | null) => {
      if (newTemplate) {
        setSelectedTemplate(newTemplate);
        createFromTemplate?.onSelectNewTemplate(newTemplate);
        setChildrenData(undefined);
        if (contentChildren?.loadChildren) {
          contentChildren.loadChildren(newTemplate).then((childrenData) => {
            setChildrenData(childrenData);
          });
        }
      } else {
        setSelectedTemplate(newTemplate);
        setChildrenData([]);
      }
    },
    [contentChildren, createFromTemplate],
  );

  const onClose = useCallback(() => {
    props.onClose();
    form.reset();
    setSelectedTemplate(null);
    setChildrenData(undefined);
    setSelectedChild(null);
    setNewChildName('');
  }, [props, form]);

  const formData: FormData = form.watch();

  const onSubmit = useCallback(
    (data: FieldValues) => {
      const childrenIds = childrenData?.map((c) => c?.id).filter((c) => c) as string[] | undefined;
      if (type === 'Create' && create) {
        create.onCreate(data, childrenIds);
      } else if (type === 'CreateFromTemplate' && createFromTemplate && selectedTemplate) {
        createFromTemplate.onCreateFromTemplate(data, selectedTemplate, childrenIds);
      } else if (type === 'CreateNewVersion' && createNewVersion) {
        createNewVersion.onCreateNewVersion(data, childrenIds, createNewVersion.content);
      } else if (type === 'Update' && update) {
        update.onUpdate(data, childrenIds, update.content);
      }
      onClose();
      form.reset();
    },
    [childrenData, create, createFromTemplate, createNewVersion, form, onClose, selectedTemplate, type, update],
  );

  const onFinalize = useCallback(
    (data: FieldValues) => {
      if (type === 'Update' && update) {
        update.onFinalize(
          data,
          childrenData ? childrenData.map((child) => child?.id ?? '').filter((childId) => childId) : [],
        );
      }
      onClose();
      form.reset();
    },
    [form, onClose, type, update, childrenData],
  );

  const onRelease = useCallback(
    (data: FieldValues) => {
      if (type === 'CreateNewVersion' && createNewVersion) {
        createNewVersion.onRelease(data);
      }
      onClose();
      form.reset();
    },
    [form, onClose, type, createNewVersion],
  );

  const addChild = useCallback((child: ChildData) => {
    setChildrenData((childrenData) => (childrenData ? [child, ...childrenData] : [child]));
  }, []);
  const removeChild = useCallback((child: ChildData) => {
    setChildrenData((childrenData) => childrenData?.filter((c) => c?.id !== child?.id));
  }, []);

  const fetchChildren = useCallback(async () => {
    if (contentChildren?.loadChildren) {
      if (type === 'CreateFromTemplate' && selectedTemplate) {
        setChildrenData(await contentChildren.loadChildren(selectedTemplate));
      } else if (type === 'CreateNewVersion' && createNewVersion?.content) {
        setChildrenData(await contentChildren.loadChildren(createNewVersion.content));
      } else if (type === 'Update' && update?.content) {
        setChildrenData(await contentChildren.loadChildren(update.content));
      } else if (type === 'Create') {
        setChildrenData([]);
      }
    } else if (type === 'CreateFromTemplate') {
      setChildrenData([]);
    }
  }, [contentChildren?.loadChildren, type, selectedTemplate, createNewVersion?.content, update?.content]);
  /// @FIXME: keep loadChildren, don't relay on full createNewVersion or update, when only .content id needed, fetchChildren get's triggered a lot

  const filteredChildren = useMemo(() => {
    return contentChildren?.allChildren?.filter((child) => !childrenData?.some((it) => child?.name === it?.name)) ?? [];
  }, [childrenData, contentChildren?.allChildren]);

  const releaseButtonDisabled = useMemo(() => {
    if (createNewVersion) {
      return createNewVersion.hasChanges(formData, createNewVersion.content, childrenData ?? []);
    }
    return true;
  }, [createNewVersion, formData, childrenData]);

  const onAddChild = useCallback(() => {
    if (selectedChild) {
      addChild(selectedChild);
      setSelectedChild(null);
    }
  }, [addChild, selectedChild]);

  useEffect(() => {
    fetchChildren();
  }, [fetchChildren]);

  const registerOptions = useMemo(() => {
    return {
      name: {
        required: 'ID is required.',
        validate: (value: string) => {
          if (value === selectedTemplate?.name) {
            return 'Already defined. Please use another ID.';
          }
          return true;
        },
      },
      displayName: { required: true },
      description: undefined,
      keepReference: undefined,
    };
  }, [selectedTemplate]);

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {type === 'CreateFromTemplate' && createFromTemplate?.templates && (
              <Grid item xs={12}>
                <Autocomplete
                  sx={{ mt: 1 }}
                  //disableClearable
                  value={selectedTemplate}
                  onChange={(_, newTemplate: ContentDto | null) => {
                    onTemplateChange(newTemplate);
                  }}
                  inputValue={templateSearch}
                  onInputChange={(_, templateSearch) => {
                    setTemplateSearch(templateSearch);
                  }}
                  options={createFromTemplate.templates}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.name}
                    </Box>
                  )}
                  renderInput={(field) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        ...field.inputProps,
                        autoComplete: 'template', // disable autocomplete and autofill
                      }}
                      label='Available Templates'
                      placeholder={'Select a Template'}
                      error={!!form.formState.errors.template}
                      helperText={form.formState.errors.template?.message?.toString()}
                    />
                  )}
                />
              </Grid>
            )}
            {/*Minimal Form (in all Modals)*/}
            <Grid item xs={12}>
              <Controller
                name='name'
                control={form.control}
                rules={registerOptions.name}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled}
                    margin='dense'
                    label='ID*'
                    fullWidth
                    error={!!form.formState.errors.name}
                    placeholder={type === 'CreateFromTemplate' ? 'Please enter a unique ID.' : undefined}
                    helperText={form.formState.errors.name?.message?.toString()}
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value.toUpperCase().trim());
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name='displayName'
                control={form.control}
                rules={registerOptions.displayName}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled}
                    margin='dense'
                    label='Name*'
                    fullWidth
                    placeholder={selectedTemplate?.displayName}
                    error={!!form.formState.errors.displayName}
                    helperText={form.formState.errors.displayName?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>

            {/*(extended) Form*/}
            {children}

            <Grid item xs={12}>
              <Controller
                name='description'
                control={form.control}
                rules={registerOptions.description}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled}
                    multiline={true}
                    margin='dense'
                    label='Description'
                    fullWidth
                    placeholder={selectedTemplate?.description}
                    error={!!form.formState.errors.description}
                    helperText={form.formState.errors.description?.message?.toString()}
                    {...field}
                  />
                )}
              />
            </Grid>

            {/*Content Children*/}
            {contentChildren && (
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  {contentChildren.title}
                </Typography>
                <List sx={{ width: '100%', maxHeight: childrenListMaxHeight, overflow: 'auto' }}>
                  {childrenData?.map((child) => {
                    if (child) {
                      const labelId = `checkbox-list-label-${child.id}`;
                      return (
                        <ListItem
                          key={child.id}
                          disablePadding
                          secondaryAction={
                            <IconButton
                              size='small'
                              onClick={() => {
                                removeChild(child);
                              }}
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            id={labelId}
                            primary={child.name ? `${child.name} - ${child.displayName}` : child.displayName}
                            primaryTypographyProps={{
                              style: {
                                maxWidth: '90%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              },
                            }}
                          />
                        </ListItem>
                      );
                    }
                    return <></>;
                  })}
                </List>
              </Grid>
            )}
            {contentChildren && childrenData && childrenData.length === 0 && (
              <Grid item xs={12}>
                <Stack spacing={1} direction='row'>
                  <FontAwesomeIcon icon={faFileCircleXmark} />
                  <Typography align='center'>No Data</Typography>
                </Stack>
              </Grid>
            )}
            {contentChildren && (
              <Grid item xs={12}>
                <Stack direction={'row'}>
                  <Autocomplete
                    size='small'
                    sx={{ width: TableAutocompleteFieldWidth }}
                    value={selectedChild}
                    onChange={(_, newSelectedChild: ChildData | null) => {
                      setSelectedChild(newSelectedChild);
                    }}
                    inputValue={newChildName}
                    onInputChange={(_, newChildName) => {
                      setNewChildName(newChildName);
                    }}
                    options={filteredChildren}
                    autoHighlight
                    getOptionLabel={(option) => (option ? `${option.name} - ${option.displayName}` : '')}
                    renderOption={(props, option) => (
                      <Box component='li' {...props}>
                        <div
                          style={{
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {option ? `${option.name} - ${option.displayName}` : ''}
                        </div>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={contentChildren.addFieldLabel}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  <IconButton
                    disabled={!selectedChild}
                    onClick={() => {
                      onAddChild();
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </Stack>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {/**keep reference Form*/}
          {type === 'CreateFromTemplate' && (
            <FormControlLabel
              control={
                <Controller
                  name='keepReference'
                  control={form.control}
                  rules={registerOptions.keepReference}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value ?? false}
                      disabled={disabled}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              }
              label='Keep Reference'
            />
          )}

          {/*Form (submit) Buttons*/}
          <Stack direction={'row'} spacing={1} marginX={2} marginBottom={2}>
            <Button
              type='button'
              onClick={() => {
                onClose();
              }}
              variant='contained'
              color='error'
              endIcon={<FontAwesomeIcon icon={faXmark} />}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='success'
              disabled={disabled}
              endIcon={<FontAwesomeIcon icon={faCheck} />}
            >
              Save
            </Button>
            {type === 'Update' && (
              <Button
                onClick={() => {
                  onFinalize(formData);
                }}
                variant='contained'
                color='success'
                disabled={disabled}
                endIcon={<FontAwesomeIcon icon={faCheckDouble} />}
              >
                Finalize
              </Button>
            )}
            {type === 'CreateNewVersion' && createNewVersion && (
              <Button
                onClick={() => {
                  onRelease(formData);
                }}
                variant='contained'
                color='info'
                disabled={releaseButtonDisabled}
                endIcon={<FontAwesomeIcon icon={faShareAll} />}
              >
                Release
              </Button>
            )}
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
}
