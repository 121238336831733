import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { confirmFinalizeRuleset } from 'src/components/edit-ruleset-header/dialogs';
import { ContentTypeStatus } from 'src/services/models/content-type-status';

import { ContentTableModal } from '../../../components/content-table-modal';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';
import { UpdateRulesetDto } from '../../../services/dto/rulesets/update-ruleset.dto';

interface UpdateRulesetFormData {
  name: string;
  displayName: string;
  description: string;
}

interface RulesetModalProps {
  open: boolean;
  onClose: () => void;
  ruleset: RulesetDto;
  allRisks?: RiskDto[] | null;
  loadRisksFromRuleset: (ruleset: RulesetDto) => Promise<RiskDto[]>;
  onRulesetUpdate: (rulesetId: string, data: UpdateRulesetDto) => void;
  onRulesetFinalize: (rulesetId: string, data: UpdateRulesetDto) => void;
}

const UpdateRulesetModal: FunctionComponent<RulesetModalProps> = ({
  open,
  onClose,
  ruleset,
  onRulesetUpdate,
  onRulesetFinalize,
  loadRisksFromRuleset,
  allRisks,
  ...props
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateRulesetFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
    },
  });

  const confirm = useConfirm();

  const onFinalize = useCallback(
    async (data: FieldValues, riskIds?: string[]) => {
      if (allRisks) {
        const newRuleset: UpdateRulesetDto = {
          name: data.name,
          displayName: data.displayName,
          description: data.description,
          riskIds: riskIds,
          status: ContentTypeStatus.Final,
        };

        const { canFinalize, dialog } = confirmFinalizeRuleset(
          confirm,
          riskIds ? allRisks.filter((risk) => riskIds.some((rId) => rId === risk.id)) : [],
        );
        dialog
          .then(() => {
            if (canFinalize) {
              onRulesetFinalize(ruleset.id, newRuleset);
            }
          })
          .catch(() => {
            /* ... */
          });
      }
    },
    [confirm, onRulesetFinalize, ruleset, allRisks],
  );

  const onUpdate = useCallback(
    async (data: FieldValues, riskIds?: string[]) => {
      onRulesetUpdate(ruleset.id, {
        name: data.name ?? ruleset.name,
        displayName: data.displayName ?? ruleset.displayName,
        description: data.description,
        riskIds: riskIds,
      });
    },
    [onRulesetUpdate, ruleset.displayName, ruleset.id, ruleset.name],
  );

  useEffect(() => {
    setValue('name', ruleset.name);
    setValue('displayName', ruleset.displayName);
    setValue('description', ruleset.description);
  }, [ruleset.description, ruleset.displayName, ruleset.name, setValue]);

  return (
    <ContentTableModal<RulesetDto, UpdateRulesetFormData, RiskDto>
      type='Update'
      title='Update Ruleset'
      open={open}
      onClose={onClose}
      update={{
        content: ruleset,
        onUpdate,
        onFinalize,
      }}
      form={{
        register,
        setValue,
        watch,
        handleSubmit,
        control,
        reset,
        formState: { errors },
      }}
      contentChildren={{
        title: 'Risks',
        loadChildren: loadRisksFromRuleset,
        allChildren: allRisks,
        addFieldLabel: 'Add Risks to Ruleset',
      }}
    ></ContentTableModal>
  );
};

export default UpdateRulesetModal;
