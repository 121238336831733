import { TableOrder, sortTableData } from '../../components/content-table-head';
import { TableHeadCell } from '../../components/content-table-head/content-table-header-filter';
import { TableBodyCellMapper, TableBodyMapper } from '../../components/content-table/content-table-body-cell';
import { ContentAuthorDto } from '../../services/dto/common/author.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';

export interface TrashContentDto {
  id: string;
  name: string;
  displayName: string;
  status: ContentTypeStatus;
  latestUpdateAt: Date;
  trashedAt: Date;
  description: string;
  author: ContentAuthorDto;
  type: 'Ruleset' | 'Risk' | 'Function';
  rulesetsCount?: number;
  risksCount?: number;
}
export type TrashOrder = TableOrder;
export type TrashContentOrderBy = 'name' | 'displayName' | 'type' | 'trashedAt' | 'author';

export function sortTrash(
  order: TrashOrder,
  orderBy: TrashContentOrderBy,
  content: TrashContentDto[],
  rowMapper: TableBodyMapper<TrashContentDto, TrashContentOrderBy>,
) {
  return sortTableData(order, orderBy, content, rowMapper);
}

export const TrashContentTableExtraCells = {
  type: {
    value: (content: TrashContentDto) => content.type,
    renderValue: (content: TrashContentDto) => content.type,
  } as TableBodyCellMapper<TrashContentDto>,
};
export const TrashContentTableExtraHeads: TableHeadCell<TrashContentOrderBy>[] = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
];
