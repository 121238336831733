import { Chip, Grid, Stack, Typography } from '@mui/material';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { xcpColors } from 'src/styles/main-theme';

import { BusinessProcessesFunctionsReportDto } from '@/services/dto/reports/business-processes-functions-report.dto';
import { ContentCountReportDto } from '@/services/dto/reports/content-types-count-report.dto';
import { RiskOwnersRisksReportDto } from '@/services/dto/reports/risk-owners-risks-report.dto';
import { RisksRiskLevelsReportDto } from '@/services/dto/reports/risks-risk-levels-report.dto';
import { RisksRiskTypesReportDto } from '@/services/dto/reports/risks-risk-types-report.dto';
import { RulesetsRisksReportDto } from '@/services/dto/reports/rulesets-risks-report.dto';

import { useSelectorUserData } from '../../redux/selector';
import { BusinessProcessesRisksReportDto } from '../../services/dto/reports/business-processes-risks-report.dto';
import { LastChangedReportDto } from '../../services/dto/reports/last-changed-content-report.dto';
import { RiskLevelDto } from '../../services/dto/risk-levels/risk-level.dto';
import { useReportService, useRiskLevelService } from '../../services/hooks';
import { RiskType } from '../../services/models/risk-type';
import {
  DASHBOARD_SEARCHPARAM_KEY_BPID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE,
} from '../../shared/constatns';
import CountBox from './elements/CountBox';
import LastChangedList from './elements/LastChangedList';
import ProcessRiskCountChartBox from './elements/ProcessRiskCountChartBox';
import ProcessRiskFunctionsCountChartBox from './elements/ProcessRisksFunctionsCountBox';
import RiskLevelChartBox from './elements/RiskLevelChartBox';
import RiskOwnerRiskCountList from './elements/RiskOwnerRiskCountList';
import RiskTypeChartBox from './elements/RiskTypeChartBox';
import RulesetRiskCountChartBox from './elements/RulesetRiskCountChartBox';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DashboardProps {}

ChartJS.register(...registerables, ChartDataLabels);

const DashboardPage = (props: DashboardProps) => {
  const { userData } = useSelectorUserData();
  const navigate = useNavigate();

  const reportsService = useReportService();
  const riskLevelsService = useRiskLevelService();

  const [businessProcessesRisksReportData, setBusinessProcessesRisksReportData] = useState<
    BusinessProcessesRisksReportDto[] | undefined
  >(undefined);
  const [businessProcessesFunctionsReportData, setBusinessProcessesFunctionsReportData] = useState<
    BusinessProcessesFunctionsReportDto[] | undefined
  >(undefined);
  const [contentCountReportData, setContentCountReportData] = useState<ContentCountReportDto | undefined>(undefined);
  const [lastChangedReportData, setLastChangedReportData] = useState<LastChangedReportDto[] | undefined>(undefined);
  const [riskOwnersRisksReportData, setRiskOwnersRisksReportData] = useState<RiskOwnersRisksReportDto[] | undefined>(
    undefined,
  );
  const [risksRiskLevelsReportData, setRisksRiskLevelsReportData] = useState<RisksRiskLevelsReportDto | undefined>(
    undefined,
  );
  const [risksRiskTypesReportData, setRisksRiskTypesReportData] = useState<RisksRiskTypesReportDto | undefined>(
    undefined,
  );
  const [rulesetsRisksReportData, setRulesetsRisksReportData] = useState<RulesetsRisksReportDto[] | undefined>(
    undefined,
  );
  const [riskLevels, setRiskLevels] = useState<RiskLevelDto[] | undefined>(undefined);

  const [rulesetFilter, setRulesetFilter] = useState<RulesetsRisksReportDto | undefined>(undefined);
  const [businessProcessFilter, setBusinessProcessFilter] = useState<BusinessProcessesRisksReportDto | undefined>(
    undefined,
  );
  const [riskTypeFilter, setRiskTypeFilter] = useState<RiskType | undefined>(undefined);
  const [riskLevelFilter, setRiskLevelFilter] = useState<RiskLevelDto | undefined>(undefined);

  const fetchRiskLevels = useCallback(async () => {
    const riskLevelsResult = await riskLevelsService.getRisklevels();
    setRiskLevels(riskLevelsResult.data);
  }, [riskLevelsService]);

  const fetchChartsData = useCallback(async () => {
    const risksForBp = await reportsService.getBusinessProcessesRisksReport({
      rulesetId: rulesetFilter?.rulesetId ?? null,
      riskType: riskTypeFilter ?? null,
      criticality: riskLevelFilter?.criticality ?? null,
    });
    const functionsForBp = await reportsService.getBusinessProcessesFunctionsReport({
      rulesetId: rulesetFilter?.rulesetId ?? null,
    });
    const risksForRiskLevel = await reportsService.getRisksRiskLevelsReport({
      rulesetId: rulesetFilter?.rulesetId ?? null,
      businessProcessId: businessProcessFilter?.businessProcessId ?? null,
      riskType: riskTypeFilter ?? null,
    });
    const risksForRiskType = await reportsService.getRisksRiskTypesReport({
      rulesetId: rulesetFilter?.rulesetId ?? null,
      businessProcessId: businessProcessFilter?.businessProcessId ?? null,
      criticality: riskLevelFilter?.criticality ?? null,
    });
    const risksForRiskOwner = await reportsService.getRiskOwnersRisksReport(
      {
        rulesetId: rulesetFilter?.rulesetId ?? null,
        businessProcessId: businessProcessFilter?.businessProcessId ?? null,
        riskType: riskTypeFilter ?? null,
        criticality: riskLevelFilter?.criticality ?? null,
      },
      5,
    );
    const risksForRulesets = await reportsService.getRulesetsRisksReport({
      businessProcessId: businessProcessFilter?.businessProcessId ?? null,
      riskType: riskTypeFilter ?? null,
      criticality: riskLevelFilter?.criticality ?? null,
    });
    const lastFiveChanged = await reportsService.getLastFiveChangedContentReport({
      rulesetId: rulesetFilter?.rulesetId ?? null,
      businessProcessId: businessProcessFilter?.businessProcessId ?? null,
      riskType: riskTypeFilter ?? null,
      criticality: riskLevelFilter?.criticality ?? null,
    });
    const contentMetrics = await reportsService.getContentTypesCountReport({
      rulesetId: rulesetFilter?.rulesetId ?? null,
      businessProcessId: businessProcessFilter?.businessProcessId ?? null,
      riskType: riskTypeFilter ?? null,
      criticality: riskLevelFilter?.criticality ?? null,
    });

    setBusinessProcessesRisksReportData(risksForBp.data);
    setBusinessProcessesFunctionsReportData(functionsForBp.data);
    setRisksRiskLevelsReportData(risksForRiskLevel.data);
    setRisksRiskTypesReportData(risksForRiskType.data);
    //@NOTE(workaround): backend does not limit the returned amount of risk owners to 5, so slicing is needed here atm
    setRiskOwnersRisksReportData(risksForRiskOwner.data.slice(0, 5));
    setRulesetsRisksReportData(risksForRulesets.data);
    setLastChangedReportData(lastFiveChanged.data);
    setContentCountReportData(contentMetrics.data);
  }, [
    businessProcessFilter?.businessProcessId,
    reportsService,
    riskLevelFilter?.criticality,
    riskTypeFilter,
    rulesetFilter?.rulesetId,
  ]);

  const onRulesetFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setBusinessProcessesFunctionsReportData(undefined);
    setRisksRiskTypesReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onBusinessProcessFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setRisksRiskTypesReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onRiskTypeFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onRiskLevelFilterChange = useCallback(() => {
    setRisksRiskTypesReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onRulesetFilterDelete = useCallback(() => {
    onRulesetFilterChange();
    setRulesetFilter(undefined);
  }, [onRulesetFilterChange]);

  const onBusinessProcessFilterDelete = useCallback(() => {
    onBusinessProcessFilterChange();
    setBusinessProcessFilter(undefined);
  }, [onBusinessProcessFilterChange]);

  const onRiskTypeFilterDelete = useCallback(() => {
    onRiskTypeFilterChange();
    setRiskTypeFilter(undefined);
  }, [onRiskTypeFilterChange]);

  const onRiskLevelFilterDelete = useCallback(() => {
    onRiskLevelFilterChange();
    setRiskLevelFilter(undefined);
  }, [onRiskLevelFilterChange]);

  const onRulesetFilter = useCallback(
    (rulesetRisksReport: RulesetsRisksReportDto) => {
      if (rulesetFilter?.rulesetId !== rulesetRisksReport.rulesetId) {
        onRulesetFilterChange();
        setRulesetFilter(rulesetRisksReport);
      }
    },
    [rulesetFilter, onRulesetFilterChange],
  );

  const onBusinessProcessesFilter = useCallback(
    (bpRisksReport: BusinessProcessesRisksReportDto) => {
      if (businessProcessFilter?.businessProcessId !== bpRisksReport.businessProcessId) {
        onBusinessProcessFilterChange();
        setBusinessProcessFilter(bpRisksReport);
      }
    },
    [businessProcessFilter, onBusinessProcessFilterChange],
  );

  const onRiskTypeFilter = useCallback(
    (riskType: RiskType) => {
      if (riskTypeFilter !== riskType) {
        onRiskTypeFilterChange();
        setRiskTypeFilter(riskType);
      }
    },
    [riskTypeFilter, onRiskTypeFilterChange],
  );

  const onRiskLevelFilter = useCallback(
    (riskLevel: RiskLevelDto) => {
      if (riskLevelFilter?.id !== riskLevel.id) {
        onRiskLevelFilterChange();
        setRiskLevelFilter(riskLevel);
      }
    },
    [riskLevelFilter, onRiskLevelFilterChange],
  );

  const filterSearchParams = useMemo(() => {
    const searchParams: URLSearchParams = new URLSearchParams();
    if (businessProcessFilter) {
      searchParams.append(DASHBOARD_SEARCHPARAM_KEY_BPID, businessProcessFilter.businessProcessId);
    }
    if (riskLevelFilter) {
      searchParams.append(DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID, riskLevelFilter.id);
    }
    if (riskTypeFilter) {
      searchParams.append(DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE, riskTypeFilter);
    }
    return searchParams;
  }, [businessProcessFilter, riskLevelFilter, riskTypeFilter]);
  const onNavigateToRuleset = useCallback(
    (rulesetId: string) => {
      navigate({
        pathname: `/rulesets/${rulesetId}`,
        search: createSearchParams(filterSearchParams).toString(),
      });
    },
    [filterSearchParams, navigate],
  );
  const onNavigateWithFilters = useCallback(
    (extraParam?: { key: string; value: string }) => {
      const filterSearchParamsWithExtraParam: URLSearchParams = new URLSearchParams();
      filterSearchParams.forEach((value, key) => {
        filterSearchParamsWithExtraParam.append(key, value);
      });
      if (extraParam) {
        filterSearchParamsWithExtraParam.append(extraParam.key, extraParam.value);
      }
      if (rulesetFilter) {
        navigate({
          pathname: `/rulesets/${rulesetFilter.rulesetId}`,
          search: createSearchParams(filterSearchParamsWithExtraParam).toString(),
        });
        return;
      }

      navigate({
        pathname: `/risks`,
        search: createSearchParams(filterSearchParamsWithExtraParam).toString(),
      });
    },
    [rulesetFilter, navigate, filterSearchParams],
  );

  const clearAllFilters = useCallback(() => {
    setRisksRiskTypesReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);

    setRulesetFilter(undefined);
    setBusinessProcessFilter(undefined);
    setRiskLevelFilter(undefined);
    setRiskTypeFilter(undefined);
  }, []);

  const filterChips = useMemo(() => {
    const chips: {
      key: string;
      label: string;
      onDelete: () => void;
    }[] = [];
    if (rulesetFilter) {
      chips.push({
        key: 'ruleset',
        label: `Ruleset: ${rulesetFilter.rulesetDisplayName}`,
        onDelete: onRulesetFilterDelete,
      });
    }
    if (businessProcessFilter) {
      chips.push({
        key: 'businessProcess',
        label: `Business Process: ${businessProcessFilter.businessProcessDisplayName}`,
        onDelete: onBusinessProcessFilterDelete,
      });
    }
    if (riskLevelFilter) {
      chips.push({
        key: 'riskLevel',
        label: `Risk Level: ${riskLevelFilter.name}`,
        onDelete: onRiskLevelFilterDelete,
      });
    }
    if (riskTypeFilter) {
      chips.push({
        key: 'riskType',
        label: `Risk Type: ${riskTypeFilter}`,
        onDelete: onRiskTypeFilterDelete,
      });
    }

    return chips;
  }, [
    businessProcessFilter,
    onBusinessProcessFilterDelete,
    onRiskLevelFilterDelete,
    onRiskTypeFilterDelete,
    onRulesetFilterDelete,
    riskLevelFilter,
    riskTypeFilter,
    rulesetFilter,
  ]);

  const bubbleChartCanvasHeight = 350;
  const bubbleChartCanvasWidth = 350;
  const chartBoxHeight = 310;
  const listBoxHeight = 300;

  useEffect(() => {
    fetchRiskLevels();
    fetchChartsData();
  }, [fetchChartsData, fetchRiskLevels]);

  return (
    <Stack sx={{ p: 2 }}>
      {/* Header */}
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Grid item xs={12} md={8}>
          <Typography
            sx={{ color: xcpColors.grey[0], flex: '1 1 100%' }}
            variant='h5'
            id='welcomeTitle'
            component='div'
          >
            Welcome to your dashboard!
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* Counts */}
          <Stack spacing={4} direction='row' sx={{ justifyContent: 'flex-end', mr: 4 }}>
            <CountBox subTitle='Rulesets' count={contentCountReportData?.rulesets} />
            <CountBox subTitle='Risks' count={contentCountReportData?.risks} />
            <CountBox subTitle='Functions' count={contentCountReportData?.functions} />
          </Stack>
        </Grid>
      </Grid>

      {/* Body */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {/* Filter */}
          <Stack spacing={2} direction={'row'} sx={{ minHeight: 35, py: 0 }}>
            {filterChips.map((chip) => (
              <Chip
                sx={{ backgroundColor: xcpColors.grey[3] }}
                key={chip.key}
                label={chip.label}
                onDelete={chip.onDelete}
              />
            ))}
            {filterChips.length > 0 && (
              <Chip
                label='Reset'
                variant='outlined'
                onClick={() => {
                  clearAllFilters();
                }}
              />
            )}
            {filterChips.length > 0 && (
              <Chip
                label='Go to Risks'
                variant='outlined'
                color='primary'
                onClick={() => {
                  onNavigateWithFilters();
                }}
              />
            )}
          </Stack>
        </Grid>
        {/* Charts */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <RulesetRiskCountChartBox
              width={bubbleChartCanvasWidth}
              chartHeight={bubbleChartCanvasHeight}
              boxHeight={chartBoxHeight}
              rulesetsRisksReportData={rulesetsRisksReportData}
              setRulesetFilter={onRulesetFilter}
              onDoubleClick={onNavigateToRuleset}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <ProcessRiskCountChartBox
              height={chartBoxHeight}
              businessProcessesRisksReportData={businessProcessesRisksReportData}
              setBusinessProcessesIdFilter={onBusinessProcessesFilter}
              onDoubleClick={onNavigateWithFilters}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <RiskTypeChartBox
              height={chartBoxHeight}
              risksRiskTypesReportData={risksRiskTypesReportData}
              setRiskTypeFilter={onRiskTypeFilter}
              onDoubleClick={onNavigateWithFilters}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <RiskLevelChartBox
              height={chartBoxHeight}
              risksRiskLevelsReportData={risksRiskLevelsReportData}
              riskLevels={riskLevels}
              setRiskLevelFilter={onRiskLevelFilter}
              onDoubleClick={onNavigateWithFilters}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ProcessRiskFunctionsCountChartBox
              height={listBoxHeight}
              businessProcessesRisksReportData={businessProcessesRisksReportData}
              businessProcessesFunctionsReportData={businessProcessesFunctionsReportData}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <RiskOwnerRiskCountList height={listBoxHeight} riskOwnersRisksReportData={riskOwnersRisksReportData} />
          </Grid>
          <Grid item xs={12} md={3}>
            <LastChangedList height={listBoxHeight} lastChangedReportData={lastChangedReportData} />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DashboardPage;
