import { Autocomplete, Grid, InputLabel, MenuItem, Stack, TextField } from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { RiskFunctionDto } from '@/services/dto/functions/function.dto';

import { ContentTableModal } from '../../../components/content-table-modal';
import RiskLevelRating from '../../../components/risks/risk-level-rating';
import { BusinessProcessDto } from '../../../services/dto/business-process/business-process.dto';
import { RiskLevelDto } from '../../../services/dto/risk-levels/risk-level.dto';
import { CreateFromTemplateRiskDto } from '../../../services/dto/risks/create-from-existing-risk.dto';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { RiskType } from '../../../services/models/risk-type';

interface CreateRiskFromTemplateFormData {
  name: string;
  displayName: string;
  riskType: RiskType;
  riskOwner: string;
  criticality: number;
  businessProcessId: string;
  description: string;
  keepReference?: boolean;
}

interface RiskModalProps {
  open: boolean;
  onClose: () => void;
  allRisks?: RiskDto[];
  allFunctions: RiskFunctionDto[];
  riskLevels: RiskLevelDto[];
  allBusinessProcesses: BusinessProcessDto[];
  loadFunctionsFromRisk: (risk: RiskDto) => Promise<RiskFunctionDto[]>;
  onRiskCreateFromTemplate: (data: CreateFromTemplateRiskDto, template: RiskDto) => Promise<void>;
}

const CreateRiskFromTemplateModal: FunctionComponent<RiskModalProps> = ({
  open,
  onClose,
  onRiskCreateFromTemplate,
  allRisks,
  allFunctions,
  riskLevels,
  allBusinessProcesses,
  loadFunctionsFromRisk,
  ...props
}) => {
  const [disabled, setDisabled] = useState(true);
  const [selectedBusinessProcess, setSelectedBusinessProcess] = useState<BusinessProcessDto | null>(null);
  const [newBusinessProcessName, setNewBusinessProcessName] = useState('');

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateRiskFromTemplateFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
      riskType: RiskType.CriticalAuthorization,
      criticality: 0,
      riskOwner: '',
      businessProcessId: '',
      keepReference: true,
    },
  });

  const onCreateFromTemplate = useCallback(
    (data: FieldValues, template: RiskDto, functionIds?: string[]) => {
      onRiskCreateFromTemplate(
        {
          name: data.name,
          displayName: data.displayName,
          description: data.description,
          riskType: data.riskType,
          criticality: data.criticality,
          riskOwner: data.riskOwner,
          businessProcessId: data.businessProcessId || null,
          riskFunctionIds: functionIds,
          keepReference: data.keepReference,
        },
        template,
      );
    },
    [onRiskCreateFromTemplate],
  );

  const onSelectNewTemplate = useCallback(
    (template: RiskDto) => {
      setValue('name', template.name);
      setValue('displayName', template.displayName);
      setValue('description', template.description);
      setValue('riskType', template.riskType);
      setValue('criticality', template.criticality);
      setValue('riskOwner', template.riskOwner);
      setValue('keepReference', true);
      setDisabled(false);

      setValue('businessProcessId', template.businessProcess?.id ?? '');
      setSelectedBusinessProcess(allBusinessProcesses.find((bp) => bp.id === template.businessProcess?.id) ?? null);
    },
    [allBusinessProcesses, setSelectedBusinessProcess, setValue],
  );

  const onAutocompleteChange = useCallback(
    (_: any, newSelectedBusinessProcess: BusinessProcessDto | null) => {
      setSelectedBusinessProcess(newSelectedBusinessProcess);
      setValue('businessProcessId', newSelectedBusinessProcess?.id ?? '');
    },
    [setSelectedBusinessProcess, setValue],
  );
  const onInputChange = useCallback((event: any, newBusinessProcessName: string) => {
    setNewBusinessProcessName(newBusinessProcessName);
  }, []);

  const registerOptions = {
    riskType: { required: true },
    criticality: { required: true },
    riskOwner: undefined,
    businessProcessId: undefined,
  };

  return (
    <ContentTableModal<RiskDto, CreateRiskFromTemplateFormData, RiskFunctionDto>
      title='Create Risk'
      type='CreateFromTemplate'
      disabled={disabled}
      createFromTemplate={{
        onCreateFromTemplate,
        templates: allRisks,
        onSelectNewTemplate,
      }}
      open={open}
      onClose={onClose}
      form={{
        register,
        setValue,
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors },
      }}
      contentChildren={{
        title: 'Functions',
        loadChildren: loadFunctionsFromRisk,
        allChildren: allFunctions,
        addFieldLabel: 'Add Functions to Risk',
      }}
    >
      <Grid item sm={4}>
        <Controller
          name='riskType'
          control={control}
          rules={registerOptions.riskType}
          render={({ field }) => (
            <TextField
              key={'riskType'}
              margin='dense'
              select
              label='Type'
              fullWidth
              error={!!errors.riskType}
              helperText={errors.riskType?.message?.toString()}
              {...field}
            >
              <MenuItem key={'CA'} value={'CA'}>
                CA
              </MenuItem>
              <MenuItem key={'SOD'} value={'SOD'}>
                SOD
              </MenuItem>
            </TextField>
          )}
        />
      </Grid>
      <Grid item sm={4}>
        <Controller
          name='riskOwner'
          control={control}
          rules={registerOptions.riskOwner}
          render={({ field }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              key={'riskOwner'}
              margin='dense'
              label='Risk Owner'
              fullWidth
              error={!!errors.criticality}
              helperText={errors.riskOwner?.message?.toString()}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item sm={4}>
        <Controller
          name='criticality'
          control={control}
          rules={registerOptions.criticality}
          render={({ field }) => (
            <Stack sx={{ display: 'flex' }}>
              <InputLabel shrink id='risk-level-label'>
                Risk Level
              </InputLabel>
              <RiskLevelRating
                sx={{ position: 'relative', bottom: '11px' }}
                centerText
                height={15}
                name='risk-level'
                riskLevels={riskLevels}
                value={field.value}
                onChange={(event, criticality) => {
                  setValue('criticality', criticality ?? 0);
                }}
              />
            </Stack>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='businessProcessId'
          control={control}
          rules={registerOptions.businessProcessId}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={selectedBusinessProcess}
              onChange={onAutocompleteChange}
              inputValue={newBusinessProcessName}
              onInputChange={onInputChange}
              options={allBusinessProcesses}
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
              renderInput={(params) => (
                <TextField {...params} margin='dense' label='Business Process' InputLabelProps={{ shrink: true }} />
              )}
            />
          )}
        />
      </Grid>
    </ContentTableModal>
  );
};

export default CreateRiskFromTemplateModal;
