import { faFileCircleXmark } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography, darken, lighten, useTheme } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Pie, getElementAtEvent } from 'react-chartjs-2';
import { xcpColors } from 'src/styles/main-theme';

import { RisksRiskLevelsReportDto } from '@/services/dto/reports/risks-risk-levels-report.dto';

import { RiskLevelDto } from '../../../services/dto/risk-levels/risk-level.dto';
import { DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID } from '../../../shared/constatns';
import { DASHBOARD_DOUBLE_CLICK_DEPLAY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskLevelChartBoxProps {
  risksRiskLevelsReportData?: RisksRiskLevelsReportDto;
  height?: string | number;
  riskLevels?: RiskLevelDto[];
  setRiskLevelFilter: (riskLevel: RiskLevelDto) => void;
  onDoubleClick: (extraParam?: { key: string; value: string }, riskLevel?: RiskLevelDto) => void;
}

const RiskLevelChartBox = ({
  risksRiskLevelsReportData,
  height,
  riskLevels,
  setRiskLevelFilter,
  onDoubleClick,
}: RiskLevelChartBoxProps) => {
  const theme = useTheme();
  const risksForRiskLevelChartDatasetsLabels = useMemo(
    () => riskLevels?.map((riskLevel) => riskLevel.name),
    [riskLevels],
  );
  const risksForRiskLevelChartLabels = useMemo(() => ['Draft', 'Final', 'Released'], []);

  const risksForRiskLevelChartDatasets = useMemo(() => {
    return risksForRiskLevelChartDatasetsLabels?.map((riskLevelName, index) => {
      const riskLevel = riskLevels?.find((rl) => rl.name === riskLevelName);
      const color = (() => {
        if (riskLevel?.color) {
          return riskLevel.color;
        }

        // fallback
        return xcpColors.graphics.length > 0 ? xcpColors.graphics[index % xcpColors.graphics.length] : undefined;
      })();

      return {
        label: riskLevelName,
        data: [
          risksRiskLevelsReportData?.riskLevels.draft[riskLevelName] ?? 0,
          risksRiskLevelsReportData?.riskLevels.final[riskLevelName] ?? 0,
          risksRiskLevelsReportData?.riskLevels.released[riskLevelName] ?? 0,
        ],
        backgroundColor: [
          color ? darken(color, 0.1) : xcpColors.graphics[0],
          color ?? xcpColors.graphics[1],
          color ? lighten(color, 0.3) : xcpColors.graphics[2],
        ],
        borderColor: ['white'],
        borderWidth: 2,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                if (!value) {
                  return 'rgba(0, 0, 0, 0)';
                }
                return color ? theme.palette.getContrastText(color) : 'white';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      };
    });
  }, [
    riskLevels,
    risksForRiskLevelChartDatasetsLabels,
    risksRiskLevelsReportData?.riskLevels.draft,
    risksRiskLevelsReportData?.riskLevels.final,
    risksRiskLevelsReportData?.riskLevels.released,
    theme.palette,
  ]);
  const risksForRiskLevelChartData = useMemo(() => {
    return {
      labels: risksForRiskLevelChartLabels,
      datasets: risksForRiskLevelChartDatasets,
    };
  }, [risksForRiskLevelChartDatasets, risksForRiskLevelChartLabels]);

  //riskLevel onClick event
  const risksRiskLevelCountChartRef = useRef<ChartJS | any>();
  const risksRiskLevelChartSingleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskLevelCountChartRef.current
        ? getElementAtEvent(risksRiskLevelCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        //const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        if (riskLevels?.[datasetIndex]) {
          setRiskLevelFilter(riskLevels[datasetIndex]);
        }
      }
    },
    [riskLevels, setRiskLevelFilter],
  );
  const risksRiskLevelChartDoubleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskLevelCountChartRef.current
        ? getElementAtEvent(risksRiskLevelCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        //const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        if (riskLevels?.[datasetIndex]) {
          onDoubleClick(
            { key: DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID, value: riskLevels[datasetIndex].id },
            riskLevels[datasetIndex],
          );
        }
      }
    },
    [onDoubleClick, riskLevels],
  );

  const [clickCounter, setClickCounter] = useState<{
    event: React.MouseEventHandler<HTMLCanvasElement> | any | undefined;
    counter: number;
  }>({ event: undefined, counter: 0 });
  const risksRiskLevelChartOnClick = useCallback((event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
    setClickCounter((c) => {
      // inc. click counter and pass event
      return { event, counter: c.counter + 1 };
    });
  }, []);

  useEffect(() => {
    // has click event
    if (clickCounter.event && clickCounter.counter > 0) {
      const timer = setTimeout(() => {
        if (clickCounter.counter === 1) risksRiskLevelChartSingleClick(clickCounter.event);
        else if (clickCounter.counter === 2) risksRiskLevelChartDoubleClick(clickCounter.event);
        setClickCounter({ event: undefined, counter: 0 });
      }, DASHBOARD_DOUBLE_CLICK_DEPLAY);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const options = useMemo(() => {
    return {
      responsive: true,
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            boxWidth: 20,
            generateLabels: (/*chart: any*/) => {
              return risksForRiskLevelChartDatasetsLabels?.map((riskLevelName, index) => {
                const sum = (() => {
                  const drafts = risksRiskLevelsReportData?.riskLevels.draft[riskLevelName] ?? 0;
                  const final = risksRiskLevelsReportData?.riskLevels.final[riskLevelName] ?? 0;
                  const released = risksRiskLevelsReportData?.riskLevels.released[riskLevelName] ?? 0;

                  return drafts + final + released;
                })();

                const riskLevel = riskLevels?.find((rl) => rl.name === riskLevelName);
                const color = (() => {
                  if (riskLevel?.color) {
                    return riskLevel.color;
                  }

                  // fallback
                  return xcpColors.graphics.length > 0
                    ? xcpColors.graphics[index % xcpColors.graphics.length]
                    : undefined;
                })();

                return {
                  datasetIndex: 0,
                  fillStyle: color,
                  fontColor: theme.palette.text.primary,
                  hidden: false,
                  index: 0,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: `${sum} ${riskLevelName}`,
                };
              });
            },
          },
          position: 'bottom',
          onClick: (mouseEvent: any, legendItem: any) => {
            if (riskLevels?.[legendItem.index]) {
              setRiskLevelFilter(riskLevels[legendItem.index]);
            }
          },
        },
      },
    };
  }, [
    risksForRiskLevelChartDatasetsLabels,
    risksRiskLevelsReportData,
    theme.palette.text.primary,
    setRiskLevelFilter,
    riskLevels,
  ]);

  const loadingState = useMemo(() => {
    if (risksRiskLevelsReportData === undefined || riskLevels === undefined) {
      return 'loading';
    }

    let countRiskLevels = 0;
    Object.entries(risksRiskLevelsReportData.riskLevels).forEach(([key, value]) => {
      riskLevels.forEach((rl) => {
        countRiskLevels += value[rl.name] ?? 0;
      });
    });

    if (countRiskLevels > 0 && riskLevels.length > 0) {
      return 'loaded';
    }

    return 'empty';
  }, [riskLevels, risksRiskLevelsReportData]);

  return (
    <Stack>
      <Typography sx={{ color: xcpColors.grey[1], flex: '1 1 100%', my: 1, ml: 2 }} variant='h6' component='div'>
        Risk Levels
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box sx={{ my: 2, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loadingState === 'loading' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {loadingState === 'empty' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography align='center'>No Data</Typography>
                <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
              </Stack>
            </Box>
          )}
          {risksRiskLevelsReportData && loadingState === 'loaded' && (
            <Pie
              data={risksForRiskLevelChartData as any}
              options={options as any} ///< @NOTE: use any for plugin options
              ref={risksRiskLevelCountChartRef}
              onClick={risksRiskLevelChartOnClick}
            />
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default RiskLevelChartBox;
