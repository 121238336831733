import { Paper, Stack } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { CreateFromTemplateRiskDto } from '@/services/dto/risks/create-from-existing-risk.dto';

import { DefaultChildrenBaseContentOrderBy } from '../../components/children-table/children-table-body-cell';
import { ContentTable } from '../../components/content-table';
import RestoreModal from '../../components/restore-modal';
import { confirmTrashRisks } from '../../components/risks/delete-dialogs';
import {
  RiskContentTableExtraHeads,
  RisksOrderBy,
  sortRisks,
  useRiskContentTableExtraCells,
} from '../../components/risks/risk-table-head';
import TrashModal from '../../components/trash-modal';
import { BusinessProcessDto } from '../../services/dto/business-process/business-process.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskLevelDto } from '../../services/dto/risk-levels/risk-level.dto';
import { CreateRiskDto } from '../../services/dto/risks/create-risk.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { UpdateRiskDto } from '../../services/dto/risks/update-risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import {
  useBusinessProcessService,
  useRiskFunctionService,
  useRiskLevelService,
  useRiskService,
} from '../../services/hooks';
import { afterRestoreContent, afterTrashContent } from '../../shared/common';
import {
  DASHBOARD_SEARCHPARAM_KEY_BPID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE,
} from '../../shared/constatns';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';
import CreateRiskModal from './risk-modal/create';
import CreateRiskFromTemplateModal from './risk-modal/create-from-template';
import CreateRiskNewVersionModal from './risk-modal/create-new-version';
import UpdateRiskModal from './risk-modal/update';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RisksPageProps {}

const RisksPage: FunctionComponent<RisksPageProps> = ({ ...props }) => {
  const confirm = useConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [risks, setRisks] = useState<RiskDto[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[]>([]);
  const [riskLevels, setRiskLevels] = useState<RiskLevelDto[]>([]);
  const [allFunctions, setAllFunctions] = useState<RiskFunctionDto[]>([]);

  const [modalRisk, setModalRisk] = useState<RiskDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'createFromTemplate' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined
  >(undefined);

  const riskService = useRiskService();
  const businessProcessService = useBusinessProcessService();
  const riskLevelService = useRiskLevelService();
  const riskFunctionService = useRiskFunctionService();

  const getRisk = useCallback(
    async (id: string) => {
      const result = await riskService.getRisk(id);
      return result.data;
    },
    [riskService],
  );

  const fetchRisks = useCallback(async () => {
    const risksResult = await riskService.getRisks();
    setRisks(risksResult.data);
    setTotalCount(risksResult.meta?.totalCount ?? risksResult.data.length);
    return risksResult.data;
  }, [riskService]);

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcesses = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcesses.data);
  }, [businessProcessService]);

  const fetchRiskLevels = useCallback(async () => {
    const riskLevelsResult = await riskLevelService.getRisklevels();
    setRiskLevels(riskLevelsResult.data);
  }, [riskLevelService]);

  const fetchRiskFunctions = useCallback(async () => {
    const riskFunctions = await riskFunctionService.getFunctions();
    setAllFunctions(riskFunctions.data);
  }, [riskFunctionService]);

  const initRisksFilters = useMemo(() => {
    return {
      businessProcessId: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_BPID),
      riskLevelId: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL_ID),
      riskType: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE),
    };
  }, [searchParams]);

  const getRiskUrl = useCallback((risk: RiskDto) => {
    return `/risks/${risk.id}`;
  }, []);

  const contentTableInitFilters = useMemo(() => {
    const ret = new Map<RisksOrderBy, string | null | undefined>();
    ret.set('businessProcess', allBusinessProcesses.find((bp) => bp.id === initRisksFilters.businessProcessId)?.name);
    ret.set('riskLevel', riskLevels.find((riskLevel) => riskLevel.id === initRisksFilters.riskLevelId)?.name);
    ret.set('riskType', initRisksFilters.riskType);
    return ret;
  }, [
    allBusinessProcesses,
    riskLevels,
    initRisksFilters.riskType,
    initRisksFilters.businessProcessId,
    initRisksFilters.riskLevelId,
  ]);

  const openCreateRisk = useCallback(() => {
    setModalRisk(undefined);
    setCurrentOpenModal('create');
  }, []);

  const openEditRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const openCreateFromTemplateRisk = useCallback(() => {
    setModalRisk(undefined);
    setCurrentOpenModal('createFromTemplate');
  }, []);

  const openTrashRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('trash');
  }, []);

  const openRestoreRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('restore');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRisk(undefined);
  }, []);

  const onRiskCreated = useCallback((newRisk: RiskDto) => {
    setRisks((risks) => [newRisk, ...(risks ?? [])]);
    setTotalCount((oldTotalCount: number) => oldTotalCount + 1);
  }, []);

  const trashRisk = useCallback(
    async (id: string) => {
      await riskService.trashRisk(id);
      afterTrashContent(id, getRisk, setRisks);
    },
    [getRisk, riskService],
  );

  const trashRisks = useCallback(
    async (riskIds: string[]) => {
      await riskService.trashRisks(riskIds);
      fetchRisks();
      // @NOTE(workaround): fetch all, don't know which got deleted (removed) or updated (status)
      /*
      setRisks((risks) => {
        if (risks) {
          return risks.filter((risk: RiskDto) => !riskIds.some((rId) => rId == risk.id));
        }
        return null;
      });
      */
    },
    [fetchRisks, riskService],
  );

  const restoreRisk = useCallback(
    async (id: string) => {
      await riskService.restoreRisk(id);
      afterRestoreContent(id, getRisk, setRisks);
    },
    [getRisk, riskService],
  );

  const onRiskTrash = useCallback(
    (id: string) => {
      trashRisk(id).then(() => {
        closeModal();
      });
    },
    [closeModal, trashRisk],
  );

  const onRiskRestore = useCallback(
    (id: string) => {
      restoreRisk(id).then(() => {
        closeModal();
      });
    },
    [closeModal, restoreRisk],
  );

  const getRiskFunctionsFromRisk = useCallback(
    async (risk: RiskDto) => {
      const riskFunctions = await riskService.getFunctionsFromRisk(risk.id);
      return riskFunctions.data;
    },
    [riskService],
  );

  const getRulesetsFromRisk = useCallback(
    async (id: string) => {
      const result = await riskService.getRulesetsFromRisk(id);
      return result.data;
    },
    [riskService],
  );

  const onMassTrash = useCallback(
    async (riskIds: string[]) => {
      const { dialog } = await confirmTrashRisks(confirm, riskIds);
      return dialog
        .then(() => {
          trashRisks(riskIds);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [confirm, trashRisks],
  );

  const onRiskCreate = useCallback(
    async (data: CreateRiskDto, functionIds?: string[]) => {
      const res = await riskService.createRisk({
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        riskType: data.riskType,
        criticality: data.criticality,
        riskOwner: data.riskOwner,
        businessProcessId: data.businessProcessId ?? null,
        riskFunctionIds: functionIds,
      });
      onRiskCreated(res.data);

      closeModal();
    },
    [closeModal, onRiskCreated, riskService],
  );
  const onRiskCreateFromTemplate = useCallback(
    async (data: CreateFromTemplateRiskDto, template: RiskDto) => {
      const res = await riskService.createFromExistingRisk(template.id, data);

      onRiskCreated(res.data);

      closeModal();
    },
    [closeModal, onRiskCreated, riskService],
  );

  const onCreateNewRiskVersion = useCallback(
    async (id: string, createNewVersionData: FieldValues, origin: RiskDto, riskFuntionIds?: string[]) => {
      const res = await riskService.createNewRiskVersion(id, {
        displayName: createNewVersionData.displayName,
        description: createNewVersionData.description,
        riskType: createNewVersionData.riskType,
        criticality: createNewVersionData.criticality,
        riskOwner: createNewVersionData.riskOwner,
        riskFunctionIds: riskFuntionIds,
      });
      setRisks((risks) => [res.data, ...(risks ?? [])].filter((risk) => risk.id != origin.id));
      closeModal();
    },
    [closeModal, riskService],
  );

  const onRiskUpdate = useCallback(
    async (id: string, data: UpdateRiskDto) => {
      const res = await riskService.updateRisk(id, data);
      setRisks((risks) => {
        if (risks) {
          return risks.map((risk: RiskDto) => {
            if (risk.id === res.data.id) {
              return { ...risk, ...res.data };
            }
            return risk;
          });
        }
        return null;
      });
      closeModal();
    },
    [closeModal, riskService],
  );

  const onRiskFinalize = useCallback(
    async (riskId: string, data: UpdateRiskDto) => {
      try {
        const res = await riskService.updateRisk(riskId, data);
        setRisks((risks) => {
          if (risks) {
            return risks.map((risk: RiskDto) => {
              if (risk.id === res.data.id) {
                return { ...risk, ...res.data };
              }
              return risk;
            });
          }
          return null;
        });
        closeModal();
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [riskService, closeModal],
  );

  const onRiskRelease = useCallback(
    async (riskId: string, data: UpdateRiskDto) => {
      try {
        const res = await riskService.updateRisk(riskId, data);
        setRisks((risks) => {
          if (risks) {
            return risks.map((risk: RiskDto) => {
              if (risk.id === res.data.id) {
                return { ...risk, ...res.data };
              }
              return risk;
            });
          }
          return null;
        });
        closeModal();
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [riskService, closeModal],
  );

  const RiskContentTableExtraCells = useRiskContentTableExtraCells(riskLevels);

  useEffect(() => {
    fetchBusinessProcesses();
    fetchRiskLevels();
    fetchRisks();
    fetchRiskFunctions();
  }, [fetchBusinessProcesses, fetchRiskFunctions, fetchRiskLevels, fetchRisks]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <Stack spacing={ContentPageStackSpacing}>
          <ContentTable<RiskDto, RisksOrderBy, RiskFunctionDto, DefaultChildrenBaseContentOrderBy>
            tableToolbarTitle='Risks'
            totalCount={totalCount}
            rows={risks}
            sortTable={sortRisks}
            openCreate={openCreateRisk}
            createButtonTitle='Create Risk'
            createFromTemplateButtonTitle='Create Risk From Template'
            autocompleteButtonTitle='Add existing Risk'
            extraCells={RiskContentTableExtraCells}
            extraHeads={RiskContentTableExtraHeads}
            openCreateNewVersion={openCreateNewVersionRisk}
            openCreateFromTemplate={openCreateFromTemplateRisk}
            openEdit={openEditRisk}
            onTrash={openTrashRisk}
            onRestore={openRestoreRisk}
            getDetailsUrl={getRiskUrl}
            loadChildren={getRiskFunctionsFromRisk}
            massTrash={{
              onMassTrash: onMassTrash,
              tooltipTitle: 'Trash selected',
              iconType: 'delete',
            }}
            initFilters={contentTableInitFilters}
          />
          <CreateRiskModal
            open={currentOpenModal === 'create'}
            onClose={closeModal}
            onRiskCreate={onRiskCreate}
            allFunctions={allFunctions}
            riskLevels={riskLevels}
            allBusinessProcesses={allBusinessProcesses}
          />
          <CreateRiskFromTemplateModal
            open={currentOpenModal === 'createFromTemplate'}
            onClose={closeModal}
            onRiskCreateFromTemplate={onRiskCreateFromTemplate}
            loadFunctionsFromRisk={getRiskFunctionsFromRisk}
            allRisks={risks ?? []}
            allFunctions={allFunctions}
            riskLevels={riskLevels}
            allBusinessProcesses={allBusinessProcesses}
          />
          {modalRisk && (
            <UpdateRiskModal
              open={currentOpenModal === 'update'}
              onClose={closeModal}
              onRiskUpdate={onRiskUpdate}
              risk={modalRisk}
              loadFunctionsFromRisk={getRiskFunctionsFromRisk}
              onRiskFinalize={onRiskFinalize}
              allFunctions={allFunctions}
              riskLevels={riskLevels}
              allBusinessProcesses={allBusinessProcesses}
            />
          )}
          {modalRisk && (
            <CreateRiskNewVersionModal
              open={currentOpenModal === 'createNewVersion'}
              onClose={closeModal}
              risk={modalRisk}
              onRiskCreateNewVersion={onCreateNewRiskVersion}
              loadFunctionsFromRisk={getRiskFunctionsFromRisk}
              allFunctions={allFunctions}
              riskLevels={riskLevels}
              allBusinessProcesses={allBusinessProcesses}
              onRiskRelease={onRiskRelease}
            />
          )}
          {modalRisk && (
            <TrashModal<RiskDto, RulesetDto>
              title='Trash Risk'
              contentName='Risk'
              parentsName='Rulesets'
              contentId={modalRisk.id}
              open={currentOpenModal === 'trash'}
              onClose={closeModal}
              onTrash={onRiskTrash}
              getContent={getRisk}
              getParents={getRulesetsFromRisk}
            />
          )}
          {modalRisk && (
            <RestoreModal<RiskDto, RulesetDto>
              title='Trash Risk'
              contentName='Risk'
              parentsName='Rulesets'
              contentId={modalRisk.id}
              open={currentOpenModal === 'restore'}
              onClose={closeModal}
              onRestore={onRiskRestore}
              getContent={getRisk}
            />
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default RisksPage;
