import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box, Link, Paper, Stack } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import image1 from '../../assets/img/guidance/Regelwerk-01.png';
import image2 from '../../assets/img/guidance/Regelwerk-02.png';
import image3 from '../../assets/img/guidance/Regelwerk-03.png';
import image4 from '../../assets/img/guidance/Regelwerk-04.png';
import image5 from '../../assets/img/guidance/Regelwerk-05.png';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GuidanceProps {}

const GuidancePage = (props: GuidanceProps) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const AccordionImageWidth = 350;
  const AccordionImageHeight = 233;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <Typography sx={{ ml: 2, mt: 2 }} variant={'h6'} id='guidancePageTitle' component='div'>
          Ruleset Design
        </Typography>
        <Typography variant={'body1'} sx={{ mt: 3, mx: 4 }}>
          A ruleset defines access risks related to critical access or segregation of duties (SOD) conflicts. Rule
          building may become a complex exercise the more business processes are in scope while rules need to be built
          in a way that a risk analysis can be performed yet in a decent manner of time. A risk analysis is performed
          not only during the user provisioning process for a little number of roles or users but also in a recurring
          fashion (e.g. as required by internal controls of an organization). Given the complexity rises significantly
          when doing a risk analysis across a large set of users with their role and profile assignments the ruleset
          design is key. Hence, this document shall assist in defining rules when using the XAMS Critical Authorization
          Framework (CRAF). In XAMS, a ruleset is depicted as an “Authorization and/or Combination Variant”, and an
          individual rule as an “Authorization ID and/or Combination ID”. XAMS How to build a ruleset that perfectly
          aligns with the XAMS
        </Typography>
        <Accordion sx={{ mt: 3 }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <Typography>1 Minimize the ruleset size</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={'row'}>
              <img width={AccordionImageWidth} height={AccordionImageHeight} src={image1} />
              <Stack sx={{ justifyContent: 'space-between' }}>
                <Typography variant={'body1'} sx={{ mt: 2, mx: 3 }}>
                  Rulesets may be used for different purposes. While one is created to determine access risks due to
                  internal controls related considerations another one is used to make sure IT access is generally not
                  granted to business users. For each purpose a separate ruleset may be created. The less rules the
                  better. The ruleset should be limited to the relevant business processes only (e.g. access risks with
                  immediate effect on the financial statements). There is no general rule how many rules may be
                  implemented.
                </Typography>
                <Link sx={{ ml: 3 }} href='/rulesets/'>
                  {' '}
                  {'=> Go to Rulesets'}
                </Link>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
            <Typography>2 Sharpen your rules to focus on business access</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={'row'}>
              <img width={AccordionImageWidth} height={AccordionImageHeight} src={image2} />
              <Stack sx={{ justifyContent: 'space-between' }}>
                <Typography variant={'body1'} sx={{ mt: 2, mx: 3 }}>
                  Besides the number of rules, it is highly recommended to keep the size of rules as lean as possible.
                  The more lines are added to a rule the longer the risk analysis job will run since the logic how rules
                  are read by XAMS CRAF is following the line-by-line approach. The execution time may quickly double
                  (or even more) if lines are added unnecessar- ily. In conclusion, it is recommended to include only
                  business access in your XAMS CRAF rules (e.g. authorization objects M_BEST_* for access to purchase
                  orders). Adding transactions, WebDynpros or Fiori apps (OData services) to your ruleset increases
                  complexity. Such type of access may be evaluated in a separate process in a later instance since it is
                  important for business departments to better understand the ac- cess risk, though. Besides, under
                  certain circumstances it is not ap- propriate to introduce a transactional layer in rules
                  (particularly for IT access due to its nature being a system-wide risk, such as Debugging).
                </Typography>
                <Link sx={{ ml: 3 }} href='/functions/'>
                  {' '}
                  {'=> Go to Functions'}
                </Link>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
            <Typography>3 Apply the “common denominator” in your rules</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={'row'}>
              <img width={AccordionImageWidth} height={AccordionImageHeight} src={image3} />
              <Stack sx={{ justifyContent: 'space-between' }}>
                <Typography variant={'body1'} sx={{ mt: 2, mx: 3 }}>
                  Omitting transactional contexts simplifies rules, focusing on standard business access required by
                  similar transactions or applications. When leaving out the transactional context your rules can be
                  kept simple using the “common denominator” of business access required by the same type of
                  transactions, WebDynpros or Fiori apps (OData services). For instance, leveraging the SAP Standard the
                  access controls will typically require at least the following authorizations in a user’s profile for
                  maintaining a purchase order:
                  <Stack direction={'row'}>
                    <br />{' '}
                    <Box sx={{ ml: 1 }}>
                      <Typography>
                        <br /> i.{' '}
                      </Typography>
                      <Typography>ii. </Typography>
                      <Typography>iii. </Typography>
                      <Typography>iv. </Typography>
                    </Box>
                    <Box sx={{ ml: 3 }}>
                      <Typography>
                        <br /> M_BEST_BSA Document Type in PO
                      </Typography>
                      <Typography>M_BEST_EKG Purchasing Group in PO</Typography>
                      <Typography>M_BEST_EKO Purchasing Organization in PO</Typography>
                      <Typography>M_BEST_WRK Plant in PO</Typography>
                    </Box>
                  </Stack>
                  <br />
                  Even though, other authorizations may be required for certain transactions to maintain a purchase
                  order those are not necessarily needed in a rule considering they do not provide much value-add (e.g.
                  when authorization objects are less critical). The more authorization objects are added to a rule the
                  more specific the rules will be but often providing the same result when performing a risk analysis.
                  In conclusion, this has a negative effect on the ability of a successful completion of a risk analysis
                  job due to much larger resource consumption.
                </Typography>
                <Link sx={{ ml: 3 }} href='/functions/'>
                  {' '}
                  {'=> Go to Functions'}
                </Link>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls='panel4d-content' id='panel4d-header'>
            <Typography>4 Avoid complexity in rule definitions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={'row'}>
              <img width={AccordionImageWidth} height={AccordionImageHeight} src={image4} />
              <Stack sx={{ justifyContent: 'space-between' }}>
                <Typography variant={'body1'} sx={{ mt: 2, mx: 3 }}>
                  While theoretically any rule can be defined in XAMS CRAF as in any other GRC application rules can be
                  built even more granular since Authorization IDs can be nested into another Authorization ID. However,
                  this is not recommended from a performance perspective and may only be used in rare cases since it
                  introduces another level of complexity.
                </Typography>
                <Link sx={{ ml: 3 }} href='/risks/'>
                  {' '}
                  {'=> Go to Risks'}
                </Link>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary aria-controls='panel5d-content' id='panel5d-header'>
            <Typography>5 Remediate findings on role level first before proceeding on user level</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={'row'}>
              <img width={AccordionImageWidth} height={AccordionImageHeight} src={image5} />
              <Typography variant={'body1'} sx={{ mt: 2, mx: 3 }}>
                XAMS CRAF might repeatedly record user-level findings caused by a single role assigned to multiple
                users. This redundancy can quickly consume memory, risking an aborted risk analysis from resource
                scarcity. Always remediate risks at the role level as much as possible before tackling the user level.
                Otherwise, risk analysis becomes a prolonged task, contrary to its intended efficiency.
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Stack>
  );
};

export default GuidancePage;
