import { TableRow, alpha, lighten, styled } from '@mui/material';

const ContentTableRow = styled(TableRow)(({ theme }) => ({
  '&.deleted:not(:hover)': {
    backgroundColor: alpha(lighten(theme.palette.action.disabledBackground, 0.2), 0.02),
    color: theme.palette.action.disabled,
  },
  '&.deprecated:not(:hover)': {
    backgroundColor: alpha(lighten(theme.palette.action.disabledBackground, 0.2), 0.02),
    color: theme.palette.action.disabled,
  },
}));

export default ContentTableRow;
