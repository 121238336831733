import { Alert, DialogContentText, Stack } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { finalizeDialogOptions, releaseDialogOptions } from '../dialog-action-buttons-options';

export function confirmFinalizeRuleset(confirm: (options?: ConfirmOptions) => Promise<void>, risks: RiskDto[]) {
  const canFinalize = risks.length > 0;
  const draftRisks = risks.filter((risk) => risk.status === ContentTypeStatus.Draft);
  const title = canFinalize ? 'Confirmation dialogue' : 'Error';

  const content = (() => {
    if (canFinalize) {
      if (draftRisks.length > 0) {
        return (
          <DialogContentText>
            <Stack spacing={1}>
              <Alert severity='warning'>No changes can be made after this version has been finalized.</Alert>
              <Alert severity='info'>
                Underlying objects exist in status draft. Those object will be finalized as well.
              </Alert>

              {/* @TODO: show detailed list on demand (collapsible)
               Risks to be finalized:
              <List dense={true}>
          {hasDraftRisks.map((risk) => (
            <ListItem key={risk.id}>
              <ListItemText primary={risk.name} />
            </ListItem>
          ))}
        </List> */}
            </Stack>
          </DialogContentText>
        );
      }
      return (
        <DialogContentText>
          <Alert severity='warning'>No changes can be made after this version has been finalized.</Alert>
        </DialogContentText>
      );
    }
    return <Alert severity='error'>Object cannot be finalized. It must contain at least one risk.</Alert>;
  })();

  return {
    canFinalize,
    dialog: confirm({
      title: title,
      content: content,
      ...finalizeDialogOptions(canFinalize),
    }),
  };
}

export function confirmReleaseRuleset(confirm: (options?: ConfirmOptions) => Promise<void>, risks: RiskDto[]) {
  const canRelease = risks.length > 0;
  const finalRisks = risks.filter((risk) => risk.status === ContentTypeStatus.Final);
  const title = canRelease ? 'Confirmation dialogue' : 'Error';

  const content = (() => {
    if (canRelease) {
      if (finalRisks.length > 0) {
        return (
          <DialogContentText>
            <Stack spacing={1}>
              <Alert severity='warning'>No changes can be made after this version has been released.</Alert>
              <Alert severity='info'>Underlying objects will be released as well.</Alert>
            </Stack>
          </DialogContentText>
        );
      }
      return (
        <DialogContentText>
          <Alert severity='warning'>No changes can be made after this version has been released.</Alert>
        </DialogContentText>
      );
    }
    return <Alert severity='error'>Object cannot be released. It must contain at least one risk.</Alert>;
  })();

  return {
    canRelease,
    dialog: confirm({
      title: title,
      content: content,
      ...releaseDialogOptions(canRelease),
    }),
  };
}
