import { GridRowId, GridValidRowModel, GridRowsProp } from '@mui/x-data-grid';
import { FunctionComponent, ReactNode, useState } from 'react';

export interface DataGridRowsData {
  // saved new or edited row (state)
  unsavedRows: Map<GridRowId, GridValidRowModel>;
  // saved row state before editing (for reverting)
  rowsBeforeChange: Map<GridRowId, GridValidRowModel>;
  rows: GridRowsProp;
}

export class DataGridRows {
  constructor(
    public data: DataGridRowsData,
    public setData: (action: (data: DataGridRowsData) => DataGridRowsData) => void,
  ) {}
}

export const RowsContextProvider: FunctionComponent<{
  RowsContext: React.Context<DataGridRows>;
  children?: ReactNode | undefined;
}> = ({ RowsContext, children }) => {
  const setData = (action: (rows: DataGridRowsData) => DataGridRowsData) => {
    setState({ ...state, data: action(state.data) });
  };

  const initState = new DataGridRows(
    {
      unsavedRows: new Map<GridRowId, GridValidRowModel>(),
      rowsBeforeChange: new Map<GridRowId, GridValidRowModel>(),
      rows: [],
    },
    setData,
  );

  const [state, setState] = useState<DataGridRows>(initState);

  return <RowsContext.Provider value={state}>{children}</RowsContext.Provider>;
};
