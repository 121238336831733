import { faFilter, faMinus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Typography,
  Toolbar,
  Stack,
  Tooltip,
  IconButton,
  Grid,
  TextField,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Dispatch, SetStateAction, useState } from 'react';

import { BaseContentType } from '../content-table/content-table-body-cell';

interface ContentTableToolbarProps<TableData extends BaseContentType> {
  title: string;
  totalCount: number;
  numSelected: number;
  tableData: TableData[] | null;
  setSearchInput: Dispatch<SetStateAction<string>>;
  searchFilter: string;
  setSearchFilter: Dispatch<SetStateAction<string>>;
  massTrash?: {
    handleSelectedTrash: () => void;
    tooltipTitle: string;
    iconType: 'delete' | 'remove';
  };
  massDeletion?: {
    handleSelectedDelete: () => void;
    tooltipTitle: string;
    iconType: 'delete' | 'remove';
  };
}

export function ContentTableToolbar<TableData extends BaseContentType>({
  title,
  totalCount,
  numSelected,
  tableData,
  setSearchInput,
  searchFilter,
  setSearchFilter,
  massTrash,
  massDeletion,
  /*...props*/
}: ContentTableToolbarProps<TableData>) {
  const [toggleFilter, setToggleFilter] = useState(false);

  const handleSearchFilterChange = (event: SelectChangeEvent) => {
    setSearchFilter(event.target.value);
  };

  return (
    <Toolbar
      sx={{
        pl: { xs: 0, sm: 0 },
        pr: { xs: 0, sm: 0 },
        ...(numSelected > 0 && {
          pl: { xs: 2, sm: 2 },
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Grid container alignItems={'center'}>
        <Grid item xs={6}>
          {numSelected > 0 ? (
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography color='inherit' variant='subtitle1' component='div'>
                {numSelected} selected {title}
              </Typography>
              {massTrash && (
                <Tooltip title={massTrash.tooltipTitle}>
                  <IconButton
                    size='small'
                    onClick={() => {
                      massTrash.handleSelectedTrash();
                    }}
                  >
                    {massTrash.iconType === 'delete' && <FontAwesomeIcon icon={faTrash} />}
                    {massTrash.iconType === 'remove' && <FontAwesomeIcon icon={faMinus} />}
                  </IconButton>
                </Tooltip>
              )}
              {massDeletion && (
                <Tooltip title={massDeletion.tooltipTitle}>
                  <IconButton
                    size='small'
                    onClick={() => {
                      massDeletion.handleSelectedDelete();
                    }}
                  >
                    {massDeletion.iconType === 'delete' && <FontAwesomeIcon icon={faTrash} />}
                    {massDeletion.iconType === 'remove' && <FontAwesomeIcon icon={faMinus} />}
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ) : (
            <Stack direction={'row'} alignItems={'center'}>
              <Typography sx={{ ml: 2 }} variant={'h6'} id='tableTitle' component='div'>
                {title} {totalCount > 0 && `(${totalCount})`}
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid item xs={6}>
          {tableData && (
            <Stack direction='row' spacing={1} justifyContent={'end'}>
              <FontAwesomeIcon icon={faSearch} style={{ marginTop: 10 }} />
              <TextField
                sx={{ width: '40ch' }}
                id='content-table-search-input'
                variant='standard'
                onChange={(event) => {
                  setSearchInput(event.target.value.toLowerCase());
                }}
              />
              {toggleFilter && (
                <FormControl>
                  <InputLabel id='action-select-label' size='small'>
                    Filter
                  </InputLabel>
                  <Select
                    labelId='search-filter-select-label'
                    id='search-filter-select'
                    label='Search filter'
                    size='small'
                    value={searchFilter}
                    onChange={handleSearchFilterChange}
                  >
                    <MenuItem key={'contains'} value='contains' defaultChecked>
                      contains
                    </MenuItem>
                    <MenuItem key={'notContains'} value='notContains'>
                      not contains
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              <Tooltip title='Filter list'>
                <IconButton
                  size='small'
                  onClick={() => {
                    setToggleFilter((toggle) => !toggle);
                  }}
                >
                  <FontAwesomeIcon icon={faFilter} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
}
