import { useMemo } from 'react';

import { RiskLevelDto } from '../../../services/dto/risk-levels/risk-level.dto';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { TableOrder, sortTableData } from '../../content-table-head';
import { TableHeadCell } from '../../content-table-head/content-table-header-filter';
import { TableBodyCellMapper, TableBodyMapper } from '../../content-table/content-table-body-cell';
import RiskLevelRating from '../risk-level-rating';
import {
  RISK_LEVEL_LABEL_CRITICALITY_ZERO,
  RISK_LEVEL_LABEL_RISK_LEVEL_NOT_FOUND,
} from '../risk-level-rating/constants';

export type RisksOrder = TableOrder;
export type RisksOrderBy =
  | 'name'
  | 'displayName'
  | 'riskType'
  | 'riskLevel'
  | 'businessProcess'
  | 'finalizedAt'
  | 'author'
  | 'status';

export function sortRisks(
  order: RisksOrder,
  orderBy: RisksOrderBy,
  risks: RiskDto[],
  rowMapper: TableBodyMapper<RiskDto, RisksOrderBy>,
) {
  return sortTableData(order, orderBy, risks, rowMapper);
}

export const useRiskContentTableExtraCells = (riskLevels: RiskLevelDto[]) => {
  const extraCells = useMemo(() => {
    return {
      riskType: {
        value: (risk: RiskDto) => risk.riskType,
        renderValue: (risk: RiskDto) => risk.riskType,
      } as TableBodyCellMapper<RiskDto>,
      riskLevel: {
        value: (risk: RiskDto) => risk.criticality.toString(),
        sortValue: (risk: RiskDto) => risk.criticality,
        filterValue: (risk: RiskDto) => {
          if (risk.riskLevel?.name) {
            return risk.riskLevel.name;
          }
          if (risk.criticality === 0) {
            return RISK_LEVEL_LABEL_CRITICALITY_ZERO;
          }
          return RISK_LEVEL_LABEL_RISK_LEVEL_NOT_FOUND;
        },
        renderValue: (risk: RiskDto) => (
          <RiskLevelRating name='risk-level' riskLevels={riskLevels} value={risk.criticality} readOnly centerText />
        ),
      } as TableBodyCellMapper<RiskDto>,
      businessProcess: {
        value: (risk: RiskDto) => risk.businessProcess?.name ?? '',
        sortValue: (risk: RiskDto) => risk.businessProcess?.name ?? null,
        renderValue: (risk: RiskDto) => risk.businessProcess?.name ?? '',
      } as TableBodyCellMapper<RiskDto>,
    };
  }, [riskLevels]);

  return extraCells;
};

export const RiskContentTableExtraHeads: TableHeadCell<RisksOrderBy>[] = [
  {
    id: 'riskType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'riskLevel',
    numeric: false,
    disablePadding: false,
    label: 'Risk Level',
  },
  {
    id: 'businessProcess',
    numeric: false,
    disablePadding: false,
    label: 'Business Process',
  },
];

export type RulesetRisksOrderBy =
  | 'name'
  | 'displayName'
  | 'riskType'
  | 'riskLevel'
  | 'businessProcess'
  | 'author'
  | 'status';
export const RulesetRisksContentTableExtraHeads: TableHeadCell<RulesetRisksOrderBy>[] = [
  {
    id: 'riskType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'riskLevel',
    numeric: false,
    disablePadding: false,
    label: 'Risk Level',
  },
  {
    id: 'businessProcess',
    numeric: false,
    disablePadding: false,
    label: 'Business Process',
  },
];
