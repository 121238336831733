import { Alert, Autocomplete, Checkbox, MenuItem, Paper, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import TabPanel from '../../components/tab-panel';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useRulesetService } from '../../services/hooks';
import { FunctionContentTypeName } from '../../services/models/function-content-type-name';
import { TableAutocompleteFieldWidth } from '../../styles/content-tables';
import { ExportCfControls } from './export-cf';
import { ExportGrcControls } from './export-grc';
import { ExportIagControls } from './export-iag';
import { ExportXspControls } from './export-xsp';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportRulesetProps {}

const ExportRulesetPage = (props: ExportRulesetProps) => {
  const [allRulesets, setAllRulesets] = useState<RulesetDto[]>([]);
  const [selectedRulesets, setSelectedRulesets] = useState<RulesetDto[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const [alert, setAlert] = useState<{ success?: string; error?: string }>({});

  const rulesetService = useRulesetService();

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<{ system: FunctionContentTypeName }>({
    defaultValues: {
      system: FunctionContentTypeName.AbapOP,
    },
  });

  const functionContentTypeName = watch('system');

  const a11yProps = (index: number) => {
    return {
      id: `export-tab-${index}`,
      'aria-controls': `export-tab-panel-${index}`,
    };
  };

  const systemOptions = useMemo(() => {
    if (tabValue === 0) {
      return [
        {
          key: FunctionContentTypeName.AbapOP,
          value: FunctionContentTypeName.AbapOP,
          label: 'ABAP OP',
          disabled: false,
        },
        {
          key: FunctionContentTypeName.SfOp,
          value: FunctionContentTypeName.SfOp,
          label: 'SF OP',
          disabled: true,
        },
        {
          key: FunctionContentTypeName.SapBtp,
          value: FunctionContentTypeName.SapBtp,
          label: 'SAP BTP',
          disabled: true,
        },
        {
          key: FunctionContentTypeName.Ariba,
          value: FunctionContentTypeName.Ariba,
          label: 'Ariba',
          disabled: true,
        },
      ];
    }
    if (tabValue === 1) {
      return [
        {
          key: FunctionContentTypeName.AbapOP,
          value: FunctionContentTypeName.AbapOP,
          label: 'ABAP OP',
          disabled: false,
        },
        {
          key: FunctionContentTypeName.SfOp,
          value: FunctionContentTypeName.SfOp,
          label: 'SF OP',
          disabled: false,
        },
        {
          key: FunctionContentTypeName.SapBtp,
          value: FunctionContentTypeName.SapBtp,
          label: 'SAP BTP',
          disabled: false,
        },
        {
          key: FunctionContentTypeName.Ariba,
          value: FunctionContentTypeName.Ariba,
          label: 'Ariba',
          disabled: false,
        },
      ];
    }
    return [
      {
        key: FunctionContentTypeName.AbapOP,
        value: FunctionContentTypeName.AbapOP,
        label: 'ABAP OP',
        disabled: false,
      },
      {
        key: FunctionContentTypeName.SfOp,
        value: FunctionContentTypeName.SfOp,
        label: 'SF OP',
        disabled: false,
      },
      {
        key: FunctionContentTypeName.SapBtp,
        value: FunctionContentTypeName.SapBtp,
        label: 'SAP BTP',
        disabled: true,
      },
      {
        key: FunctionContentTypeName.Ariba,
        value: FunctionContentTypeName.Ariba,
        label: 'Ariba',
        disabled: true,
      },
    ];
  }, [tabValue]);

  const onTabChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
      switch (newValue) {
        case 0:
          setValue('system', FunctionContentTypeName.AbapOP);
          break;
        case 1:
          setValue('system', FunctionContentTypeName.SfOp);
          break;
        case 2:
          setValue('system', FunctionContentTypeName.AbapOP);
          break;
        case 3:
          setValue('system', FunctionContentTypeName.AbapOP);
          break;
      }
    },
    [setValue],
  );

  const fetchAllRulesets = useCallback(async () => {
    const rulesets = await rulesetService.getRulesets();
    setAllRulesets(rulesets.data);
  }, [rulesetService]);

  const formatFilename = (name: string, suffix: string, extension: string) => {
    const now = new Date().toISOString().split('T')[0].replaceAll('-', '');
    return `${now}${name}_${suffix}.${extension}`;
  };

  const formatXspFilename = (name: string, contentType: string, suffix: string) => {
    const now = new Date().toISOString().split('T')[0].replaceAll('-', '');
    return `XSP_${contentType}_${name}_${suffix}_${now}.zip`;
  };

  const downloadFile = useCallback(async (blob: Blob, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }, []);

  useEffect(() => {
    fetchAllRulesets();
  }, [fetchAllRulesets]);

  return (
    <Stack spacing={3}>
      <Paper sx={{ p: 2 }}>
        <Typography sx={{ flex: '1 1 100%', mb: 2 }} variant='h5' id='tableTitle' component='div'>
          Export Ruleset
        </Typography>
        <Stack spacing={2} sx={{ width: 2.5 * TableAutocompleteFieldWidth }}>
          <Autocomplete
            multiple
            size='small'
            onChange={(event, value) => {
              setSelectedRulesets(value);
            }}
            value={selectedRulesets}
            options={allRulesets}
            autoHighlight
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label='Available Rulesets' />}
          />
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Tabs value={tabValue} onChange={onTabChange} aria-label='details tabs'>
              <Tab label='XAMS' {...a11yProps(0)} />
              <Tab label='XSP' {...a11yProps(1)} />
              <Tab label='SAP Access Control' {...a11yProps(2)} />
              <Tab label='SAP IAG' {...a11yProps(3)} />
            </Tabs>
            <Controller
              name='system'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  size='small'
                  sx={{ width: '10rem' }}
                  key={'system'}
                  margin='dense'
                  select
                  label='System'
                  error={!!errors.system}
                  helperText={errors.system?.message?.toString()}
                  {...field}
                >
                  {systemOptions.map((option) => (
                    <MenuItem key={option.key} value={option.value} disabled={option.disabled}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Stack>
          <TabPanel value={tabValue} index={0}>
            <ExportCfControls
              selectedRulesets={selectedRulesets}
              downloadFile={downloadFile}
              formatFilename={formatFilename}
              setAlert={setAlert}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ExportXspControls
              selectedRulesets={selectedRulesets}
              functionContentTypeName={functionContentTypeName}
              downloadFile={downloadFile}
              formatXspFilename={formatXspFilename}
              setAlert={setAlert}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <ExportGrcControls
              selectedRulesets={selectedRulesets}
              functionContentTypeName={functionContentTypeName}
              downloadFile={downloadFile}
              formatFilename={formatFilename}
              setAlert={setAlert}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <ExportIagControls selectedRulesets={selectedRulesets} />
          </TabPanel>
          {alert.success && <Alert severity='success'>{alert.success}</Alert>}
          {alert.error && <Alert severity='error'>{alert.error}</Alert>}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ExportRulesetPage;
