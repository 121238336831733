import { Autocomplete, Grid, InputLabel, MenuItem, Stack, TextField } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { confirmReleaseRisk } from 'src/components/edit-risk-header/dialogs';
import { UpdateRiskDto } from 'src/services/dto/risks/update-risk.dto';
import { ContentTypeStatus } from 'src/services/models/content-type-status';
import { compareContentArray } from 'src/shared/utils';

import { ContentTableModal } from '../../../components/content-table-modal';
import RiskLevelRating from '../../../components/risks/risk-level-rating';
import { BusinessProcessDto } from '../../../services/dto/business-process/business-process.dto';
import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { RiskLevelDto } from '../../../services/dto/risk-levels/risk-level.dto';
import { CreateNewVersionRiskDto } from '../../../services/dto/risks/create-new-version-risk.dto';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { RiskType } from '../../../services/models/risk-type';

interface CreateRiskNewVersionFormData {
  name: string;
  displayName: string;
  riskType: RiskType;
  riskOwner: string;
  criticality: number;
  businessProcessId: string;
  description: string;
}

interface RiskModalProps {
  open: boolean;
  onClose: () => void;
  risk: RiskDto;
  allFunctions: RiskFunctionDto[];
  riskLevels: RiskLevelDto[];
  allBusinessProcesses: BusinessProcessDto[];
  loadFunctionsFromRisk: (risk: RiskDto) => Promise<RiskFunctionDto[]>;
  onRiskCreateNewVersion: (id: string, data: CreateNewVersionRiskDto, risk: RiskDto) => void;
  onRiskRelease: (riskFunctionId: string, data: UpdateRiskDto) => void;
}

const CreateRiskNewVersionModal: FunctionComponent<RiskModalProps> = ({
  open,
  risk,
  onClose,
  allFunctions,
  riskLevels,
  allBusinessProcesses,
  onRiskRelease,
  loadFunctionsFromRisk,
  onRiskCreateNewVersion,
  ...props
}) => {
  const [selectedBusinessProcess, setSelectedBusinessProcess] = useState<BusinessProcessDto | null>(null);
  const [newBusinessProcessName, setNewBusinessProcessName] = useState('');
  const [oldFunctionsFromRisk, setOldFunctionsFromRisk] = useState<RiskFunctionDto[] | undefined>(undefined);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateRiskNewVersionFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
      riskType: RiskType.CriticalAuthorization,
      criticality: 0,
      riskOwner: '',
      businessProcessId: '',
    },
  });

  const fetchOldRiskFunctionsFromRisk = useCallback(async () => {
    setOldFunctionsFromRisk(await loadFunctionsFromRisk(risk));
  }, [loadFunctionsFromRisk, risk]);

  const hasChanges = useCallback(
    (data: FieldValues, content: RiskDto, newChildren: RiskFunctionDto[]) => {
      return (
        data.name !== content.name ||
        data.displayName !== content.displayName ||
        data.description !== content.description ||
        data.businessProcessId !== (content.businessProcess?.id ?? '') ||
        data.criticality !== content.criticality ||
        data.riskType !== content.riskType ||
        data.riskOwner !== content.riskOwner ||
        !compareContentArray(newChildren, oldFunctionsFromRisk ?? [])
      );
    },
    [oldFunctionsFromRisk],
  );

  const confirm = useConfirm();

  const onRelease = useCallback(
    async (data: FieldValues) => {
      if (oldFunctionsFromRisk) {
        const { canRelease, dialog } = confirmReleaseRisk(confirm, risk, oldFunctionsFromRisk, data);
        dialog
          .then(() => {
            if (canRelease) {
              onRiskRelease(risk.id, {
                status: ContentTypeStatus.Released,
              });
            }
          })
          .catch(() => {
            /* ... */
          });
      }
    },
    [confirm, onRiskRelease, oldFunctionsFromRisk, risk],
  );

  const onCreateNewVersion = useCallback(
    (data: FieldValues, functionIds?: string[]) => {
      onRiskCreateNewVersion(
        risk.id,
        {
          displayName: data.displayName,
          description: data.description,
          riskType: data.riskType,
          criticality: data.criticality,
          riskOwner: data.riskOwner,
          businessProcessId: data.businessProcessId || null,
          riskFunctionIds: functionIds,
        },
        risk,
      );
    },
    [risk, onRiskCreateNewVersion],
  );

  const onAutocompleteChange = useCallback(
    (_: any, newSelectedBusinessProcess: BusinessProcessDto | null) => {
      setSelectedBusinessProcess(newSelectedBusinessProcess);
      setValue('businessProcessId', newSelectedBusinessProcess?.id ?? '');
    },
    [setSelectedBusinessProcess, setValue],
  );
  const onInputChange = useCallback((event: any, newBusinessProcessName: string) => {
    setNewBusinessProcessName(newBusinessProcessName);
  }, []);

  useEffect(() => {
    setValue('name', risk.name);
    setValue('displayName', risk.displayName);
    setValue('description', risk.description);
    setValue('riskType', risk.riskType);
    setValue('criticality', risk.criticality);
    setValue('riskOwner', risk.riskOwner);
    setValue('businessProcessId', risk.businessProcess?.id ?? '');
  }, [
    allBusinessProcesses,
    risk.businessProcess?.id,
    risk.criticality,
    risk.description,
    risk.displayName,
    risk.name,
    risk.riskOwner,
    risk.riskType,
    setValue,
  ]);

  useEffect(() => {
    fetchOldRiskFunctionsFromRisk();
  }, [fetchOldRiskFunctionsFromRisk]);

  const registerOptions = {
    riskType: { required: true },
    criticality: { required: true },
    riskOwner: undefined,
    businessProcessId: undefined,
  };

  return (
    <ContentTableModal<RiskDto, CreateRiskNewVersionFormData, RiskFunctionDto>
      title='Edit Risk'
      type='CreateNewVersion'
      createNewVersion={{
        hasChanges,
        onCreateNewVersion,
        onRelease,
        content: risk,
      }}
      open={open}
      onClose={onClose}
      form={{
        register,
        setValue,
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors },
      }}
      contentChildren={{
        title: 'Functions',
        loadChildren: loadFunctionsFromRisk,
        allChildren: allFunctions,
        addFieldLabel: 'Add Functions to Risk',
      }}
    >
      <Grid item sm={4}>
        <Controller
          name='riskType'
          control={control}
          rules={registerOptions.riskType}
          render={({ field }) => (
            <TextField
              key={'riskType'}
              margin='dense'
              select
              label='Type'
              placeholder={risk.riskType}
              fullWidth
              error={!!errors.riskType}
              helperText={errors.riskType?.message?.toString()}
              {...field}
            >
              <MenuItem key={'CA'} value={'CA'}>
                CA
              </MenuItem>
              <MenuItem key={'SOD'} value={'SOD'}>
                SOD
              </MenuItem>
            </TextField>
          )}
        />
      </Grid>
      <Grid item sm={4}>
        <Controller
          name='riskOwner'
          control={control}
          rules={registerOptions.riskOwner}
          render={({ field }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              key={'riskOwner'}
              margin='dense'
              label='Risk Owner'
              placeholder={risk.riskOwner}
              fullWidth
              error={!!errors.criticality}
              helperText={errors.riskOwner?.message?.toString()}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item sm={4}>
        <Controller
          name='criticality'
          control={control}
          rules={registerOptions.criticality}
          render={({ field }) => (
            <Stack sx={{ display: 'flex' }}>
              <InputLabel shrink id='risk-level-label'>
                Risk Level
              </InputLabel>
              <RiskLevelRating
                sx={{ position: 'relative', bottom: '11px' }}
                centerText
                height={15}
                name='risk-level'
                riskLevels={riskLevels}
                value={field.value}
                onChange={(event, criticality) => {
                  setValue('criticality', criticality ?? 0);
                }}
              />
            </Stack>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='businessProcessId'
          control={control}
          rules={registerOptions.businessProcessId}
          render={({ field }) => (
            <Autocomplete
              value={selectedBusinessProcess}
              onChange={onAutocompleteChange}
              inputValue={newBusinessProcessName}
              onInputChange={(_, newBusinessProcessName) => {
                setNewBusinessProcessName(newBusinessProcessName);
              }}
              options={allBusinessProcesses}
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
              renderInput={(params) => (
                <TextField
                  margin='dense'
                  {...params}
                  label='Business Process'
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          )}
        />
      </Grid>
    </ContentTableModal>
  );
};

export default CreateRiskNewVersionModal;
