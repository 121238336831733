import { faRectangleWide } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { faRectangleWide as faRectangleWideSolid } from '@awesome.me/kit-6741fca89c/icons/classic/solid';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Stack, Typography } from '@mui/material';
import Rating, { IconContainerProps, RatingProps } from '@mui/material/Rating';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';

import { RiskLevelDto } from '../../../services/dto/risk-levels/risk-level.dto';
import { RISK_LEVEL_LABEL_CRITICALITY_ZERO, RISK_LEVEL_LABEL_RISK_LEVEL_NOT_FOUND } from './constants';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

function RiskLevelIconContainer({
  riskLevelIcons,
  currentValue,
  emptyIcon,
  value,
  hover,
  ...props
}: IconContainerProps & {
  currentValue: number | null | undefined;
  riskLevelIcons: Record<number, { icon: React.ReactElement<unknown> }>;
  emptyIcon: React.ReactNode;
  hover: number;
}) {
  const icon = React.useMemo(() => {
    if (hover !== -1) {
      if (value <= hover) {
        return riskLevelIcons[hover] ? riskLevelIcons[hover].icon : emptyIcon;
      }
      return emptyIcon;
    }
    if (currentValue) {
      if (value <= currentValue) {
        return riskLevelIcons[currentValue] ? riskLevelIcons[currentValue].icon : emptyIcon;
      }
      return emptyIcon;
    }
    return emptyIcon;
  }, [currentValue, emptyIcon, hover, riskLevelIcons, value]);

  return <span {...props}>{icon}</span>;
}

export default function RiskLevelRating({
  riskLevels,
  value,
  height,
  iconProps,
  centerText,
  ...props
}: RatingProps & {
  riskLevels: RiskLevelDto[];
  height?: number;
  iconProps?: FontAwesomeIconProps;
  centerText?: boolean;
}) {
  const [hover, setHover] = React.useState(-1);

  const theme = useTheme();

  const riskLevelIconStyle = React.useMemo(() => {
    return {
      marginRight: 1,
      marginTop: 2,
      height: height ?? '0.9rem',
    };
  }, [height]);

  const RiskLevelIconEmpty = React.useMemo(() => {
    return (
      <FontAwesomeIcon
        style={riskLevelIconStyle}
        icon={faRectangleWide}
        fontSize='inherit'
        color={theme.palette.action.disabled}
        {...iconProps}
      />
    );
  }, [iconProps, riskLevelIconStyle, theme.palette.action.disabled]);

  const riskLevelIcons = React.useMemo(() => {
    const ret: Record<
      number,
      {
        icon: React.ReactElement<unknown>;
        label: string;
      }
    > = {};
    riskLevels.forEach((riskLevel) => {
      ret[riskLevel.criticality] = {
        // Risk Level Rank Icon (Rectangle)
        icon: (
          <FontAwesomeIcon
            icon={faRectangleWideSolid}
            color={riskLevel.color}
            fontSize='inherit'
            style={riskLevelIconStyle}
            {...iconProps}
          />
        ),
        label: riskLevel.name,
      };
    });
    return ret;
  }, [riskLevels, riskLevelIconStyle, iconProps]);

  const riskLevelLabel = React.useMemo(() => {
    if (hover !== -1) {
      return (
        riskLevels.find((riskLevel) => riskLevel.criticality === hover)?.name ?? RISK_LEVEL_LABEL_RISK_LEVEL_NOT_FOUND
      );
    }
    if (value === undefined || value === null) {
      return RISK_LEVEL_LABEL_RISK_LEVEL_NOT_FOUND;
    }
    if (value === 0) {
      return RISK_LEVEL_LABEL_CRITICALITY_ZERO;
    }
    return (
      riskLevels.find((riskLevel) => riskLevel.criticality === value)?.name ?? RISK_LEVEL_LABEL_RISK_LEVEL_NOT_FOUND
    );
  }, [hover, value, riskLevels]);

  const containerWidth = React.useMemo(() => {
    if (centerText) {
      if (height) {
        return height * 1.5 * riskLevels.length;
      }
      return 19 * riskLevels.length;
    }
    return undefined;
  }, [centerText, height, riskLevels.length]);

  return (
    <Stack
      sx={{ display: 'flex', width: containerWidth, alignItems: 'center', ...props.sx }}
      direction={centerText ? 'column' : 'row'}
    >
      <Typography sx={{ position: 'relative', top: '6px' }}>{riskLevelLabel}</Typography>
      <StyledRating
        size='small'
        defaultValue={0}
        precision={1}
        max={riskLevels.length}
        emptyIcon={RiskLevelIconEmpty}
        value={value}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        {...props}
        sx={undefined}
        IconContainerComponent={(iconProps) =>
          RiskLevelIconContainer({
            riskLevelIcons,
            currentValue: value,
            ...iconProps,
            emptyIcon: RiskLevelIconEmpty,
            hover,
          })
        }
        getLabelText={(value: number) => (riskLevelIcons[value] ? riskLevelIcons[value].label : value.toString())}
      />
    </Stack>
  );
}
