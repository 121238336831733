import { faCheck, faCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Chip, MenuItem, Paper, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { dialogActionButtonOptions } from '../../components/dialog-action-buttons-options';
import { RiskLevelDto } from '../../services/dto/risk-levels/risk-level.dto';
import { useRiskLevelService } from '../../services/hooks';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CriticalityProps {}

const CriticalityPage = (props: CriticalityProps) => {
  const theme = useTheme();
  const confirm = useConfirm();
  const [riskLevels, setRiskLevels] = useState<RiskLevelDto[] | null>(null);
  const [editRiskLevelId, setEditRiskLevelId] = useState<string | undefined>(undefined);

  const riskLevelService = useRiskLevelService();

  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      criticality: 0,
      color: '',
    },
  });

  const fetchRiskLevels = useCallback(async () => {
    const riskLevelsResult = await riskLevelService.getRisklevels();
    setRiskLevels(riskLevelsResult.data);
  }, [riskLevelService]);

  const isEditMode = useMemo(() => {
    return !!editRiskLevelId;
  }, [editRiskLevelId]);

  const submitButtonLabel = useMemo(() => (isEditMode ? 'Save' : 'Add'), [isEditMode]);

  const clearForm = useCallback(() => {
    setValue('criticality', 0);
    setValue('name', '');
    setValue('color', '');
    setEditRiskLevelId(undefined);
    clearErrors();
  }, [clearErrors, setValue]);

  const selectRiskLevelForEdit = useCallback(
    (riskLevel: RiskLevelDto) => {
      setValue('criticality', riskLevel.criticality);
      setValue('name', riskLevel.name);
      setValue('color', riskLevel.color);
      setEditRiskLevelId(riskLevel.id);
    },
    [setValue],
  );

  const deleteRiskLevel = useCallback(
    (riskLevel: RiskLevelDto) => {
      confirm({
        title: `Are you sure you want to remove ${riskLevel.name}?`,
        ...dialogActionButtonOptions,
      })
        .then(async () => {
          const result = await riskLevelService.deleteRiskLevel(riskLevel.id);
          setRiskLevels((riskLevels) => {
            return riskLevels?.filter((rl) => rl.id !== riskLevel.id) ?? null;
          });
          if (editRiskLevelId === riskLevel.id) {
            clearForm();
          }
          return true;
        })
        .catch(() => {
          return false;
          /* ... */
        });
    },
    [clearForm, confirm, editRiskLevelId, riskLevelService],
  );

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      if (isEditMode && editRiskLevelId) {
        const result = await riskLevelService.updateRiskLevel(editRiskLevelId, {
          name: data.name,
          criticality: data.criticality,
          color: data.color,
        });
        setRiskLevels((riskLevels) => {
          const newRiskLevels =
            riskLevels?.map((riskLevel) => {
              if (riskLevel.id === result.data.id) {
                riskLevel.name = result.data.name;
                riskLevel.criticality = result.data.criticality;
                riskLevel.color = result.data.color;
              }
              return riskLevel;
            }) ?? null;
          return newRiskLevels
            ? newRiskLevels.sort((a, b) => {
                return a.criticality - b.criticality;
              })
            : null;
        });
        clearForm();
        return;
      }

      const result = await riskLevelService.createRiskLevel({
        name: data.name,
        criticality: data.criticality,
        color: data.color,
      });
      setRiskLevels((riskLevels) => {
        return riskLevels
          ? [...riskLevels, result.data].sort((a, b) => {
              return a.criticality - b.criticality;
            })
          : [result.data];
      });
      clearForm();
    },
    [clearForm, editRiskLevelId, isEditMode, riskLevelService],
  );

  useEffect(() => {
    fetchRiskLevels();
  }, [fetchRiskLevels]);

  const registerOptions = useMemo(() => {
    return {
      criticality: {
        required: 'ID is required',
        min: {
          value: 1,
          message: 'ID must be an integer between 1 and 10..',
        },
        max: {
          value: 10,
          message: 'ID can not be higher than 10.',
        },
        validate: (value: number | string) => {
          const existRisklevel = riskLevels?.find((riskLevel) => {
            if (isEditMode && editRiskLevelId === riskLevel.id) {
              return false;
            }
            return riskLevel.criticality == value;
          });
          if (existRisklevel) {
            return 'Risk level already defined. Please use another ID.';
          }
          return true;
        },
      },
      name: {
        required: 'Name is required.',
        maxLength: 15,
        validate: (value: string) => {
          return true;
        },
      },
      color: { required: true },
    };
  }, [editRiskLevelId, isEditMode, riskLevels]);

  const alreadyUsedColors = useMemo(() => riskLevels?.map((riskLevel) => riskLevel.color) ?? [], [riskLevels]);

  const colorPalette = useMemo(
    (): {
      label: string;
      value: string;
      color?: string;
      disabled?: boolean;
    }[] =>
      [
        { label: '(Please select a Color)', value: '', color: '#FFFFFF' },
        { label: 'Green', value: '#769E79' },
        { label: 'Dark Green', value: '#88BB8A' },
        { label: 'Dark Sea Green', value: '#B2D78E' },
        { label: 'Light Gold', value: '#FFE396' },
        { label: 'Burly Wood', value: '#FAB980' },
        { label: 'Light Salmon', value: '#F19771' },
        { label: 'Light Coral', value: '#E17177' },
        { label: 'Pale Violet Red', value: '#D37494' },
        { label: 'Pale Violet', value: '#CE6CA3' },
        { label: 'Rosy', value: '#B87A8F' },
      ].map((color) => {
        // skip default color
        if (color.value === '') {
          return color;
        }

        // filter out already used colors
        if (alreadyUsedColors.length === 0) {
          return color;
        }

        if (isEditMode) {
          // make current value (in edit mode), selectable
          const editRiskLevel = riskLevels?.find((riskLevel) => riskLevel.id === editRiskLevelId);
          if (editRiskLevel?.color === color.value) {
            return color;
          }
        }
        if (alreadyUsedColors.some((c) => c === color.value)) {
          return { ...color, disabled: true };
        }
        return color;
      }),
    [alreadyUsedColors, editRiskLevelId, isEditMode, riskLevels],
  );

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <Stack sx={{ my: 2 }}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={{ ml: 2 }} variant={'h6'} id='pageTitle' component='div'>
              Risk Levels
            </Typography>
          </Stack>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onReset={() => {
              clearForm();
            }}
          >
            <Stack sx={{ ml: 2, mt: 4, mb: 2 }} spacing={1} direction={'row'}>
              <Controller
                name='criticality'
                control={control}
                rules={registerOptions.criticality}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    margin='dense'
                    placeholder='ID*'
                    type='number'
                    error={!!errors.criticality}
                    helperText={errors.criticality?.message?.toString() ?? ' '}
                    {...field}
                  />
                )}
              />
              <Controller
                name='name'
                control={control}
                rules={registerOptions.name}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      maxLength: 15,
                    }}
                    size='small'
                    margin='dense'
                    placeholder='Name*'
                    error={!!errors.name}
                    helperText={errors.name?.message?.toString() ?? ' '}
                    {...field}
                  />
                )}
              />
              <Controller
                name='color'
                control={control}
                rules={registerOptions.color}
                render={({ field }) => (
                  <TextField
                    select
                    key={'color'}
                    margin='dense'
                    size='small'
                    sx={{ minWidth: 180 }}
                    placeholder='color'
                    error={!!errors.color}
                    helperText={errors.color?.message?.toString() ?? ' '}
                    {...field}
                  >
                    {colorPalette.map((color) => (
                      <MenuItem key={color.value} value={color.value} disabled={color.disabled}>
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faCircle}
                          color={color.color ?? color.value}
                        />{' '}
                        {color.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Box>
                <Button type='submit' variant='contained' color='success' endIcon={<FontAwesomeIcon icon={faCheck} />}>
                  {submitButtonLabel}
                </Button>
              </Box>
              <Box>
                <Button type='reset' variant='contained' color='error' endIcon={<FontAwesomeIcon icon={faXmark} />}>
                  Reset
                </Button>
              </Box>
            </Stack>
          </form>
          <Stack sx={{ ml: 2 }} spacing={1} direction={'row'}>
            {riskLevels &&
              riskLevels.length > 0 &&
              riskLevels.map((riskLevel) => {
                const name = `${riskLevel.criticality} - ${riskLevel.name}`;
                return (
                  <Chip
                    key={riskLevel.id}
                    label={name}
                    onClick={() => {
                      selectRiskLevelForEdit(riskLevel);
                    }}
                    onDelete={() => {
                      deleteRiskLevel(riskLevel);
                    }}
                    sx={{
                      minWidth: 170,
                      backgroundColor: riskLevel.color,
                      color: riskLevel.color ? theme.palette.getContrastText(riskLevel.color) : undefined,
                      '&:hover': {
                        color: riskLevel.color ? theme.palette.getContrastText(riskLevel.color) : undefined,
                        backgroundColor: riskLevel.color,
                      },
                    }}
                  />
                );
              })}
            {(!riskLevels || riskLevels.length <= 0) && <span>No Risk Levels</span>}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default CriticalityPage;
