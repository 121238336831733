import {
  RiskFunctionValueAbapOpTypeName,
  RiskFunctionValueSfOpTypeName,
  RiskFunctionValueAribaTypeName,
  RiskFunctionValueSapBtpTypeName,
} from '../../services/dto/functions/constants';

/// @NOTE: add new RiskFunctionValueTypes here
export const FunctionDataGridTabLabels = {
  [RiskFunctionValueAbapOpTypeName]: 'ABAP OP',
  [RiskFunctionValueSfOpTypeName]: 'SF',
  [RiskFunctionValueAribaTypeName]: 'Ariba',
  [RiskFunctionValueSapBtpTypeName]: 'SAP BTP',
};
