import {
  faBasketShopping,
  faClipboard,
  faCrystalBall,
  faStars,
  faUpload,
} from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RolesUsersOverviewPage from '../pages/roles-users-overview';
import RolesUsersUploadPage from '../pages/roles-users-upload';
import StorePage from '../pages/store';
import UpdatesPage from '../pages/updates';
import { RouteMenuElement } from './route-menu-element';

const mockupRoutes: RouteMenuElement[] = [
  {
    sidebarProps: {
      text: 'Mockup',
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
  },
  {
    path: '/updates',
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    element: <UpdatesPage />,
    sidebarProps: {
      title: 'Updates',
      icon: <FontAwesomeIcon icon={faStars} />,
    },
  },
  {
    path: '/store',
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    element: <StorePage />,
    sidebarProps: {
      title: 'Store',
      icon: <FontAwesomeIcon icon={faBasketShopping} />,
    },
  },
  {
    sidebarProps: {
      text: 'Risk Analysis',
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
  },
  {
    /// Roles & Users
    sidebarProps: {
      title: 'Roles & Users',
      icon: <FontAwesomeIcon icon={faCrystalBall} />,
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    children: [
      {
        path: '/roles/users-upload',
        element: <RolesUsersUploadPage />,
        sidebarProps: {
          title: 'Roles & Users Upload',
          icon: <FontAwesomeIcon icon={faUpload} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
      {
        path: '/roles/users-overview',
        element: <RolesUsersOverviewPage />,
        sidebarProps: {
          title: 'Roles & Users Overview',
          icon: <FontAwesomeIcon icon={faClipboard} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
    ],
  },
];

export default mockupRoutes;
