import { faFileCircleXmark } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography, alpha } from '@mui/material';
import React, { useMemo } from 'react';
import { Radar } from 'react-chartjs-2';
import { BusinessProcessesFunctionsReportDto } from 'src/services/dto/reports/business-processes-functions-report.dto';
import { BusinessProcessesRisksReportDto } from 'src/services/dto/reports/business-processes-risks-report.dto';
import { filterUnique } from 'src/shared/utils';
import { xcpColors } from 'src/styles/main-theme';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProcessRisksFunctionsCountChartBoxProps {
  businessProcessesRisksReportData: BusinessProcessesRisksReportDto[] | undefined;
  businessProcessesFunctionsReportData: BusinessProcessesFunctionsReportDto[] | undefined;
  height?: string | number;
}

const ProcessRisksFunctionsCountChartBox = ({
  businessProcessesRisksReportData,
  businessProcessesFunctionsReportData,
  height,
}: ProcessRisksFunctionsCountChartBoxProps) => {
  //const theme = useTheme();

  const risksFunctionsForBpChartLabels = useMemo(
    () =>
      filterUnique(
        [
          ...(businessProcessesRisksReportData?.map((data) => data.businessProcessName) ?? []),
          ...(businessProcessesFunctionsReportData?.map((data) => data.businessProcessName) ?? []),
        ],
        (a, b) => a === b,
      ),
    [businessProcessesRisksReportData, businessProcessesFunctionsReportData],
  );

  const risksDatasetData = useMemo(() => {
    return risksFunctionsForBpChartLabels.map((bpName) => {
      const riskCountBpResult = businessProcessesRisksReportData?.find((bpRiskReportData) => {
        return bpRiskReportData.businessProcessName === bpName;
      });
      return riskCountBpResult?.riskCount ?? 0;
    });
  }, [risksFunctionsForBpChartLabels, businessProcessesRisksReportData]);

  const riskFunctionsDatasetData = useMemo(() => {
    return risksFunctionsForBpChartLabels.map((bpName) => {
      const functionCountBpResult = businessProcessesFunctionsReportData?.find((bpFunctionReportData) => {
        return bpFunctionReportData.businessProcessName === bpName;
      });
      return functionCountBpResult?.functionCount ?? 0;
    });
  }, [risksFunctionsForBpChartLabels, businessProcessesFunctionsReportData]);

  const risksFunctionsForBpChartDataset = useMemo(
    () => [
      {
        label: 'Risks',
        data: risksDatasetData,
        borderColor: alpha(xcpColors.units[4], 0.4),
        backgroundColor: alpha(xcpColors.units[4], 0.2),
        borderWidth: 2,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        label: 'Functions',
        data: riskFunctionsDatasetData,
        borderColor: alpha(xcpColors.units[2], 0.4),
        backgroundColor: alpha(xcpColors.units[2], 0.2),
        borderWidth: 2,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
    ],
    [risksDatasetData, riskFunctionsDatasetData],
  );
  const risksFunctionsForBpChartData = useMemo(() => {
    return {
      labels: risksFunctionsForBpChartLabels,
      datasets: risksFunctionsForBpChartDataset,
    };
  }, [risksFunctionsForBpChartDataset, risksFunctionsForBpChartLabels]);

  const loadingState = useMemo(() => {
    if (businessProcessesRisksReportData === undefined) {
      return 'loading';
    }
    if (businessProcessesRisksReportData.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [businessProcessesRisksReportData]);

  const canShowChart = useMemo(() => {
    return (
      businessProcessesRisksReportData &&
      businessProcessesFunctionsReportData &&
      risksFunctionsForBpChartLabels.length >= 4 &&
      businessProcessesRisksReportData.length >= 2 &&
      businessProcessesFunctionsReportData.length >= 2
    );
  }, [businessProcessesFunctionsReportData, businessProcessesRisksReportData, risksFunctionsForBpChartLabels]);

  const options = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 20,
          },
        },
      },
      scales: {
        r: {
          ticks: {
            stepSize: 1,
          },
        },
      },
    };
  }, []);

  return (
    <Stack>
      <Typography sx={{ color: xcpColors.grey[1], flex: '1 1 100%', my: 1, ml: 2 }} variant='h6' component='div'>
        Risks & Functions / Business Processes
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box sx={{ my: 2, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loadingState === 'loading' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {loadingState === 'empty' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography align='center'>No Data</Typography>
                <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
              </Stack>
            </Box>
          )}
          {canShowChart && loadingState === 'loaded' && (
            <Radar
              data={risksFunctionsForBpChartData as any}
              options={options as any} ///< @NOTE: use any for plugin options
            />
          )}
          {!canShowChart && loadingState === 'loaded' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography align='center'>Not Available.</Typography>
                <Typography align='center' sx={{ pb: 1 }}>
                  Min. 4 Business Processes Required
                </Typography>
                <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
              </Stack>
            </Box>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default ProcessRisksFunctionsCountChartBox;
