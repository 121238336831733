import { CreateRiskLevelDto } from './dto/risk-levels/create-risk-level.dto';
import { RiskLevelDto } from './dto/risk-levels/risk-level.dto';
import { UpdateRiskLevelDto } from './dto/risk-levels/update-risk-level.dto';
import { HttpService } from './http.service';

export class RiskLevelService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('risk-levels')) {}

  async getRisklevels(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RiskLevelDto[]>(undefined, params);
    return response.data;
  }

  async getRisklevel(riskLevelId: string) {
    const response = await this.httpService.get<RiskLevelDto>(riskLevelId);
    return response.data;
  }

  async createRiskLevel(body: CreateRiskLevelDto) {
    const response = await this.httpService.post<RiskLevelDto>('', body);
    return response.data;
  }

  async updateRiskLevel(riskLevelId: string, body: UpdateRiskLevelDto) {
    const response = await this.httpService.patch<RiskLevelDto>(riskLevelId, body);
    return response.data;
  }

  async deleteRiskLevel(riskLevelId: string) {
    const response = await this.httpService.delete(riskLevelId);
    return response.data;
  }
}
