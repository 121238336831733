import { BusinessProcessesFunctionsReportDto } from './dto/reports/business-processes-functions-report.dto';
import { BusinessProcessesRisksReportDto } from './dto/reports/business-processes-risks-report.dto';
import { ContentCountReportDto } from './dto/reports/content-types-count-report.dto';
import { LastChangedReportDto } from './dto/reports/last-changed-content-report.dto';
import { RiskOwnersRisksReportDto } from './dto/reports/risk-owners-risks-report.dto';
import { RisksRiskLevelsReportDto } from './dto/reports/risks-risk-levels-report.dto';
import { RisksRiskTypesReportDto } from './dto/reports/risks-risk-types-report.dto';
import { RulesetsRisksReportDto } from './dto/reports/rulesets-risks-report.dto';
import { HttpService } from './http.service';

export class ReportsService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('reports')) {}

  async getBusinessProcessesRisksReport(params?: {
    rulesetId: string | null;
    riskType: string | null;
    criticality: number | null;
  }) {
    const response = await this.httpService.get<BusinessProcessesRisksReportDto[]>(`business-processes/risks`, params);
    return response.data;
  }

  async getBusinessProcessesFunctionsReport(params?: { rulesetId: string | null }) {
    const response = await this.httpService.get<BusinessProcessesFunctionsReportDto[]>(
      `business-processes/functions`,
      params,
    );
    return response.data;
  }

  async getRulesetsRisksReport(params?: {
    businessProcessId: string | null;
    riskType: string | null;
    criticality: number | null;
  }) {
    const response = await this.httpService.get<RulesetsRisksReportDto[]>(`rulesets/risks`, params);
    return response.data;
  }

  async getRiskOwnersRisksReport(
    params?: {
      rulesetId: string | null;
      businessProcessId: string | null;
      riskType: string | null;
      criticality: number | null;
    },
    limit?: number,
  ) {
    const response = await this.httpService.get<RiskOwnersRisksReportDto[]>(`risks/risk-owner`, { ...params, limit });
    return response.data;
  }

  async getRisksRiskLevelsReport(params?: {
    rulesetId: string | null;
    businessProcessId: string | null;
    riskType: string | null;
  }) {
    const response = await this.httpService.get<RisksRiskLevelsReportDto>(`risks/risk-levels`, params);
    return response.data;
  }

  async getRisksRiskTypesReport(params?: {
    rulesetId: string | null;
    businessProcessId: string | null;
    criticality: number | null;
  }) {
    const response = await this.httpService.get<RisksRiskTypesReportDto>(`risks/risk-types`, params);
    return response.data;
  }

  async getLastFiveChangedContentReport(params?: {
    rulesetId: string | null;
    businessProcessId: string | null;
    riskType: string | null;
    criticality: number | null;
  }) {
    const response = await this.httpService.get<LastChangedReportDto[]>(`last-changed-content`, {
      ...params,
      amount: 5,
    });
    return response.data;
  }

  async getContentTypesCountReport(params?: {
    rulesetId: string | null;
    businessProcessId: string | null;
    riskType: string | null;
    criticality: number | null;
  }) {
    const response = await this.httpService.get<ContentCountReportDto>(`content-types-count`, params);
    return response.data;
  }
}
