import { FunctionComponent } from 'react';

import FunctionsDataGrid, { FunctionDefinitionColumn } from '..';

import { RiskFunctionValueAribaTypeName } from '../../../../services/dto/functions/constants';
import { RiskFunctionDto, RiskFunctionValueDto } from '../../../../services/dto/functions/function.dto';
import { RiskFunctionOperator } from '../../../../services/models/function-operator';
import { FunctionValueTableFieldWidth } from '../../../../styles/content-tables';
import { AribaRowsContext } from '../../context/ariba';

export interface AribaTableRow {
  id: string;
  group: string;
  position: number;
  operator: RiskFunctionOperator;
  groupId: string;
  isNew?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AribaFunctionsDataGridProps {
  riskFunction: RiskFunctionDto;
  saveValues: () => Promise<RiskFunctionDto | undefined>;
  filter?: {
    search: string;
    operator: 'contains' | 'notContains' | string;
  };
}

const AribaFunctionsDataGrid: FunctionComponent<AribaFunctionsDataGridProps> = ({
  riskFunction,
  saveValues,
  filter,
  /*...prop*/
}) => {
  const columns = [
    {
      field: 'group',
      headerName: 'Group',
      width: 100,
      editable: true,
      filterable: true,
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 90,
      editable: true,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'groupId',
      headerName: 'Group ID',
      width: FunctionValueTableFieldWidth,
      editable: true,
      filterable: true,
    },
    {
      field: 'operator',
      headerName: 'Operator',
      width: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: [RiskFunctionOperator.Or, RiskFunctionOperator.And],
    },
  ] as FunctionDefinitionColumn[];

  const defaultValue: AribaTableRow = {
    id: '',
    group: '',
    position: 0,
    operator: RiskFunctionOperator.Or,
    groupId: '',
  };

  const riskFunctionValueToRow = (value: RiskFunctionValueDto) => {
    return {
      id: value.id,
      contentType: value.type.name,
      group: value.group,
      position: value.position,
      groupId: value.fields.groupId,
      operator: value.operator,
    } as AribaTableRow;
  };

  return (
    <FunctionsDataGrid<AribaTableRow>
      RowsContext={AribaRowsContext}
      contentTypeName={RiskFunctionValueAribaTypeName}
      riskFunctionDefinitionColumns={columns}
      riskFunction={riskFunction}
      saveValues={saveValues}
      riskFunctionValueToRow={riskFunctionValueToRow}
      defaultValue={defaultValue}
      filter={filter}
    />
  );
};

export default AribaFunctionsDataGrid;
