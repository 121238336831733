import { XspExportRulesetDto } from '../dto/export/xsp-export-rulesets.dto';
import { HttpService } from '../http.service';

export class XspExportService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('export/xsp')) {}

  async exportRulesetsFull(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `rulesets/full/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
  async exportRulesetsHeader(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `rulesets/hdr/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
  async exportRulesetsDefiniton(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `rulesets/def/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }

  async exportRisksFull(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `risks/full/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
  async exportRisksHeader(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `risks/hdr/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
  async exportRisksDefiniton(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `risks/def/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }

  async exportFunctionsFull(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `functions/full/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
  async exportFunctionsHeader(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `functions/hdr/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
  async exportFunctionsDefiniton(rulesetIds: string[], functionContentTypeName: string) {
    const response = await this.httpService.postRaw<string>(
      `functions/def/zip`,
      { rulesetIds, functionContentTypeName } as XspExportRulesetDto,
      'arraybuffer',
    );
    return new Blob([response.data], { type: 'application/zip' });
  }
}
