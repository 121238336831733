import { Paper, Box, CircularProgress, Collapse } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMemo } from 'react';

import { ChildrenTable } from '../children-table';
import {
  ChildrenBaseContentType,
  ChildrenBaseContentOrderBy,
  DefaultChildrenBaseContentOrderBy,
} from '../children-table/children-table-body-cell';
import { TableHeadCell } from '../content-table-head/content-table-header-filter';
import { BaseContentType, TableBodyMapper } from './content-table-body-cell';

interface ChildrenCollapseProps<
  Row extends BaseContentType,
  Children extends ChildrenBaseContentType,
  ChildrenOrderBy extends
    | ChildrenBaseContentOrderBy
    | keyof Children
    | number
    | symbol = DefaultChildrenBaseContentOrderBy,
> {
  trash?: boolean;
  row: Row;
  allChildren: Record<string, Children[] | undefined>;
  openRows: string[];
  childrenExtraHeads?: TableHeadCell<ChildrenOrderBy>[];
  childrenExtraCells?: TableBodyMapper<Children, keyof Children>;
  childrenShowHeaders?: ChildrenOrderBy[];
  getChildrenDetailsUrl?: (child: Children) => string;
  parentCols?: number;
}

function ChildrenCollapse<
  Row extends BaseContentType,
  Children extends ChildrenBaseContentType,
  ChildrenOrderBy extends
    | ChildrenBaseContentOrderBy
    | keyof Children
    | number
    | symbol = DefaultChildrenBaseContentOrderBy,
>({
  trash,
  row,
  allChildren,
  openRows,
  childrenExtraHeads,
  childrenExtraCells,
  childrenShowHeaders,
  getChildrenDetailsUrl,
  parentCols,
  /*...props*/
}: ChildrenCollapseProps<Row, Children, ChildrenOrderBy>) {
  const collapseIn = useMemo(() => {
    return openRows.some((rid) => rid === row.id);
  }, [row, openRows]);

  const children = useMemo(() => {
    return allChildren[row.id];
  }, [allChildren, row]);

  return (
    <Collapse in={collapseIn}>
      {!children && (
        <Paper sx={{ width: '100%', bgcolor: grey[50], px: 4 }}>
          <Box sx={{ p: 3 }}>
            <CircularProgress />
          </Box>
        </Paper>
      )}
      {children && (
        <ChildrenTable<Children, ChildrenOrderBy>
          trash={trash}
          rows={children}
          extraHeads={childrenExtraHeads}
          extraCells={childrenExtraCells}
          showHeaders={childrenShowHeaders}
          getChildrenDetailsUrl={getChildrenDetailsUrl}
          parentCols={parentCols}
        />
      )}
    </Collapse>
  );
}

export default ChildrenCollapse;
