import { FunctionComponent } from 'react';

import FunctionsDataGrid, { FunctionDefinitionColumn } from '..';

import { RiskFunctionValueSapBtpTypeName } from '../../../../services/dto/functions/constants';
import { RiskFunctionDto, RiskFunctionValueDto } from '../../../../services/dto/functions/function.dto';
import { RiskFunctionOperator } from '../../../../services/models/function-operator';
import { FunctionValueTableFieldWidth } from '../../../../styles/content-tables';
import { SapBtpRowsContext } from '../../context/sap-btp';

export interface SapBtpTableRow {
  id: string;
  group: string;
  position: number;
  operator: RiskFunctionOperator;
  attribute: string;
  value: string;
  isNew?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SapBtpFunctionsDataGridProps {
  riskFunction: RiskFunctionDto;
  saveValues: () => Promise<RiskFunctionDto | undefined>;
  filter?: {
    search: string;
    operator: 'contains' | 'notContains' | string;
  };
}

const SapBtpFunctionsDataGrid: FunctionComponent<SapBtpFunctionsDataGridProps> = ({
  riskFunction,
  saveValues,
  filter,
  /*...prop*/
}) => {
  const columns = [
    {
      field: 'group',
      headerName: 'Group',
      width: 100,
      editable: true,
      filterable: true,
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 90,
      editable: true,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'attribute',
      headerName: 'Attribute',
      width: 100,
      editable: true,
      filterable: true,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: FunctionValueTableFieldWidth,
      editable: true,
      filterable: true,
    },
    {
      field: 'operator',
      headerName: 'Operator',
      width: 100,
      editable: true,
      type: 'singleSelect',
      valueOptions: [RiskFunctionOperator.Or, RiskFunctionOperator.And],
    },
  ] as FunctionDefinitionColumn[];

  const defaultValue: SapBtpTableRow = {
    id: '',
    group: '',
    position: 0,
    operator: RiskFunctionOperator.Or,
    attribute: '',
    value: '',
  };

  const riskFunctionValueToRow = (value: RiskFunctionValueDto) => {
    return {
      id: value.id,
      contentType: value.type.name,
      group: value.group,
      position: value.position,
      attribute: value.fields.attribute,
      value: value.fields.value,
      operator: value.operator,
    } as SapBtpTableRow;
  };

  return (
    <FunctionsDataGrid<SapBtpTableRow>
      RowsContext={SapBtpRowsContext}
      contentTypeName={RiskFunctionValueSapBtpTypeName}
      riskFunctionDefinitionColumns={columns}
      riskFunction={riskFunction}
      saveValues={saveValues}
      riskFunctionValueToRow={riskFunctionValueToRow}
      defaultValue={defaultValue}
      filter={filter}
    />
  );
};

export default SapBtpFunctionsDataGrid;
