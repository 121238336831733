import { AutocompleteRenderGroupParams, Box } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { DefaultChildrenBaseContentOrderBy } from '../../components/children-table/children-table-body-cell';
import { ContentTable, GroupHeader, GroupItems } from '../../components/content-table';
import { confirmRemoveRiskFromRuleset, confirmRemoveRisksFromRuleset } from '../../components/risks/remove-dialogs';
import {
  RiskContentTableExtraHeads,
  RisksOrderBy,
  sortRisks,
  useRiskContentTableExtraCells,
} from '../../components/risks/risk-table-head';
import { BusinessProcessDto } from '../../services/dto/business-process/business-process.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskLevelDto } from '../../services/dto/risk-levels/risk-level.dto';
import { CreateFromTemplateRiskDto } from '../../services/dto/risks/create-from-existing-risk.dto';
import { CreateNewVersionRiskDto } from '../../services/dto/risks/create-new-version-risk.dto';
import { CreateRiskDto } from '../../services/dto/risks/create-risk.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { UpdateRiskDto } from '../../services/dto/risks/update-risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useRiskService, useRulesetService } from '../../services/hooks';
import { RiskType } from '../../services/models/risk-type';
import CreateRiskModal from '../risks/risk-modal/create';
import CreateRiskFromTemplateModal from '../risks/risk-modal/create-from-template';
import CreateRiskNewVersionModal from '../risks/risk-modal/create-new-version';
import UpdateRiskModal from '../risks/risk-modal/update';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetRisksComponentProps {
  ruleset: RulesetDto;
  risks: RiskDto[] | null;
  totalCount: number;
  onRiskCreated: (newRisk: RiskDto) => void;
  onRiskAdded: (rulesetId: string, riskId: string, risk: RiskDto | null) => void;
  onRiskRemoved: (riskId: string) => void;
  onRisksRemoved: (riskIds: string[]) => void;
  onRiskUpdated: (id: string, data: RiskDto) => void;
  onRiskNewVersionCreated: (newRisk: RiskDto, origin: RiskDto, updateParents: Partial<RulesetDto>[]) => void;
  initRisksFilters: {
    businessProcessId: string | null;
    riskLevelId: string | null;
    riskType: string | null;
  };
  allBusinessProcesses: BusinessProcessDto[];
  riskLevels: RiskLevelDto[];
  allFunctions: RiskFunctionDto[];
  allRisks: RiskDto[];
}

interface RiskFormData {
  name?: string;
  displayName?: string;
  description?: string;
  riskType?: RiskType;
  criticality: number;
}

const RulesetRisksComponent: FunctionComponent<RulesetRisksComponentProps> = ({
  ruleset,
  risks,
  totalCount,
  onRiskCreated,
  onRiskAdded,
  onRiskUpdated,
  onRiskRemoved,
  onRisksRemoved,
  onRiskNewVersionCreated,
  initRisksFilters,
  allBusinessProcesses,
  riskLevels,
  allFunctions,
  allRisks,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'update' | 'createNewVersion' | 'createFromTemplate' | undefined
  >(undefined);
  const [modalRisk, setModalRisk] = useState<RiskDto | undefined>(undefined);

  const rulesetService = useRulesetService();
  const riskService = useRiskService();

  const openCreateRisk = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openEditRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const openCreateFromTemplateRisk = useCallback(() => {
    setModalRisk(undefined);
    setCurrentOpenModal('createFromTemplate');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRisk(undefined);
  }, []);

  const onRiskCreate = useCallback(
    async (data: CreateRiskDto) => {
      const res = await riskService.createRisk(data);
      onRiskCreated(res.data);

      closeModal();
    },
    [closeModal, onRiskCreated, riskService],
  );
  const onRiskCreateFromTemplate = useCallback(
    async (data: CreateFromTemplateRiskDto, template: RiskDto) => {
      const res = await riskService.createFromExistingRisk(template.id, data);
      onRiskCreated(res.data);
      closeModal();
    },
    [closeModal, onRiskCreated, riskService],
  );

  const onCreateNewRiskVersion = useCallback(
    async (id: string, data: CreateNewVersionRiskDto, origin: RiskDto) => {
      const res = await riskService.createNewRiskVersion(id, data);
      onRiskNewVersionCreated(res.data, origin, res.meta.updateParents);
      closeModal();
    },
    [closeModal, onRiskNewVersionCreated, riskService],
  );

  const onRiskUpdate = useCallback(
    async (id: string, data: UpdateRiskDto) => {
      const res = await riskService.updateRisk(id, data);
      onRiskUpdated(id, res.data);
      closeModal();
    },
    [closeModal, onRiskUpdated, riskService],
  );

  const onRiskFinalize = useCallback(
    async (riskId: string, data: UpdateRiskDto) => {
      const res = await riskService.updateRisk(riskId, data);
      onRiskUpdated(riskId, res.data);
      closeModal();
    },
    [closeModal, onRiskUpdated, riskService],
  );

  const onRiskRelease = useCallback(
    async (riskId: string, data: UpdateRiskDto) => {
      try {
        const res = await riskService.updateRisk(riskId, data);
        onRiskUpdated(riskId, res.data);
        closeModal();
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [riskService, closeModal, onRiskUpdated],
  );

  const addRiskToRuleset = useCallback(
    async (ruleset: RulesetDto, risk: RiskDto) => {
      await rulesetService.addRiskToRuleset(ruleset.id, risk.id);
      onRiskAdded(ruleset.id, risk.id, risk);
    },
    [onRiskAdded, rulesetService],
  );

  const getRiskFunctionsFromRisk = useCallback(
    async (risk: RiskDto) => {
      const riskFunctions = await riskService.getFunctionsFromRisk(risk.id);
      return riskFunctions.data;
    },
    [riskService],
  );

  const onRemoveRiskFromRuleset = useCallback(
    async (risk: RiskDto) => {
      const { dialog } = await confirmRemoveRiskFromRuleset(confirm, risk);
      dialog
        .then(async () => {
          await rulesetService.removeRiskFromRuleset(ruleset.id, risk.id);
          onRiskRemoved(risk.id);
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, onRiskRemoved, ruleset.id, rulesetService],
  );

  const onMassDelete = useCallback(
    async (riskIds: string[]) => {
      const { dialog } = await confirmRemoveRisksFromRuleset(confirm, riskIds);
      return dialog
        .then(async () => {
          await rulesetService.removeRisksFromRuleset(ruleset.id, riskIds);
          onRisksRemoved(riskIds);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [confirm, onRisksRemoved, ruleset.id, rulesetService],
  );

  const getRiskUrl = useCallback(
    (risk: RiskDto) => {
      return `/rulesets/${ruleset.id}/risks/${risk.id}`;
    },
    [ruleset],
  );

  const contentTableInitFilters = useMemo(() => {
    const ret = new Map<RisksOrderBy, string | null | undefined>();
    ret.set('businessProcess', allBusinessProcesses.find((bp) => bp.id === initRisksFilters.businessProcessId)?.name);
    ret.set('riskLevel', riskLevels.find((riskLevel) => riskLevel.id === initRisksFilters.riskLevelId)?.name);
    ret.set('riskType', initRisksFilters.riskType);
    return ret;
  }, [
    allBusinessProcesses,
    riskLevels,
    initRisksFilters.riskType,
    initRisksFilters.businessProcessId,
    initRisksFilters.riskLevelId,
  ]);

  const RiskContentTableExtraCells = useRiskContentTableExtraCells(riskLevels);

  return (
    <Box>
      <ContentTable<RiskDto, RisksOrderBy, RiskFunctionDto, DefaultChildrenBaseContentOrderBy, RulesetDto>
        tableToolbarTitle='Risks'
        totalCount={totalCount}
        parent={ruleset}
        rows={risks}
        sortTable={sortRisks}
        openCreate={openCreateRisk}
        createButtonTitle='Create Risk'
        createFromTemplateButtonTitle='Create Risk From Template'
        extraCells={RiskContentTableExtraCells}
        extraHeads={RiskContentTableExtraHeads}
        openCreateNewVersion={openCreateNewVersionRisk}
        openCreateFromTemplate={openCreateFromTemplateRisk}
        openEdit={openEditRisk}
        onRemoveFromParent={onRemoveRiskFromRuleset}
        getDetailsUrl={getRiskUrl}
        loadChildren={getRiskFunctionsFromRisk}
        initFilters={contentTableInitFilters}
        template={{
          allOptions: allRisks,
          label: 'Select existing Risk',
          buttonLabel: 'Add Risk to Ruleset',
          addChildToParent: addRiskToRuleset,
          groupList: {
            groupBy: (risk: RiskDto) => {
              return risk.riskType;
            },
            renderGroup: (params: AutocompleteRenderGroupParams) => {
              return (
                <li key={params.key}>
                  <GroupHeader>{`${params.group} Risks`}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              );
            },
          },
        }}
        massDeletion={{
          onMassDelete: onMassDelete,
          tooltipTitle: 'Remove selected',
          iconType: 'remove',
        }}
      />
      <CreateRiskModal
        open={currentOpenModal === 'create'}
        onClose={closeModal}
        onRiskCreate={onRiskCreate}
        allFunctions={allFunctions}
        riskLevels={riskLevels}
        allBusinessProcesses={allBusinessProcesses}
      />
      {modalRisk && (
        <UpdateRiskModal
          open={currentOpenModal === 'update'}
          onClose={closeModal}
          onRiskUpdate={onRiskUpdate}
          onRiskFinalize={onRiskFinalize}
          risk={modalRisk}
          loadFunctionsFromRisk={getRiskFunctionsFromRisk}
          allFunctions={allFunctions}
          riskLevels={riskLevels}
          allBusinessProcesses={allBusinessProcesses}
        />
      )}
      <CreateRiskFromTemplateModal
        open={currentOpenModal === 'createFromTemplate'}
        onClose={closeModal}
        onRiskCreateFromTemplate={onRiskCreateFromTemplate}
        loadFunctionsFromRisk={getRiskFunctionsFromRisk}
        allRisks={allRisks}
        allFunctions={allFunctions}
        riskLevels={riskLevels}
        allBusinessProcesses={allBusinessProcesses}
      />
      {modalRisk && (
        <CreateRiskNewVersionModal
          open={currentOpenModal === 'createNewVersion'}
          onClose={closeModal}
          risk={modalRisk}
          onRiskCreateNewVersion={onCreateNewRiskVersion}
          loadFunctionsFromRisk={getRiskFunctionsFromRisk}
          allFunctions={allFunctions}
          riskLevels={riskLevels}
          allBusinessProcesses={allBusinessProcesses}
          onRiskRelease={onRiskRelease}
        />
      )}
    </Box>
  );
};

export default RulesetRisksComponent;
