import { Paper, Stack } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';

import { ContentTable } from '../../components/content-table';
import { confirmTrashRiskFunctions } from '../../components/functions/delete-dialogs';
import {
  RiskFunctionContentTableExtraCells,
  RiskFunctionContentTableExtraHeads,
  RiskFunctionsOrderBy,
  sortRiskFunctions,
} from '../../components/functions/function-table-head';
import RestoreModal from '../../components/restore-modal';
import TrashModal from '../../components/trash-modal';
import { CreateFromTemplateRiskFunctionDto } from '../../services/dto/functions/create-from-existing-function.dto';
import { CreateRiskFunctionDto } from '../../services/dto/functions/create-function.dto';
import { CreateNewVersionRiskFunctionDto } from '../../services/dto/functions/create-new-version-function.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { UpdateRiskFunctionDto } from '../../services/dto/functions/update-function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { useBusinessProcessService, useRiskFunctionService } from '../../services/hooks';
import { afterRestoreContent, afterTrashContent } from '../../shared/common';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';
import CreateRiskFunctionModal from './functions-modal/create';
import CreateRiskFunctionFromTemplateModal from './functions-modal/create-from-template';
import CreateRiskFunctionNewVersionModal from './functions-modal/create-new-version';
import UpdateRiskFunctionModal from './functions-modal/update';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FunctionsPageProps {}

// eslint-disable-next-line no-empty-pattern
const FunctionsPage: FunctionComponent<FunctionsPageProps> = ({ ...props }) => {
  const [riskFunctions, setRiskFunctions] = useState<RiskFunctionDto[] | null>(null);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [modalRiskFunction, setModalRiskFunction] = useState<RiskFunctionDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'createFromTemplate' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined
  >(undefined);
  const confirm = useConfirm();

  const businessProcessService = useBusinessProcessService();
  const riskFunctionService = useRiskFunctionService();

  const getRiskFunction = useCallback(
    async (id: string) => {
      const result = await riskFunctionService.getFunction(id);
      return result.data;
    },
    [riskFunctionService],
  );

  const fetchRiskFunctions = useCallback(async () => {
    const riskFunctions = await riskFunctionService.getFunctions();
    setRiskFunctions(riskFunctions.data);
    setTotalCount(riskFunctions.meta?.totalCount ?? riskFunctions.data.length);
    return riskFunctions.data;
  }, [riskFunctionService]);

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcesses = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcesses.data);
    return businessProcesses.data;
  }, [businessProcessService]);

  const onRiskFunctionCreated = useCallback(async (newRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => [newRiskFunction, ...(riskFunctions ?? [])]);
  }, []);

  const onNewRiskFunctionVersion = useCallback(
    async (newRiskFunction: RiskFunctionDto, origin: RiskFunctionDto, updateParents: Partial<RiskDto>[]) => {
      setRiskFunctions((riskFunctions) =>
        [newRiskFunction, ...(riskFunctions ?? [])].filter((riskFunction) => riskFunction.id != origin.id),
      );
    },
    [],
  );

  const onRiskFunctionUpdated = useCallback(async (id: string, updateRiskFunction: RiskFunctionDto) => {
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.map((riskFunction) => {
          if (riskFunction.id === id) {
            return { ...riskFunctions, ...updateRiskFunction };
          }
          return riskFunction;
        });
      }
      return null;
    });
  }, []);

  const onRiskFunctionDeleted = useCallback(
    (id: string) => {
      afterTrashContent(id, getRiskFunction, setRiskFunctions);
    },
    [getRiskFunction],
  );

  const onRiskFunctionRestored = useCallback(
    (id: string) => {
      afterRestoreContent(id, getRiskFunction, setRiskFunctions);
    },
    [getRiskFunction],
  );

  const onRiskFunctionsDeleted = useCallback(
    (riskFunctionIds: string[]) => {
      fetchRiskFunctions();
      // @NOTE(workaround): fetch all, don't know which got deleted (removed) or updated (status)
      /*
    setRiskFunctions((riskFunctions) => {
      if (riskFunctions) {
        return riskFunctions.filter(
          (riskFunction: RiskFunctionDto) => !riskFunctionIds.some((fId) => fId == riskFunction.id),
        );
      }
      return null;
    });
    */
    },
    [fetchRiskFunctions],
  );

  const openCreateRiskFunction = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openCreateFromTemplateRiskFunction = useCallback(() => {
    setModalRiskFunction(undefined);
    setCurrentOpenModal('createFromTemplate');
  }, []);

  const openEditRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const openTrashRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('trash');
  }, []);

  const openRestoreRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('restore');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRiskFunction(undefined);
  }, []);

  const onRiskFunctionCreate = useCallback(
    async (data: CreateRiskFunctionDto) => {
      const res = await riskFunctionService.createFunction(data);
      onRiskFunctionCreated(res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionCreated, riskFunctionService],
  );

  const onRiskFunctionCreateFromTemplate = useCallback(
    async (data: CreateFromTemplateRiskFunctionDto, origin: RiskFunctionDto) => {
      const res = await (() => {
        return riskFunctionService.createFromExistingFunction(origin.id, {
          name: data.name,
          displayName: data.displayName,
          description: data.description,
          keepReference: data.keepReference,
          businessProcessId: data.businessProcessId ?? undefined,
        });
      })();
      onRiskFunctionCreated(res.data);
      closeModal();
    },
    [closeModal, riskFunctionService, onRiskFunctionCreated],
  );

  const onRiskFunctionNewVersionCreate = useCallback(
    async (id: string, createNewVersionData: CreateNewVersionRiskFunctionDto, origin: RiskFunctionDto) => {
      const res = await riskFunctionService.createNewFunctionVersion(id, createNewVersionData);
      onNewRiskFunctionVersion(res.data, origin, res.meta.updateParents);
      closeModal();
    },
    [closeModal, onNewRiskFunctionVersion, riskFunctionService],
  );

  const onRiskFunctionUpdate = useCallback(
    async (id: string, data: UpdateRiskFunctionDto) => {
      const res = await riskFunctionService.updateFunction(id, {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        businessProcessId: data.businessProcessId ?? null,
      });
      onRiskFunctionUpdated(id, res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionUpdated, riskFunctionService],
  );

  const onRiskFunctionFinalize = useCallback(
    async (riskFunctionId: string, data: UpdateRiskFunctionDto) => {
      try {
        const res = await riskFunctionService.updateFunction(riskFunctionId, data);
        onRiskFunctionUpdated(riskFunctionId, res.data);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [riskFunctionService, onRiskFunctionUpdated],
  );

  const onRiskFunctionRelease = useCallback(
    async (riskFunctionId: string, data: UpdateRiskFunctionDto) => {
      try {
        const res = await riskFunctionService.updateFunction(riskFunctionId, data);
        onRiskFunctionUpdated(riskFunctionId, res.data);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [riskFunctionService, onRiskFunctionUpdated],
  );

  const trashRiskFunction = useCallback(
    async (id: string) => {
      await riskFunctionService.trashFunction(id);
      onRiskFunctionDeleted(id);
    },
    [onRiskFunctionDeleted, riskFunctionService],
  );

  const trashRiskFunctions = useCallback(
    async (functionIds: string[]) => {
      await riskFunctionService.trashFunctions(functionIds);
      onRiskFunctionsDeleted(functionIds);
    },
    [onRiskFunctionsDeleted, riskFunctionService],
  );

  const restoreRiskFunction = useCallback(
    async (id: string) => {
      await riskFunctionService.restoreFunction(id);
      onRiskFunctionRestored(id);
    },
    [onRiskFunctionRestored, riskFunctionService],
  );

  const onRiskFunctionTrash = useCallback(
    (id: string) => {
      trashRiskFunction(id).then(() => {
        closeModal();
      });
    },
    [closeModal, trashRiskFunction],
  );

  const onRiskFunctionRestore = useCallback(
    (id: string) => {
      restoreRiskFunction(id).then(() => {
        closeModal();
      });
    },
    [closeModal, restoreRiskFunction],
  );

  const loadFunctionWithValues = useCallback(
    async (riskFunctionId: string) => {
      const ret = await riskFunctionService.getFunction(riskFunctionId);
      return ret.data;
    },
    [riskFunctionService],
  );

  const getRisksFromRiskFunction = useCallback(
    async (id: string) => {
      const result = await riskFunctionService.getRisksFromFunction(id);
      return result.data;
    },
    [riskFunctionService],
  );

  const onMassTrash = useCallback(
    async (riskFunctionIds: string[]) => {
      //@TODO: Hier werden noch die alten Delete-Dialoge benutzt. Diese sollten mit neuen ersetzt werden.
      const { dialog } = await confirmTrashRiskFunctions(confirm, riskFunctionIds);
      return dialog
        .then(() => {
          trashRiskFunctions(riskFunctionIds);
          return true;
        })
        .catch(() => {
          return false;
          /* ... */
        });
    },
    [confirm, trashRiskFunctions],
  );

  const getRiskFunctionUrl = useCallback((riskFunction: RiskFunctionDto) => {
    return `/functions/${riskFunction.id}`;
  }, []);

  useEffect(() => {
    fetchRiskFunctions();
    fetchBusinessProcesses();
  }, [fetchBusinessProcesses, fetchRiskFunctions]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <Stack spacing={ContentPageStackSpacing}>
          <ContentTable<RiskFunctionDto, RiskFunctionsOrderBy>
            tableToolbarTitle='Functions'
            totalCount={totalCount}
            rows={riskFunctions}
            sortTable={sortRiskFunctions}
            openCreate={openCreateRiskFunction}
            createButtonTitle='Create Function'
            createFromTemplateButtonTitle='Create Function From Template'
            extraCells={RiskFunctionContentTableExtraCells}
            extraHeads={RiskFunctionContentTableExtraHeads}
            openCreateFromTemplate={openCreateFromTemplateRiskFunction}
            openCreateNewVersion={openCreateNewVersionRiskFunction}
            openEdit={openEditRiskFunction}
            onTrash={openTrashRiskFunction}
            onRestore={openRestoreRiskFunction}
            getDetailsUrl={getRiskFunctionUrl}
            defaultOrderBy='name'
            massTrash={{
              onMassTrash: onMassTrash,
              tooltipTitle: 'Delete selected',
              iconType: 'delete',
            }}
          />
          <CreateRiskFunctionModal
            open={currentOpenModal === 'create'}
            onClose={closeModal}
            onRiskFunctionCreate={onRiskFunctionCreate}
            allBusinessProcesses={allBusinessProcesses ?? []}
          />
          <CreateRiskFunctionFromTemplateModal
            open={currentOpenModal === 'createFromTemplate'}
            allBusinessProcesses={allBusinessProcesses ?? []}
            onClose={closeModal}
            onRiskFunctionCreateFromTemplate={onRiskFunctionCreateFromTemplate}
            allRiskFunctions={riskFunctions ?? []}
          />
          {modalRiskFunction && (
            <UpdateRiskFunctionModal
              allBusinessProcesses={allBusinessProcesses ?? []}
              open={currentOpenModal === 'update'}
              onClose={closeModal}
              onRiskFunctionUpdate={onRiskFunctionUpdate}
              onRiskFunctionFinalize={onRiskFunctionFinalize}
              loadFunctionWithValues={loadFunctionWithValues}
              riskFunction={modalRiskFunction}
            />
          )}
          {modalRiskFunction && (
            <CreateRiskFunctionNewVersionModal
              allBusinessProcesses={allBusinessProcesses ?? []}
              open={currentOpenModal === 'createNewVersion'}
              onClose={closeModal}
              riskFunction={modalRiskFunction}
              onRiskFunctionCreateNewVersion={onRiskFunctionNewVersionCreate}
              onRiskFunctionRelease={onRiskFunctionRelease}
              loadFunctionWithValues={loadFunctionWithValues}
            />
          )}
          {modalRiskFunction && (
            <TrashModal<RiskFunctionDto, RiskDto>
              title='Trash Function'
              contentName='Function'
              parentsName='Risks'
              contentId={modalRiskFunction.id}
              open={currentOpenModal === 'trash'}
              onClose={closeModal}
              onTrash={onRiskFunctionTrash}
              getContent={getRiskFunction}
              getParents={getRisksFromRiskFunction}
            />
          )}
          {modalRiskFunction && (
            <RestoreModal<RiskFunctionDto, RiskDto>
              title='Restore Function'
              contentName='Function'
              parentsName='Risks'
              contentId={modalRiskFunction.id}
              open={currentOpenModal === 'restore'}
              onClose={closeModal}
              onRestore={onRiskFunctionRestore}
              getContent={getRiskFunction}
            />
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default FunctionsPage;
