import { BusinessProcessDto } from './dto/business-process/business-process.dto';
import { CreateBusinessProcessDto } from './dto/business-process/create-business-process.dto';
import { UpdateBusinessProcessDto } from './dto/business-process/update-business-process.dto';
import { HttpService } from './http.service';

export class BusinessProcessService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('business-processes')) {}

  async getBusinessProcesses(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<BusinessProcessDto[]>(undefined, params);
    return response.data;
  }

  async getBusinessProcess(businessProcessId: string) {
    const response = await this.httpService.get<BusinessProcessDto>(businessProcessId);
    return response.data;
  }

  async createBusinessProcess(body: CreateBusinessProcessDto) {
    const response = await this.httpService.post<BusinessProcessDto>('', body);
    return response.data;
  }

  async updateBusinessProcess(businessProcessId: string, body: UpdateBusinessProcessDto) {
    const response = await this.httpService.patch<BusinessProcessDto>(businessProcessId, body);
    return response.data;
  }

  async deleteBusinessProcess(businessProcessId: string) {
    const response = await this.httpService.delete(businessProcessId);
    return response.data;
  }
}
