import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';

import { ContentTable } from '../../components/content-table';
import { confirmDeleteContents } from './delete-dialogs';
import {
  TrashContentDto,
  TrashContentOrderBy,
  TrashContentTableExtraCells,
  TrashContentTableExtraHeads,
  sortTrash,
} from './trash-table-head';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TrashTableProps {
  content: TrashContentDto[] | null;
  totalCount: number;
  massDelete: (selectedIds: string[]) => void;
  openDeleteContent: (content: TrashContentDto) => void;
  openRestoreContent: (content: TrashContentDto) => void;
  getDetailsUrl?: (content: TrashContentDto) => string;
}

export const TrashTable: FunctionComponent<TrashTableProps> = ({
  content,
  totalCount,
  massDelete,
  openDeleteContent,
  openRestoreContent,
  getDetailsUrl,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const onMassDelete = useCallback(
    async (ids: string[]) => {
      const { dialog } = confirmDeleteContents(confirm, ids);
      return dialog
        .then(() => {
          massDelete(ids);
          return true;
        })
        .catch(() => {
          return false;
        });
      return false;
    },
    [confirm, massDelete],
  );

  return (
    <ContentTable<TrashContentDto, TrashContentOrderBy>
      tableToolbarTitle='Content'
      totalCount={totalCount}
      rows={content}
      sortTable={sortTrash}
      defaultOrderBy='name'
      extraCells={TrashContentTableExtraCells}
      extraHeads={TrashContentTableExtraHeads}
      onDelete={openDeleteContent}
      onRestore={openRestoreContent}
      massDeletion={{
        onMassDelete: onMassDelete,
        tooltipTitle: 'Delete selected',
        iconType: 'delete',
      }}
      trash={true}
      showHeaders={['name', 'displayName', 'type', 'trashedAt', 'author']}
      disableMultiSelect={true}
      getDetailsUrl={getDetailsUrl}
    />
  );
};
