import { TableOrder, sortTableData } from '../../components/content-table-head';
import { TableBodyMapper } from '../../components/content-table/content-table-body-cell';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';

export type RulesetsOrder = TableOrder;
export type RulesetsOrderBy = 'name' | 'displayName' | 'finalizedAt' | 'author' | 'status';

export type RulesetsRisksChildrenOrderBy =
  | 'name'
  | 'displayName'
  | 'riskType'
  | 'riskLevel'
  | 'businessProcess'
  | 'status';

export function sortRulesets(
  order: RulesetsOrder,
  orderBy: RulesetsOrderBy,
  rulesets: RulesetDto[],
  rowMapper: TableBodyMapper<RulesetDto, RulesetsOrderBy>,
) {
  return sortTableData(order, orderBy, rulesets, rowMapper);
}
