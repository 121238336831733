import { faFileCircleXmark } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import React from 'react';
import { xcpColors } from 'src/styles/main-theme';

import { RiskOwnersRisksReportDto } from '@/services/dto/reports/risk-owners-risks-report.dto';

import UserAvatar from '../../../components/avatar';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskOwnerRiskCountListProps {
  riskOwnersRisksReportData: RiskOwnersRisksReportDto[] | undefined;
  height?: string | number;
}

const RiskOwnerRiskCountList = ({ riskOwnersRisksReportData, height }: RiskOwnerRiskCountListProps) => {
  const loadingState = useMemo(() => {
    if (riskOwnersRisksReportData === undefined) {
      return 'loading';
    }
    if (riskOwnersRisksReportData.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [riskOwnersRisksReportData]);

  return (
    <Stack>
      <Typography sx={{ color: xcpColors.grey[1], flex: '1 1 100%', my: 1, ml: 2 }} variant='h6' component='div'>
        Risks / Risk Owner
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box
          sx={{ height: height, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}
        >
          {loadingState === 'loading' && (
            <List sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
              {[1, 2, 3, 4, 5].map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <Box sx={{ pr: 3, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          <Skeleton variant='text' height={25} />
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < 4 && <Divider sx={{ py: 0.5 }} component='li' />}
                </React.Fragment>
              ))}
            </List>
          )}
          {loadingState === 'empty' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography align='center'>No Data</Typography>
                <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
              </Stack>
            </Box>
          )}
          {loadingState === 'loaded' && (
            <List sx={{ height: '100%', width: '100%', flexDirection: 'column' }}>
              {riskOwnersRisksReportData?.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem key={index} secondaryAction={item.riskCount}>
                    <ListItemAvatar>
                      <UserAvatar username={item.riskOwner} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {item.riskOwner}
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < riskOwnersRisksReportData.length - 1 && <Divider variant='inset' component='li' />}
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default RiskOwnerRiskCountList;
