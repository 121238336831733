import { InputLabel, MenuItem, Stack, TextField } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';
import { RiskLevelDto } from '@/services/dto/risk-levels/risk-level.dto';

import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { RiskType } from '../../services/models/risk-type';
import ContentHeader from '../content-header';
import { BaseContentType } from '../content-table/content-table-body-cell';
import RiskLevelRating from '../risks/risk-level-rating';
import { confirmFinalizeRisk, confirmReleaseRisk } from './dialogs';

export interface EditRiskFormData {
  name: string;
  displayName: string;
  description: string;
  riskType: RiskType;
  criticality: number;
  riskOwner: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EditRiskFormProps {
  risk: RiskDto;
  riskVersionHistory: RiskDto[];
  riskFunctions: RiskFunctionDto[];
  rulesets?: RulesetDto[];
  allBusinessProcesses: BusinessProcessDto[];
  riskLevels: RiskLevelDto[];
  onSave: (data: FieldValues) => void;
  onConfirmFinalize: (risk: RiskDto, data: FieldValues) => void;
  onConfirmRelease: (risk: RiskDto, data: FieldValues) => void;
}

const EditRiskForm: FunctionComponent<EditRiskFormProps> = ({
  risk,
  riskFunctions,
  riskVersionHistory,
  rulesets,
  allBusinessProcesses,
  riskLevels,
  onSave,
  onConfirmFinalize,
  onConfirmRelease,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const getRiskDetailsUrl = (risk: BaseContentType) => {
    return `/risks/${risk.id}`;
  };
  const getRulesetDetailsUrl = (ruleset: BaseContentType) => {
    return `/rulesets/${ruleset.id}`;
  };

  const onFinalize = useCallback(
    async (data: FieldValues) => {
      const { canFinalize, dialog } = confirmFinalizeRisk(confirm, risk, riskFunctions, data);
      dialog
        .then(() => {
          if (canFinalize) {
            onConfirmFinalize(risk, {
              ...data,
              status: ContentTypeStatus.Final,
            });
          }
        })
        //@TODO: catch error and throw it (alert or popup)
        .catch((e) => {
          console.error(e);
          /* ... */
        });
    },
    [confirm, onConfirmFinalize, risk, riskFunctions],
  );

  const onRelease = useCallback(
    async (data: FieldValues) => {
      const { canRelease, dialog } = confirmReleaseRisk(confirm, risk, riskFunctions, data);
      dialog
        .then(() => {
          if (canRelease) {
            onConfirmRelease(risk, {
              status: ContentTypeStatus.Released,
            });
          }
        })
        //@TODO: catch error and throw it (alert or popup)
        .catch((e) => {
          console.error(e);
          /* ... */
        });
    },
    [confirm, onConfirmRelease, risk, riskFunctions],
  );

  const registerOptions = {
    riskType: { required: true },
    criticality: { required: true },
    riskOwner: undefined,
  };

  const extendRiskEditForm = {
    extendForm: (
      register: UseFormRegister<EditRiskFormData | Record<string, any>>,
      control: Control<EditRiskFormData | Record<string, any>>,
      errors: FieldErrors<EditRiskFormData | Record<string, any>>,
      setValue: UseFormSetValue<EditRiskFormData | Record<string, any>>,
    ) => {
      return [
        {
          xs: 4,
          field: (
            <Controller
              name='riskType'
              control={control}
              rules={registerOptions.riskType}
              defaultValue={risk.riskType}
              render={({ field }) => (
                <TextField
                  key={'riskType'}
                  margin='dense'
                  select
                  label='Type'
                  fullWidth
                  InputProps={{
                    readOnly: risk.status !== ContentTypeStatus.Draft,
                  }}
                  error={!!errors.riskType}
                  helperText={errors.riskType?.message?.toString()}
                  {...field}
                >
                  <MenuItem key={'CA'} value={'CA'}>
                    CA
                  </MenuItem>
                  <MenuItem key={'SOD'} value={'SOD'}>
                    SOD
                  </MenuItem>
                </TextField>
              )}
            />
          ),
        },
        {
          xs: 4,
          field: (
            <Controller
              name='riskOwner'
              control={control}
              rules={registerOptions.riskOwner}
              defaultValue={risk.riskOwner}
              render={({ field }) => (
                <TextField
                  key={'riskOwner'}
                  margin='dense'
                  label='Risk Owner'
                  fullWidth
                  InputProps={{
                    readOnly: risk.status !== ContentTypeStatus.Draft,
                  }}
                  error={!!errors.criticality}
                  helperText={errors.riskOwner?.message?.toString()}
                  {...field}
                />
              )}
            />
          ),
        },
        {
          xs: 4,
          field: (
            <Controller
              name='criticality'
              control={control}
              rules={registerOptions.criticality}
              defaultValue={risk.criticality}
              render={({ field }) => (
                // <TextField
                //   key={'criticality'}
                //   margin='dense'
                //   label='Risk Level'
                //   fullWidth
                //   type='number'
                //   InputProps={{
                //     readOnly: risk.status !== ContentTypeStatus.Draft,
                //   }}
                //   error={!!errors.criticality}
                //   helperText={errors.criticality?.message?.toString()}
                //   {...field}
                // />
                <Stack sx={{ display: 'flex' }}>
                  <InputLabel shrink id='risk-level-label'>
                    Risk Level
                  </InputLabel>
                  <RiskLevelRating
                    sx={{ position: 'relative', bottom: '11px' }}
                    centerText
                    height={15}
                    name='risk-level'
                    riskLevels={riskLevels}
                    value={field.value}
                    onChange={(event, criticality) => {
                      setValue('criticality', criticality);
                    }}
                  />
                </Stack>
              )}
            />
          ),
        },
      ];
    },
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
      riskType: 'CA',
      criticality: 0,
      riskOwner: '',
    } as EditRiskFormData,
    onInit: (
      content: RiskDto,
      register: UseFormRegister<EditRiskFormData | Record<string, any>>,
      setValue: UseFormSetValue<EditRiskFormData | Record<string, any>>,
    ) => {
      setValue('riskType', content.riskType);
      setValue('criticality', content.criticality);
      setValue('riskOwner', content.riskOwner);
    },
  };

  return (
    <ContentHeader<RiskDto, RulesetDto, EditRiskFormData>
      title='Risk Header'
      content={risk}
      versionHistory={riskVersionHistory}
      occurrences={rulesets}
      allBusinessProcesses={allBusinessProcesses}
      onSave={onSave}
      onFinalize={onFinalize}
      onRelease={onRelease}
      getVersionHistoryDetailsUrl={getRiskDetailsUrl}
      getOccurrenceDetailsUrl={getRulesetDetailsUrl}
      extend={extendRiskEditForm}
    />
  );
};

export default EditRiskForm;
