import { faFileZipper, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack } from '@mui/material';

import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';
import { TableAutocompleteFieldWidth } from '../../../styles/content-tables';

interface ExportIagControlsProps {
  selectedRulesets: RulesetDto[];
}

export function ExportIagControls({
  selectedRulesets,
  /*...props*/
}: ExportIagControlsProps) {
  return (
    <Stack spacing={2} sx={{ width: 2.5 * TableAutocompleteFieldWidth }}>
      <Button
        variant={'contained'}
        color='success'
        // startIcon={}
        disabled={!selectedRulesets.length}
        // onClick={onZipExport}
        startIcon={<FontAwesomeIcon icon={faFileZipper} />}
      >
        Export all files
      </Button>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          // onClick={onCattExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Example
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          // onClick={onCadExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Example
        </Button>
      </Stack>
      <Stack direction='row' spacing={2}>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          // onClick={onCavttExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function Example
        </Button>
        <Button
          fullWidth
          variant={'contained'}
          color='info'
          // startIcon={}
          disabled={!selectedRulesets.length}
          // onClick={onCavdExport}
          startIcon={<FontAwesomeIcon icon={faCloudArrowDown} />}
        >
          Export Function Example
        </Button>
      </Stack>
    </Stack>
  );
}
