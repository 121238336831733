import { BaseContentType } from '@/components/content-table/content-table-body-cell';

export function searchStarFilter(
  searchInput: string,
  fieldValues: string[],
  filter: 'contains' | 'notContains' | string = 'contains',
) {
  if (!searchInput || fieldValues.length === 0) {
    return true;
  }

  if (
    searchInput.startsWith('*') &&
    !searchInput.startsWith('\\*') &&
    searchInput.endsWith('*') &&
    !searchInput.endsWith('\\*')
  ) {
    return fieldValues.some((value) => !value || value.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()));
  } else if (searchInput.startsWith('*') && !searchInput.startsWith('\\*')) {
    const filterValue = searchInput.replaceAll('*', '');
    const filterRegex = new RegExp(`^.*(${filterValue})$`, 'i');
    return fieldValues.some((value) => !value || filterRegex.test(value));
  } else if (searchInput.endsWith('*') && !searchInput.endsWith('\\*')) {
    const filterValue = searchInput.replaceAll('*', '');
    const filterRegex = new RegExp(`^(${filterValue}).*$`, 'i');
    return fieldValues.some((value) => !value || filterRegex.test(value));
  }

  return fieldValues.some((value) => !value || value.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()));
}

export function flatten<T>(a: T[][]): T[] {
  return a.reduce((accumulator, value) => accumulator.concat(value), []);
}

export function filterUnique<T>(arr: T[], equalsPred: (a: T, b: T) => boolean) {
  return arr.filter((value, index, self) => {
    return self.findIndex((v) => equalsPred(v, value)) === index;
  });
}

export function roundToHundred(arr: number[]) {
  let acc = 0;

  return arr.map((value) => {
    const roundedCur = Math.round(value);
    const currentAcc = acc;
    if (acc === 0) {
      acc += value;
      return roundedCur;
    }
    acc += value;
    return Math.round(acc) - Math.round(currentAcc);
  });
}

export function sortContentArrayByIds(arr: BaseContentType[]) {
  return arr.sort((n1, n2) => {
    if (n1.id > n2.id) {
      return 1;
    }

    if (n1.id < n2.id) {
      return -1;
    }

    return 0;
  });
}

export function compareContentArray(content1: BaseContentType[], content2: BaseContentType[]) {
  //@NOTE: cloning is needed here in order to not manipulate the original children arrays
  const cloneContent1 = [...content1];
  const cloneContent2 = [...content2];

  const sortedContent1 = sortContentArrayByIds(cloneContent1);
  const sortedContent2 = sortContentArrayByIds(cloneContent2);

  return (
    sortedContent1.length == sortedContent2.length &&
    sortedContent1.every((element: BaseContentType, index: number) => {
      return element.id === sortedContent2[index].id;
    })
  );
}
