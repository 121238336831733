import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Chip, Paper, Stack, TextField, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { dialogActionButtonOptions } from 'src/components/dialog-action-buttons-options';
import { BusinessProcessDto } from 'src/services/dto/business-process/business-process.dto';
import { useBusinessProcessService } from 'src/services/hooks';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from 'src/styles/pages';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BusinessProcessesProps {}

const BusinessProcessesPage = (props: BusinessProcessesProps) => {
  const confirm = useConfirm();
  const [businessProcesses, setBusinessProcesses] = useState<BusinessProcessDto[] | null>(null);
  const [editBusinessProcessId, setEditBusinessProcessId] = useState<string | undefined>(undefined);
  const businessProcessService = useBusinessProcessService();
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      displayName: '',
    },
  });

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcessesResult = await businessProcessService.getBusinessProcesses();
    setBusinessProcesses(businessProcessesResult.data);
  }, [businessProcessService]);

  const isEditMode = useMemo(() => {
    return !!editBusinessProcessId;
  }, [editBusinessProcessId]);

  const submitButtonLabel = useMemo(() => (isEditMode ? 'Save' : 'Add'), [isEditMode]);

  const clearForm = useCallback(() => {
    setValue('name', '');
    setValue('displayName', '');
    setEditBusinessProcessId(undefined);
    clearErrors();
  }, [clearErrors, setValue]);

  const selectBusinessProcessId = useCallback(
    (businessProcess: BusinessProcessDto) => {
      setValue('name', businessProcess.name);
      setValue('displayName', businessProcess.displayName);
      setEditBusinessProcessId(businessProcess.id);
    },
    [setValue],
  );

  const deleteBusinessProcess = useCallback(
    (businessProcess: BusinessProcessDto) => {
      confirm({
        title: `Are you sure you want to delete ${businessProcess.name}?`,
        ...dialogActionButtonOptions,
      })
        .then(async () => {
          const result = await businessProcessService.deleteBusinessProcess(businessProcess.id);
          setBusinessProcesses((businessProcesses) => {
            return businessProcesses?.filter((bp) => bp.id !== businessProcess.id) ?? null;
          });
          return true;
        })
        .catch(() => {
          return false;
          /* ... */
        });
    },
    [confirm, businessProcessService],
  );

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      if (isEditMode && editBusinessProcessId) {
        const result = await businessProcessService.updateBusinessProcess(editBusinessProcessId, {
          name: data.name,
          displayName: data.displayName,
        });
        setBusinessProcesses((businessProcesses) => {
          return (
            businessProcesses?.map((businessProcess) => {
              if (businessProcess.id === result.data.id) {
                businessProcess.name = result.data.name;
                businessProcess.displayName = result.data.displayName;
              }
              return businessProcess;
            }) ?? null
          );
        });
        clearForm();
        return;
      }
      const result = await businessProcessService.createBusinessProcess({
        name: data.name,
        displayName: data.displayName,
      });
      setBusinessProcesses((businessProcesses) => {
        return businessProcesses ? [...businessProcesses, result.data] : [result.data];
      });
      clearForm();
    },
    [clearForm, editBusinessProcessId, isEditMode, businessProcessService],
  );

  useEffect(() => {
    fetchBusinessProcesses();
  }, [fetchBusinessProcesses]);

  const registerOptions = useMemo(() => {
    return {
      name: {
        required: 'ID is required.',
        maxLength: 3,
        validate: (value: string) => {
          const existBusinessProcess = businessProcesses?.find((businessProcess) => {
            if (isEditMode && editBusinessProcessId === businessProcess.id) {
              return false;
            }
            return businessProcess.name == value;
          });
          if (existBusinessProcess) {
            return 'Business Process already defined. Please use another ID.';
          }
          return true;
        },
      },
      displayName: {
        required: 'Name is required.',
        validate: (value: string) => {
          const existRisklevel = businessProcesses?.find((businessProcess) => {
            if (isEditMode && editBusinessProcessId === businessProcess.id) {
              return false;
            }
            return businessProcess.displayName === value;
          });
          if (existRisklevel) {
            return 'Business Process already defined. Please use another Name.';
          }
          return true;
        },
      },
    };
  }, [editBusinessProcessId, isEditMode, businessProcesses]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <Stack sx={{ my: 2 }}>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography sx={{ ml: 2 }} variant={'h6'} id='pageTitle' component='div'>
              Business Processes
            </Typography>
          </Stack>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onReset={() => {
              clearForm();
            }}
          >
            <Stack sx={{ ml: 2, mt: 4, mb: 2 }} spacing={1} direction={'row'}>
              <Controller
                name='name'
                control={control}
                rules={registerOptions.name}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 3 }}
                    size='small'
                    margin='dense'
                    placeholder='ID*'
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message?.toString() : ' '}
                    {...field}
                  />
                )}
              />
              <Controller
                name='displayName'
                control={control}
                rules={registerOptions.displayName}
                render={({ field }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 30 }}
                    size='small'
                    margin='dense'
                    placeholder='Name*'
                    error={!!errors.displayName}
                    helperText={errors.displayName ? errors.displayName.message?.toString() : ' '}
                    {...field}
                  />
                )}
              />
              <Box>
                <Button type='submit' variant='contained' color='success' endIcon={<FontAwesomeIcon icon={faCheck} />}>
                  {submitButtonLabel}
                </Button>
              </Box>
              <Box>
                <Button type='reset' variant='contained' color='error' endIcon={<FontAwesomeIcon icon={faXmark} />}>
                  Reset
                </Button>
              </Box>
            </Stack>
          </form>
          <Stack sx={{ ml: 2 }} spacing={1} direction={'row'}>
            {businessProcesses &&
              businessProcesses.length > 0 &&
              businessProcesses.map((businessProcess) => {
                return (
                  <Chip
                    key={businessProcess.id}
                    label={businessProcess.name}
                    onClick={() => {
                      selectBusinessProcessId(businessProcess);
                    }}
                    onDelete={() => {
                      deleteBusinessProcess(businessProcess);
                    }}
                    sx={{
                      width: 80,
                      // backgroundColor: riskLevel.color,
                      // color: riskLevel.color ? theme.palette.getContrastText(riskLevel.color) : undefined,
                    }}
                  />
                );
              })}
            {(!businessProcesses || businessProcesses.length <= 0) && <span>No Business Processes</span>}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default BusinessProcessesPage;
