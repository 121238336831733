import { Autocomplete, Grid, InputLabel, MenuItem, Stack, TextField } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { confirmFinalizeRisk } from 'src/components/edit-risk-header/dialogs';
import { ContentTypeStatus } from 'src/services/models/content-type-status';

import { ContentTableModal } from '../../../components/content-table-modal';
import RiskLevelRating from '../../../components/risks/risk-level-rating';
import { BusinessProcessDto } from '../../../services/dto/business-process/business-process.dto';
import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { RiskLevelDto } from '../../../services/dto/risk-levels/risk-level.dto';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { UpdateRiskDto } from '../../../services/dto/risks/update-risk.dto';
import { RiskType } from '../../../services/models/risk-type';

interface UpdateRiskFormData {
  name: string;
  displayName: string;
  riskType: RiskType;
  riskOwner: string;
  criticality: number;
  businessProcessId: string;
  description: string;
}

interface RiskModalProps {
  open: boolean;
  onClose: () => void;
  risk: RiskDto;

  allFunctions: RiskFunctionDto[];
  riskLevels: RiskLevelDto[];
  allBusinessProcesses: BusinessProcessDto[];
  loadFunctionsFromRisk: (risk: RiskDto) => Promise<RiskFunctionDto[]>;
  onRiskUpdate: (riskId: string, data: UpdateRiskDto) => void;
  onRiskFinalize: (riskId: string, data: UpdateRiskDto) => void;
}

const UpdateRiskModal: FunctionComponent<RiskModalProps> = ({
  open,
  onClose,
  risk,
  onRiskUpdate,
  onRiskFinalize,
  allFunctions,
  riskLevels,
  allBusinessProcesses,
  loadFunctionsFromRisk,
  ...props
}) => {
  const [selectedBusinessProcess, setSelectedBusinessProcess] = useState<BusinessProcessDto | null>(null);
  const [newBusinessProcessName, setNewBusinessProcessName] = useState('');

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<UpdateRiskFormData>({
    defaultValues: {
      name: '',
      displayName: '',
      description: '',
      riskType: RiskType.CriticalAuthorization,
      criticality: 0,
      riskOwner: '',
      businessProcessId: '',
    },
  });

  const confirm = useConfirm();

  const onFinalize = useCallback(
    async (data: FieldValues, functionIds?: string[]) => {
      const { canFinalize, dialog } = confirmFinalizeRisk(confirm, risk, await loadFunctionsFromRisk(risk), data);
      dialog
        .then(() => {
          if (canFinalize) {
            onRiskFinalize(risk.id, {
              name: data.name,
              displayName: data.displayName,
              description: data.description,
              riskType: data.riskType,
              criticality: data.criticality,
              riskOwner: data.riskOwner,
              businessProcessId: data.businessProcessId || null,
              riskFunctionIds: functionIds,
              status: ContentTypeStatus.Final,
            });
          }
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, onRiskFinalize, risk, loadFunctionsFromRisk],
  );

  const onUpdate = useCallback(
    async (data: FieldValues, functionIds?: string[]) => {
      onRiskUpdate(risk.id, {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        riskType: data.riskType,
        criticality: data.criticality,
        riskOwner: data.riskOwner,
        businessProcessId: data.businessProcessId || null,
        riskFunctionIds: functionIds,
      });
    },
    [onRiskUpdate, risk.id],
  );

  const onAutocompleteChange = useCallback(
    (_: any, newSelectedBusinessProcess: BusinessProcessDto | null) => {
      setSelectedBusinessProcess(newSelectedBusinessProcess);
      setValue('businessProcessId', newSelectedBusinessProcess?.id ?? '');
    },
    [setSelectedBusinessProcess, setValue],
  );

  useEffect(() => {
    setValue('name', risk.name);
    setValue('displayName', risk.displayName);
    setValue('description', risk.description);
    setValue('riskType', risk.riskType);
    setValue('criticality', risk.criticality);
    setValue('riskOwner', risk.riskOwner);

    setValue('businessProcessId', risk.businessProcess?.id ?? '');
    setSelectedBusinessProcess(allBusinessProcesses.find((bp) => bp.id === risk.businessProcess?.id) ?? null);
  }, [
    allBusinessProcesses,
    risk.businessProcess?.id,
    risk.criticality,
    risk.description,
    risk.displayName,
    risk.name,
    risk.riskOwner,
    risk.riskType,
    setValue,
  ]);

  const registerOptions = {
    riskType: { required: true },
    criticality: { required: true },
    riskOwner: undefined,
    businessProcessId: undefined,
  };

  return (
    <ContentTableModal<RiskDto, UpdateRiskFormData, RiskFunctionDto>
      type='Update'
      title='Update Risk'
      open={open}
      onClose={onClose}
      update={{
        content: risk,
        onUpdate,
        onFinalize,
      }}
      form={{
        register,
        setValue,
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors },
      }}
      contentChildren={{
        title: 'Functions',
        loadChildren: loadFunctionsFromRisk,
        allChildren: allFunctions,
        addFieldLabel: 'Add Functions to Risk',
      }}
    >
      <Grid item sm={4}>
        <Controller
          name='riskType'
          control={control}
          rules={registerOptions.riskType}
          render={({ field }) => (
            <TextField
              key={'riskType'}
              margin='dense'
              select
              label='Type'
              fullWidth
              error={!!errors.riskType}
              helperText={errors.riskType?.message?.toString()}
              {...field}
            >
              <MenuItem key={'CA'} value={'CA'}>
                CA
              </MenuItem>
              <MenuItem key={'SOD'} value={'SOD'}>
                SOD
              </MenuItem>
            </TextField>
          )}
        />
      </Grid>
      <Grid item sm={4}>
        <Controller
          name='riskOwner'
          control={control}
          rules={registerOptions.riskOwner}
          render={({ field }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              key={'riskOwner'}
              margin='dense'
              label='Risk Owner'
              fullWidth
              error={!!errors.criticality}
              helperText={errors.riskOwner?.message?.toString()}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item sm={4}>
        <Controller
          name='criticality'
          control={control}
          rules={registerOptions.criticality}
          render={({ field }) => (
            <Stack sx={{ display: 'flex' }}>
              <InputLabel shrink id='risk-level-label'>
                Risk Level
              </InputLabel>
              <RiskLevelRating
                sx={{ position: 'relative', bottom: '11px' }}
                centerText
                height={15}
                name='risk-level'
                riskLevels={riskLevels}
                value={field.value}
                onChange={(event, criticality) => {
                  setValue('criticality', criticality ?? 0);
                }}
              />
            </Stack>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name='businessProcessId'
          control={control}
          rules={registerOptions.businessProcessId}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={selectedBusinessProcess}
              onChange={onAutocompleteChange}
              inputValue={newBusinessProcessName}
              onInputChange={(_, newBusinessProcessName) => {
                setNewBusinessProcessName(newBusinessProcessName);
              }}
              options={allBusinessProcesses}
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.name} - ${option.displayName}`}
              renderInput={(params) => (
                <TextField {...params} margin='dense' label='Business Process' InputLabelProps={{ shrink: true }} />
              )}
            />
          )}
        />
      </Grid>
    </ContentTableModal>
  );
};

export default UpdateRiskModal;
