import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ChildrenBaseContentOrderBy, ChildrenBaseContentType } from '../children-table/children-table-body-cell';
import { TableHeadCell } from '../content-table-head/content-table-header-filter';
import StatusBadge from '../status-badge';
import {
  BaseContentOrderBy,
  BaseContentType,
  TableBodyCell,
  TableBodyCellMapper,
  TableBodyMapper,
} from './content-table-body-cell';

export function useDefaultContentTableRowMapper<
  Row extends BaseContentType | ChildrenBaseContentType,
  OrderBy extends Partial<BaseContentOrderBy | keyof Row | number | symbol | ChildrenBaseContentOrderBy>,
>({ trash, getNameLinkPath }: { trash?: boolean; getNameLinkPath?: (row: Row) => string }) {
  const rowMapper = useMemo(() => {
    const ret: Partial<Record<OrderBy, TableBodyCellMapper<Row>>> = {};

    // add default cells
    ret['name' as OrderBy] = {
      value: (row: Row) => row.name,
      renderValue: (row: Row) => {
        if (getNameLinkPath) {
          return <Link to={getNameLinkPath(row)}>{row.name}</Link>;
        }
        return row.name;
      },
    };
    ret['displayName' as OrderBy] = {
      value: (row: Row) => row.displayName,
      renderValue: (row: Row) => row.displayName,
    };
    ret['latestUpdateAt' as OrderBy] = {
      value: (row: Row) => {
        if (trash) {
          return row.trashedAt ? new Date(row.trashedAt).toISOString() : '';
        }
        return new Date(row.latestUpdateAt).toISOString();
      },
      sortValue: (row: Row) => {
        if (trash) {
          return row.trashedAt ? new Date(row.trashedAt).toISOString() : '';
        }
        return new Date(row.latestUpdateAt).toISOString();
      },
      renderValue: (row: Row) => {
        if (trash) {
          return row.trashedAt ? new Date(row.trashedAt).toLocaleString('en-GB') : '';
        }
        return new Date(row.latestUpdateAt).toLocaleString('en-GB');
      },
      filterValue: (row: Row) => {
        if (trash) {
          return row.trashedAt ? new Date(row.trashedAt).toLocaleString('en-GB') : '';
        }
        return new Date(row.latestUpdateAt).toLocaleString('en-GB');
      },
    };
    ret['trashedAt' as OrderBy] = {
      value: (row: Row) => {
        return row.trashedAt ? new Date(row.trashedAt).toISOString() : '';
      },
      sortValue: (row: Row) => {
        return row.trashedAt ? new Date(row.trashedAt).toISOString() : '';
      },
      renderValue: (row: Row) => {
        return row.trashedAt ? new Date(row.trashedAt).toLocaleString('en-GB') : '';
      },
      filterValue: (row: Row) => {
        return row.trashedAt ? new Date(row.trashedAt).toLocaleString('en-GB') : '';
      },
    };
    ret['author' as OrderBy] = {
      value: (row: Row) => {
        if (!trash) {
          return row.author.latestEditorUsername;
        }
        return row.author.trashedByUsername;
      },
      renderValue: (row: Row) => {
        if (!trash) {
          return row.author.latestEditorUsername;
        }
        return row.author.trashedByUsername;
      },
    };
    ret['status' as OrderBy] = {
      value: (row: Row) => row.status,
      renderValue: (row: Row) => <StatusBadge status={row.status} />,
    };

    return ret;
  }, [trash, getNameLinkPath]);

  return rowMapper;
}

export function useContentTableRowMapper<
  Row extends BaseContentType | ChildrenBaseContentType,
  OrderBy extends
    | Partial<BaseContentOrderBy | keyof Row | number | symbol>
    | Partial<ChildrenBaseContentOrderBy | keyof Row | number | symbol>,
>({
  trash,
  extraCells,
  getNameLinkPath,
}: {
  trash?: boolean;
  extraCells?: TableBodyMapper<Row, keyof Row>;
  getNameLinkPath?: (row: Row) => string;
}) {
  const DefaultContentTableRowMapper = useDefaultContentTableRowMapper<Row, OrderBy>({ trash, getNameLinkPath });

  const rowMapper = useMemo(() => {
    const ret: Partial<Record<OrderBy, TableBodyCellMapper<Row>>> = DefaultContentTableRowMapper;

    // extend with custom extra cells
    Object.entries(extraCells ?? {}).forEach(([column, mapper]) => {
      if (column) {
        ret[column as OrderBy] = mapper;
      }
    });

    return ret;
  }, [DefaultContentTableRowMapper, extraCells]);

  return rowMapper;
}

export function useCanShowCell<
  Row extends BaseContentType | ChildrenBaseContentType,
  OrderBy extends
    | Partial<BaseContentOrderBy | keyof Row | number | symbol>
    | Partial<ChildrenBaseContentOrderBy | keyof Row | number | symbol>,
>({ showHeaders }: { showHeaders?: OrderBy[] }) {
  const ret = useCallback(
    (headerName: string) => !showHeaders || showHeaders.some((h) => h === headerName),
    [showHeaders],
  );
  return ret;
}
export function useContentTableHeadCells<
  Row extends BaseContentType | ChildrenBaseContentType,
  OrderBy extends
    | Partial<BaseContentOrderBy | keyof Row | number | symbol>
    | Partial<ChildrenBaseContentOrderBy | keyof Row | number | symbol>,
>({
  canShowCell,
  trash,
  extraHeads,
}: {
  canShowCell: (headerName: string) => boolean;
  trash?: boolean;
  extraHeads?: TableHeadCell<OrderBy>[];
}) {
  const leftHeads = useMemo(() => {
    const ret: TableHeadCell<OrderBy>[] = [];
    if (canShowCell('name')) {
      ret.push({
        id: 'name' as OrderBy,
        numeric: false,
        disablePadding: false,
        label: 'ID',
      });
    }
    if (canShowCell('displayName')) {
      ret.push({
        id: 'displayName' as OrderBy,
        numeric: false,
        disablePadding: false,
        label: 'Name',
      });
    }
    return ret;
  }, [canShowCell]);

  const rightHeads = useMemo(() => {
    const ret: TableHeadCell<OrderBy>[] = [];
    // 'Updated on' or 'Trashed on'
    if (trash) {
      if (canShowCell('latestUpdateAt')) {
        ret.push({
          id: 'latestUpdateAt' as OrderBy,
          numeric: false,
          disablePadding: false,
          label: 'Trashed on',
        });
      } else if (canShowCell('trashedAt')) {
        ret.push({
          id: 'trashedAt' as OrderBy,
          numeric: false,
          disablePadding: false,
          label: 'Trashed on',
        });
      }
    } else {
      if (canShowCell('latestUpdateAt')) {
        ret.push({
          id: 'latestUpdateAt' as OrderBy,
          numeric: false,
          disablePadding: false,
          label: 'Updated on',
        });
      }
    }
    // author: 'Updated by' or 'Trashed by'
    if (canShowCell('author')) {
      // 'Trashed by'
      if (trash) {
        ret.push({
          id: 'author' as OrderBy,
          numeric: false,
          disablePadding: false,
          label: 'Trashed by',
        });
      } else {
        // Updated by
        ret.push({
          id: 'author' as OrderBy,
          numeric: false,
          disablePadding: false,
          label: 'Updated by',
        });
      }
    }
    // Status
    if (canShowCell('status')) {
      ret.push({
        id: 'status' as OrderBy,
        numeric: false,
        disablePadding: false,
        label: 'Status',
        align: 'left',
      });
    }
    // Actions
    ret.push({
      numeric: false,
      disablePadding: false,
      align: 'center',
    });
    return ret;
  }, [canShowCell, trash]);

  const ret = useMemo((): TableHeadCell<OrderBy>[] => {
    return extraHeads ? [...leftHeads, ...extraHeads, ...rightHeads] : [...leftHeads, ...rightHeads];
  }, [extraHeads, leftHeads, rightHeads]);

  return ret;
}

export function useContentTableCells<
  Row extends BaseContentType | ChildrenBaseContentType,
  OrderBy extends
    | Partial<BaseContentOrderBy | keyof Row | number | symbol>
    | Partial<ChildrenBaseContentOrderBy | keyof Row | number | symbol>,
>({
  canShowCell,
  trash,
  extraCells,
  clickOnRow,
  getNameLinkPath,
}: {
  canShowCell: (headerName: string) => boolean;
  trash?: boolean;
  extraCells?: TableBodyMapper<Row, keyof Row>;
  clickOnRow?: (row: Row) => void;
  getNameLinkPath?: (row: Row) => string;
}) {
  const rowMapper = useContentTableRowMapper<Row, OrderBy>({ trash, extraCells, getNameLinkPath });
  const defaultOnClick = useCallback(
    (row: Row, event: any) => {
      if (clickOnRow) {
        clickOnRow(row);
      }
    },
    [clickOnRow],
  );

  const ret = useCallback(
    (row: Row) => {
      const cells: TableBodyCell[] = [];
      // left Cells
      if (canShowCell('name')) {
        cells.push({
          column: 'name',
          key: 'name',
          align: 'left',
          onClick: (event) => {
            defaultOnClick(row, event);
          },
          value: () => rowMapper['name' as OrderBy]?.value(row) ?? '',
          renderValue: () => rowMapper['name' as OrderBy]?.renderValue(row) ?? '',
        });
      }
      if (canShowCell('displayName')) {
        cells.push({
          column: 'displayName',
          key: 'displayName',
          align: 'left',
          onClick: (event) => {
            defaultOnClick(row, event);
          },
          value: () => rowMapper['displayName' as OrderBy]?.value(row) ?? '',
          renderValue: () => rowMapper['displayName' as OrderBy]?.renderValue(row) ?? '',
        });
      }
      // extra cells
      for (const key in extraCells) {
        if (canShowCell(key)) {
          const cellMapper = extraCells[key];
          cells.push({
            column: key,
            key: key,
            align: 'left',
            onClick: (event) => {
              defaultOnClick(row, event);
            },
            value: cellMapper?.value ? () => cellMapper.value(row) : () => '',
            renderValue: cellMapper?.renderValue ? () => cellMapper.renderValue(row) : () => '',
            filterValue: cellMapper?.filterValue
              ? () => (cellMapper.filterValue ? cellMapper.filterValue(row) : '')
              : undefined,
            sortValue: cellMapper?.sortValue
              ? () => (cellMapper.sortValue ? cellMapper.sortValue(row) : '')
              : undefined,
          });
        }
      }
      // right cells
      /// 'Updated on' or 'Trashed on'
      if (canShowCell('latestUpdateAt')) {
        cells.push({
          column: 'latestUpdateAt',
          key: 'latestUpdateAt',
          align: 'left',
          onClick: (event) => {
            defaultOnClick(row, event);
          },
          value: () => rowMapper['latestUpdateAt' as OrderBy]?.value(row) ?? '',
          renderValue: () => rowMapper['latestUpdateAt' as OrderBy]?.renderValue(row) ?? '',
          sortValue: () => {
            const sortValue = rowMapper['latestUpdateAt' as OrderBy]?.sortValue;
            if (sortValue) {
              return sortValue(row);
            }
            return '';
          },
        });
      } else if (canShowCell('trashedAt')) {
        cells.push({
          column: 'trashedAt',
          key: 'trashedAt',
          align: 'left',
          onClick: (event) => {
            defaultOnClick(row, event);
          },
          value: () => rowMapper['trashedAt' as OrderBy]?.value(row) ?? '',
          renderValue: () => rowMapper['trashedAt' as OrderBy]?.renderValue(row) ?? '',
          sortValue: () => {
            const sortValue = rowMapper['trashedAt' as OrderBy]?.sortValue;
            if (sortValue) {
              return sortValue(row);
            }
            return '';
          },
        });
      }
      /// author: 'Updated by' or 'Trashed by'
      if (canShowCell('author')) {
        cells.push({
          column: 'author',
          key: 'author',
          align: 'left',
          onClick: (event) => {
            defaultOnClick(row, event);
          },
          value: () => rowMapper['author' as OrderBy]?.value(row) ?? '',
          renderValue: () => rowMapper['author' as OrderBy]?.renderValue(row) ?? '',
        });
      }
      // status
      if (canShowCell('status')) {
        cells.push({
          column: 'status',
          key: 'status',
          align: 'left',
          onClick: (event) => {
            defaultOnClick(row, event);
          },
          value: () => rowMapper['status' as OrderBy]?.value(row) ?? '',
          renderValue: () => rowMapper['status' as OrderBy]?.renderValue(row) ?? '',
        });
        /// @NOTE: actions are hard-coded below (see ContentRow)
      }

      return cells;
    },
    [canShowCell, defaultOnClick, rowMapper, extraCells],
  );

  return ret;
}
