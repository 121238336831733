import { CreateFromTemplateRiskFunctionDto } from './dto/functions/create-from-existing-function.dto';
import { CreateRiskFunctionDto } from './dto/functions/create-function.dto';
import { CreateNewVersionRiskFunctionDto } from './dto/functions/create-new-version-function.dto';
import { RiskFunctionDto } from './dto/functions/function.dto';
import { UpdateRiskFunctionDto } from './dto/functions/update-function.dto';
import { RiskDto } from './dto/risks/risk.dto';
import { HttpService } from './http.service';

export class RiskFunctionService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('functions')) {}

  async getFunctions(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RiskFunctionDto[]>(undefined, params);
    return response.data;
  }
  async getFunctionsTrashed(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RiskFunctionDto[]>('trashed', params);
    return response.data;
  }

  async getFunction(id: string) {
    const response = await this.httpService.get<RiskFunctionDto>(id);
    return response.data;
  }

  async getFunctionVersionHistory(id: string) {
    const response = await this.httpService.get<RiskFunctionDto[]>(`${id}/history`);
    return response.data;
  }

  async getRisksFromFunction(id: string) {
    const response = await this.httpService.get<RiskDto[]>(`${id}/risks`);
    return response.data;
  }

  async createFunction(body: CreateRiskFunctionDto) {
    const response = await this.httpService.post<RiskFunctionDto>('', body);
    return response.data;
  }

  async createFromExistingFunction(id: string, body: CreateFromTemplateRiskFunctionDto) {
    const response = await this.httpService.post<RiskFunctionDto>(id, body);
    return response.data;
  }

  async createNewFunctionVersion(id: string, body: CreateNewVersionRiskFunctionDto) {
    const response = await this.httpService.postWithMeta<RiskFunctionDto, { updateParents: Partial<RiskDto>[] }>(
      `${id}/new-version`,
      body,
    );
    return response.data;
  }

  async updateFunction(id: string, body: UpdateRiskFunctionDto) {
    const response = await this.httpService.patch<RiskFunctionDto>(id, body);
    return response.data;
  }

  async deleteFunction(id: string) {
    const response = await this.httpService.delete(`${id}/delete`);
    return response.data;
  }

  async deleteFunctions(riskFunctionIds: string[]) {
    const response = await this.httpService.delete('delete', { riskFunctionIds });
    return response.data;
  }

  async trashFunction(id: string) {
    const response = await this.httpService.delete(`${id}/trash`);
    return response.data;
  }

  async trashFunctions(riskFunctionIds: string[]) {
    const response = await this.httpService.delete('trash', { riskFunctionIds });
    return response.data;
  }

  async restoreFunction(id: string) {
    const response = await this.httpService.post(`${id}/restore`);
    return response.data;
  }

  async cloneFunction(id: string, body: CreateFromTemplateRiskFunctionDto) {
    const response = await this.httpService.post<RiskFunctionDto>(`${id}/fork`, body);
    return response.data;
  }
}
