import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { ChangeEvent, FunctionComponent, SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  RiskFunctionValueAbapOpTypeName,
  RiskFunctionValueAribaTypeName,
  RiskFunctionValueSapBtpTypeName,
  RiskFunctionValueSfOpTypeName,
} from '../../services/dto/functions/constants';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import {
  UpdateRiskFunctionValueDto,
  UpdateRiskFunctionValueFields,
} from '../../services/dto/functions/update-function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import MountedTabPanel from '../../shared/mounted-tab-panel';
import { FunctionDataGridTabLabels } from './constants';
import { AbapOpRowsContext } from './context/abap-op';
import { AribaRowsContext } from './context/ariba';
import { DataGridRows } from './context/data';
import { SapBtpRowsContext } from './context/sap-btp';
import { SfOpRowsContext } from './context/sf-op';
import AbapOpFunctionsDataGrid from './function-values-data-grid/abap-op';
import AribaFunctionsDataGrid from './function-values-data-grid/ariba';
import SapBtpFunctionsDataGrid from './function-values-data-grid/sap-btp';
import SfOpFunctionsDataGrid from './function-values-data-grid/sf-op';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FunctionDefinitionsTableProps {
  riskFunction: RiskFunctionDto;
  risksFromRiskFunction?: RiskDto[];
  saveAllValues: (values: UpdateRiskFunctionValueDto[]) => Promise<RiskFunctionDto | undefined>;
}

const FunctionDefinitionsTable: FunctionComponent<FunctionDefinitionsTableProps> = ({
  riskFunction,
  risksFromRiskFunction,
  saveAllValues,
  /*...prop*/
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');

  /// @NOTE: put function definitions contextes here for usage (see below)
  const abapOpRowsContext = useContext(AbapOpRowsContext);
  const sfOpRowsContext = useContext(SfOpRowsContext);
  const aribaRowsContext = useContext(AribaRowsContext);
  const sapBtpRowsContext = useContext(SapBtpRowsContext);

  const debouncedSearch = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    // delay in ms
    200,
  );

  const onTabChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const a11yProps = (index: number) => {
    return {
      id: `function-details-tab-${index}`,
      'aria-controls': `function-details-tab-panel-${index}`,
    };
  };

  /// @NOTe: add new FD values mapping function here
  const mapFieldsAbapOp = (row: GridValidRowModel) => {
    return {
      object: row.object,
      field: row.field,
      low: row.low,
      high: row.high,
    };
  };
  const mapFieldsSfOp = (row: GridValidRowModel) => {
    return {
      permission: row.permission,
      field: row.field,
      value: row.value,
    };
  };
  const mapFieldsAriba = (row: GridValidRowModel) => {
    return {
      groupId: row.groupId,
    };
  };
  const mapFieldsSapBtp = (row: GridValidRowModel) => {
    return {
      attribute: row.attribute,
      value: row.value,
    };
  };

  const getUpdateRiskFunctionValues = useCallback(
    (
      unsavedRowsContext: DataGridRows,
      contentTypeName: string,
      mapFields: (row: GridValidRowModel) => UpdateRiskFunctionValueFields,
    ) => {
      const newRows = unsavedRowsContext.data.rows
        .map((row) => {
          const newRow = unsavedRowsContext.data.unsavedRows.get(row.id);
          if (newRow && (newRow._action === 'delete' || newRow.isDeleted)) {
            return undefined;
          }
          return newRow ?? row;
        })
        .filter((r) => r) as GridValidRowModel[];

      return newRows.map((row) => {
        return {
          id: !row.isNew && row.id ? row.id : undefined,
          functionContentTypeName: row.isNew ? contentTypeName : undefined,
          group: row.group,
          position: row.position,
          operator: row.operator,
          fields: mapFields(row),
        } as UpdateRiskFunctionValueDto;
      });
    },
    [],
  );

  const saveValues = useCallback(() => {
    const values = [
      /// @NOTE: map ALL function definition values into one array
      ...getUpdateRiskFunctionValues(abapOpRowsContext, RiskFunctionValueAbapOpTypeName, mapFieldsAbapOp),
      ...getUpdateRiskFunctionValues(sfOpRowsContext, RiskFunctionValueSfOpTypeName, mapFieldsSfOp),
      ...getUpdateRiskFunctionValues(aribaRowsContext, RiskFunctionValueAribaTypeName, mapFieldsAriba),
      ...getUpdateRiskFunctionValues(sapBtpRowsContext, RiskFunctionValueSapBtpTypeName, mapFieldsSapBtp),
    ];
    return saveAllValues(values).then((res) => {
      /// @NOTE: clear all contexts from value data
      abapOpRowsContext.setData((data) => {
        data.rowsBeforeChange.clear();
        data.unsavedRows.clear();
        return data;
      });
      sfOpRowsContext.setData((data) => {
        data.rowsBeforeChange.clear();
        data.unsavedRows.clear();
        return data;
      });
      aribaRowsContext.setData((data) => {
        data.rowsBeforeChange.clear();
        data.unsavedRows.clear();
        return data;
      });
      sapBtpRowsContext.setData((data) => {
        data.rowsBeforeChange.clear();
        data.unsavedRows.clear();
        return data;
      });

      return res;
    });
  }, [
    getUpdateRiskFunctionValues,
    abapOpRowsContext,
    sfOpRowsContext,
    aribaRowsContext,
    sapBtpRowsContext,
    saveAllValues,
  ]);

  const ABAP_OP_TAB_INDEX = 0;
  const SF_OP_TAB_INDEX = 1;
  const ARIBA_TAB_INDEX = 2;
  const SAP_BTP_TAB_INDEX = 3;

  return (
    <Stack>
      <Typography sx={{ mt: 2, mb: 2, ml: 2 }} variant='h6' id='tableTitle' component='div'>
        Function Definitions ({riskFunction.values.length})
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Tabs value={tabValue} onChange={onTabChange} aria-label='risk details tabs'>
            <Tab label={FunctionDataGridTabLabels[RiskFunctionValueAbapOpTypeName]} {...a11yProps(ABAP_OP_TAB_INDEX)} />
            <Tab label={FunctionDataGridTabLabels[RiskFunctionValueSfOpTypeName]} {...a11yProps(SF_OP_TAB_INDEX)} />
            <Tab label={FunctionDataGridTabLabels[RiskFunctionValueAribaTypeName]} {...a11yProps(ARIBA_TAB_INDEX)} />
            <Tab label={FunctionDataGridTabLabels[RiskFunctionValueSapBtpTypeName]} {...a11yProps(SAP_BTP_TAB_INDEX)} />
          </Tabs>
        </Grid>
        <Grid item xs={3}>
          <Stack direction='row' spacing={1} sx={{ m: 1, mx: 2 }} alignItems='center'>
            <FontAwesomeIcon icon={faSearch} />
            <TextField
              fullWidth
              value={searchInput}
              size='small'
              variant='standard'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(event.target.value);
                debouncedSearch(event.target.value);
              }}
              InputProps={{
                type: 'search',
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      {/* uses MountedTabPanel to keep data-grid state */}
      <MountedTabPanel value={tabValue} index={ABAP_OP_TAB_INDEX}>
        <AbapOpFunctionsDataGrid
          riskFunction={riskFunction}
          saveValues={saveValues}
          filter={{
            search: search,
            operator: 'contains',
          }}
        />
      </MountedTabPanel>
      <MountedTabPanel value={tabValue} index={SF_OP_TAB_INDEX}>
        <SfOpFunctionsDataGrid
          riskFunction={riskFunction}
          saveValues={saveValues}
          filter={{
            search: search,
            operator: 'contains',
          }}
        />
      </MountedTabPanel>
      <MountedTabPanel value={tabValue} index={ARIBA_TAB_INDEX}>
        <AribaFunctionsDataGrid
          riskFunction={riskFunction}
          saveValues={saveValues}
          filter={{
            search: search,
            operator: 'contains',
          }}
        />
      </MountedTabPanel>
      <MountedTabPanel value={tabValue} index={SAP_BTP_TAB_INDEX}>
        <SapBtpFunctionsDataGrid
          riskFunction={riskFunction}
          saveValues={saveValues}
          filter={{
            search: search,
            operator: 'contains',
          }}
        />
      </MountedTabPanel>
    </Stack>
  );
};

export default FunctionDefinitionsTable;
