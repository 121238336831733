import { faFileCircleXmark } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { roundToHundred } from 'src/shared/utils';
import { xcpColors } from 'src/styles/main-theme';

import { BusinessProcessesRisksReportDto } from '@/services/dto/reports/business-processes-risks-report.dto';

import { DASHBOARD_SEARCHPARAM_KEY_BPID } from '../../../shared/constatns';
import { DASHBOARD_DOUBLE_CLICK_DEPLAY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProcessRiskCountChartBoxProps {
  businessProcessesRisksReportData: BusinessProcessesRisksReportDto[] | undefined;
  height?: string | number;
  setBusinessProcessesIdFilter: (bpRisksReport: BusinessProcessesRisksReportDto) => void;
  onDoubleClick: (extraParam?: { key: string; value: string }) => void;
}

const ProcessRiskCountChartBox = ({
  businessProcessesRisksReportData,
  height,
  setBusinessProcessesIdFilter,
  onDoubleClick,
}: ProcessRiskCountChartBoxProps) => {
  const theme = useTheme();
  const risksForBpChartLabels = useMemo(
    () => businessProcessesRisksReportData?.map((data) => data.businessProcessName),
    [businessProcessesRisksReportData],
  );

  const chartColors = useMemo(() => [...xcpColors.units, ...xcpColors.graphics], []);

  const risksForBpChartDataset = useMemo(
    () => [
      {
        label: 'Risks',
        data: businessProcessesRisksReportData?.map((data) => data.riskCount),
        backgroundColor: chartColors,
        borderColor: ['white'],
        borderWidth: 2,
        datalabels: {
          labels: {
            // name: {
            //   align: () => {
            //     return 'center';
            //   },
            //   offset: 10,
            //   font: { size: 14 },
            //   color: (ctx: any) => {
            //     return 'white';
            //   },
            //   formatter: (value: any, ctx: any) => {
            //      if (risksForBpChartLabels) {
            //       return
            //       // risksForBpChartLabels[ctx.dataIndex]
            //      }
            //      return
            //   },
            // },
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any, ctx: any) => {
                if (risksForBpChartLabels) {
                  const sumValues = roundToHundred(ctx.dataset.data).reduce(
                    (sum: number, current: number) => sum + current,
                    0,
                  );
                  const percent = ((100 / sumValues) * value).toFixed(0);
                  return `${percent} %`;
                }
              },
              padding: 4,
              textAlign: 'center',
            },
          },
        },
      },
    ],
    [businessProcessesRisksReportData, risksForBpChartLabels, chartColors],
  );
  const risksForBpChartData = useMemo(() => {
    return {
      labels: risksForBpChartLabels,
      datasets: risksForBpChartDataset,
    };
  }, [risksForBpChartDataset, risksForBpChartLabels]);

  const loadingState = useMemo(() => {
    if (businessProcessesRisksReportData === undefined) {
      return 'loading';
    }
    if (businessProcessesRisksReportData.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [businessProcessesRisksReportData]);

  //businessProcess onClick event
  const businessProcessRiskCountChartRef = useRef<ChartJS | any>();
  const businessProcessRiskChartSingleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      if (businessProcessesRisksReportData) {
        /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
        const elementClicked = businessProcessRiskCountChartRef.current
          ? getElementAtEvent(businessProcessRiskCountChartRef.current, event)
          : undefined;
        if (elementClicked && elementClicked.length > 0) {
          const index = elementClicked[0].index;
          const datasetIndex = elementClicked[0].datasetIndex;
          if (datasetIndex === 0) {
            ///< dataset 0 is 'Risks' data
            //const businessProcessId = businessProcessesRisksReportData[index]?.businessProcessId;
            //const businessProcessDisplayName = businessProcessesRisksReportData[index]?.businessProcessDisplayName;
            if (businessProcessesRisksReportData[index]) {
              setBusinessProcessesIdFilter(businessProcessesRisksReportData[index]);
            }
          }
        }
      }
    },
    [businessProcessesRisksReportData, setBusinessProcessesIdFilter],
  );
  const businessProcessRiskChartDoubleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      if (businessProcessesRisksReportData) {
        /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
        const elementClicked = businessProcessRiskCountChartRef.current
          ? getElementAtEvent(businessProcessRiskCountChartRef.current, event)
          : undefined;
        if (elementClicked && elementClicked.length > 0) {
          const index = elementClicked[0].index;
          const datasetIndex = elementClicked[0].datasetIndex;
          if (datasetIndex === 0) {
            ///< dataset 0 is 'Risks' data
            const businessProcessId = businessProcessesRisksReportData[index]?.businessProcessId;
            onDoubleClick({ key: DASHBOARD_SEARCHPARAM_KEY_BPID, value: businessProcessId });
          }
        }
      }
    },
    [businessProcessesRisksReportData, onDoubleClick],
  );

  const [clickCounter, setClickCounter] = useState<{
    event: React.MouseEventHandler<HTMLCanvasElement> | any | undefined;
    counter: number;
  }>({ event: undefined, counter: 0 });
  const businessProcessRiskChartOnClick = useCallback((event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
    setClickCounter((c) => {
      // inc. click counter and pass event
      return { event, counter: c.counter + 1 };
    });
  }, []);

  useEffect(() => {
    // has click event
    if (clickCounter.event && clickCounter.counter > 0) {
      const timer = setTimeout(() => {
        if (clickCounter.counter === 1) businessProcessRiskChartSingleClick(clickCounter.event);
        else if (clickCounter.counter === 2) businessProcessRiskChartDoubleClick(clickCounter.event);
        setClickCounter({ event: undefined, counter: 0 });
      }, DASHBOARD_DOUBLE_CLICK_DEPLAY);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const generateLabels = useCallback(() => {
    return businessProcessesRisksReportData?.map((data, index) => {
      return {
        datasetIndex: 0,
        fillStyle: chartColors[index % chartColors.length],
        fontColor: theme.palette.text.primary,
        hidden: false,
        index: index,
        lineWidth: 1,
        pointStyle: undefined,
        strokeStyle: 'rgba(0, 0, 0, 0)',
        text: `${data.riskCount} ${data.businessProcessName}`,
      };
    });
  }, [businessProcessesRisksReportData, chartColors, theme.palette.text.primary]);

  //const matchesFHD = useMediaQuery('(min-height: 980px)');
  const matchesWQHD = useMediaQuery('(min-height: 1250px)');
  //const matches4K = useMediaQuery('(min-height: 2000px)');
  const cutout = useMemo(() => {
    if (matchesWQHD) {
      return 90;
    }

    if (risksForBpChartLabels) {
      if (risksForBpChartLabels.length > 4) {
        return 60;
      } else if (risksForBpChartLabels.length > 8) {
        return 30;
      } else if (risksForBpChartLabels.length > 12) {
        return 0;
      }
    }

    return 90;
  }, [matchesWQHD, risksForBpChartLabels]);

  const options = useMemo(() => {
    return {
      responsive: true,
      cutout: cutout,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: generateLabels,
            boxWidth: 20,
          },
        },
      },
    };
  }, [cutout, generateLabels]);

  return (
    <Stack>
      <Typography sx={{ color: xcpColors.grey[1], flex: '1 1 100%', my: 1, ml: 2 }} variant='h6' component='div'>
        Risks / Business Processes
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box sx={{ my: 2, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loadingState === 'loading' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {loadingState === 'empty' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography align='center'>No Data</Typography>
                <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
              </Stack>
            </Box>
          )}
          {businessProcessesRisksReportData && businessProcessesRisksReportData.length > 0 && (
            <Doughnut
              data={risksForBpChartData as any}
              options={options as any} ///< @NOTE: use any for plugin options
              ref={businessProcessRiskCountChartRef}
              onClick={businessProcessRiskChartOnClick}
            />
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default ProcessRiskCountChartBox;
