import { GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import { createContext, FunctionComponent, ReactNode } from 'react';

import { DataGridRows, DataGridRowsData, RowsContextProvider } from './data';

/// Ariba Context
export const AribaRowsContext = createContext(
  new DataGridRows(
    {
      unsavedRows: new Map<GridRowId, GridValidRowModel>(),
      rowsBeforeChange: new Map<GridRowId, GridValidRowModel>(),
      rows: [],
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (action: (rows: DataGridRowsData) => DataGridRowsData) => {},
  ),
);
export const AribaRowsContextProvider: FunctionComponent<{ children?: ReactNode | undefined }> = ({ children }) => {
  return <RowsContextProvider RowsContext={AribaRowsContext}>{children}</RowsContextProvider>;
};
